(function () {
	'use strict';

	angular
		.module('common')
		.factory('manageTranslations', ['manageSettings', manageTranslations]);
	function manageTranslations(manageSettings) {
		var defaultTranslations = {};
		//English language and substitutions
		defaultTranslations['en'] = {
			id: 'en',
			name: 'English',
			translations: {
				//These are name value pairs. Customize the values only (the item after the colon)
				Home: 'Home',
				Calendars: 'Calendars',
				Filters: 'Filters',
				Settings: 'Settings',

				'Hide Sidebar': 'Hide Sidebar',
				'Show Sidebar': 'Show Sidebar',

				//repeating event items
				Repeat: 'Repeat',
				January: 'January',
				February: 'February',
				March: 'March',
				April: 'April',
				May: 'May',
				June: 'June',
				July: 'July',
				August: 'August',
				September: 'September',
				October: 'October',
				November: 'November',
				December: 'December',
				Monday: 'Monday',
				Tuesday: 'Tuesday',
				Wednesday: 'Wednesday',
				Thursday: 'Thursday',
				Friday: 'Friday',
				Saturday: 'Saturday',
				Sunday: 'Sunday',
				Daily: 'Daily',
				Weekly: 'Weekly',
				Monthly: 'Monthly',
				Yearly: 'Yearly',
				'1st': '1st',
				'2nd': '2nd',
				'3rd': '3rd',
				'4th': '4th',
				Last: 'Last',
				'repeat every': 'repeat every',
				'day on': 'day on',
				'days on': 'days on',
				'week on': 'week on',
				'weeks on': 'weeks on',
				'month on': 'month on',
				'months on': 'months on',
				'year on': 'year on',
				'years on': 'years on',
				'stop after': 'stop after',
				never: 'never',
				'on the': 'on the',
				'Your changes will only apply to this event, not to its other repetitions. Would you still like to save this change?':
					'Your changes will only apply to this event, not to its other repetitions. Would you still like to save this change?',
				'Edit only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Edit only this item, this and all future repetitions, or all repetitons? This cannot be undone.',
				'Delete only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Delete only this item, this and all future repetitions, or all repetitons? This cannot be undone.',
				'Edit only this item or this and all future repetitions? This cannot be undone.':
					'Edit only this item or this and all future repetitions? This cannot be undone.',
				'Edit Repeating Item': 'Edit Repeating Item',
				'Delete Repeating Item': 'Delete Repeating Item',
				'Only This Item': 'Only This Item',
				'All Future Repetitions': 'All Future Repetitions',
				'All Repetitions': 'All Repetitions',
				'Would you like to delete all future repetitions and create new ones? This cannot be undone':
					'Would you like to delete all future repetitions and create new ones? This cannot be undone',
				'Repeating Event': 'Repeating Event',
				'Delete Only': 'Delete Only',
				'Delete and New': 'Delete and New',
				'Future Repetitions Deleted': 'Future Repetitions Deleted',
				'must be on or before': 'must be on or before',

				Day: 'Day',
				Schedule: 'Schedule',
				List: 'List',
				Week: 'Week',
				Month: 'Month',
				Resource: 'Resource',
				Grid: 'Grid',
				'Pivot Schedule': 'Pivot Schedule',
				'Pivot List': 'Pivot List',

				'Snap To Month': 'Snap To Month',

				Today: 'Today',
				Resources: 'Resources',
				resources: 'resources',

				Calendar: 'Calendar',
				All_Day: 'All Day',
				Start: 'Start',
				End: 'End',
				Status: 'Status',
				Location: 'Location',
				Contact: 'Contact',
				Project: 'Project',
				Done: 'Done',

				Delete: 'Delete',
				Close: 'Close',
				'Save And Close': 'Save & Close',
				'Save And Back': 'Save & Back',
				Back: 'Back',

				'Show on Map': 'Show on Map',
				'Open URL': 'Open URL',
				'Open Map': 'Open Map',
				'Location not found': 'Location not found',
				'Enter an address or location name':
					'Enter an address or location name',

				Search: 'Search',
				none: 'none',

				Description: 'Description',
				'Enter Title': 'Enter Title',
				'resource inherited from calendar':
					'resource inherited from calendar',

				'Clear Selection': 'Clear Selection',

				New_Event_Title: 'New Event',
				'New Event': 'New Event',
				'View Day': 'View Day',
				'You have no editable calendars selected':
					'You have no editable calendars selected',
				'You may not edit shared views':
					'You may not edit shared views',
				'You have no calendar sources configured':
					'You have no calendar sources configured',
				'You have no calendars selected that allow times':
					'You have no calendars selected that allow times',
				'Could not create a new event because all breakout items are hidden':
					'Could not create a new event because all breakout items are hidden',
				"'All Day Event' is currently disabled for Events":
					"'All Day Event' is currently disabled for Events",

				'Time Settings': 'Time Settings',
				'Default Time': 'Default Time',
				'Earliest Time': 'Earliest Time',
				'Latest Time': 'Latest Time',
				'Time Scale': 'Time Scale',
				'View Settings': 'View Settings',
				'Compressed View': 'Compressed View',
				'Fluid Months': 'Fluid Months',

				Yes: 'Yes',
				No: 'No',

				'Advanced Filters': 'Advanced Filters',
				'Clear Filters': 'Clear Filters',

				Week_Number_Header: 'W',
				more: 'more',

				hour: 'hour',
				hours: 'hours',
				minute: 'minute',
				minutes: 'minutes',

				Text: 'Text',
				'Status Filters': 'Status Filters',
				'Resource Filters': 'Resource Filters',
				'Project Filters': 'Project Filters',
				'Contact Filters': 'Contact Filters',

				'New Status': 'New Status',
				'New Resource': 'New Resource',
				'New Folder': 'New Folder',

				Sources: 'Sources',
				Save: 'Save',
				Cancel: 'Cancel',
				cancel: 'cancel',

				'Show as resource': 'Show as resource',
				'Calendar for new entries': 'Calendar for new entries',
				'for me': 'for me',
				'for everyone': 'for everyone',
				'disabled by your administrator':
					'disabled by your administrator',

				'Call your own scripts': 'Button Actions',

				'Status Filter': 'Status Filter',

				Name: 'Name',
				Color: 'Color',

				day: 'day',
				days: 'days',

				'Account Settings': 'Account Settings',
				'Trial Mode': 'Trial Mode',
				'Contact Us': 'Contact Us',
				Help: 'Help',
				Documentation: 'Documentation',
				'What Is New': "What's New",
				'Sign Out': 'Sign Out',

				'Check For Updates': 'Check For Updates',
				'Install Update': 'Install Update',
				'what is new': "what's new?",

				'Load Calendar': 'Load Calendar',

				'Purchase DayBack': 'Purchase DayBack',
				'Switch To Subscription': 'Switch To Subscription',
				'Manage Subscription': 'Manage Subscription',
				'Enter New License': 'Enter New License',
				Activate: 'Activate',

				'Trial activated': 'Your {{days}} trial has been activated',
				'Trial expired': 'Your trial has expired',
				'Trial expired message':
					'Trial expired. Please purchase a license to continue.',
				'Trial remains': '{{days}} left in your trial',
				'Trial expires in days': 'Your trial expires in {{days}}.',

				'Subscription expired': 'Subscription expired {{days}} ago',
				'Subscription expired message':
					'Subscription expired. Please renew to continue.',
				'Cease to function message':
					'If a new license is not entered the calendar interface will cease to function in {{days}}',

				'Subscription renews': 'Subscription renews in {{days}}',
				'Subscription renews today': 'Subscription renews today',
				'Subscription should have renewed':
					'Subscription should have renewed {{days}} ago',

				'Subscription overdue message admin':
					'Subscription payment is past due. Manage your subscription and update payment info.',
				'Subscription overdue message non admin':
					'Subscription payment is past due. Contact us to update payment info.',

				Purchased: 'Purchased',

				Product: 'Product',
				'Order Number': 'Order Number',
				Email: 'Email',
				'Registered To': 'Registered To',

				'Current version': "You're running version {{version}}",
				'Version available': 'Version {{version}} available',
				'Running latest version': 'You are running the latest version',
				'Cannot connect': "Couldn't connect to update server",
				'Problem downloading update':
					'There was a problem downloading the update',

				'Activation denied':
					'The activation request was denied. Please check your information and try again',
				'Activation successful': 'Activation successful',

				TooManyUsers1: 'Too Many Users',
				TooManyUsers2: 'Too many users have opened the calendar.',
				TooManyUsers3:
					'You can purchase an upgrade to allow more users, or temporarily reset the currently counted users so that you can continue working now.',
				'Purchase Upgrade': 'Purchase Upgrade',
				'Reset User Count': 'Reset User Count',

				'Mobile not supported':
					'This is currently not supported on iOS',
				'Update Successful': 'Update Successful',

				'Resource Columns': 'Resource Columns',

				Refresh: 'Refresh',
				'Show Weekends': 'Show Weekends',
				Days: 'Days',
				of: 'of',

				Fetching: 'Fetching',

				'Extend In-App Updates': 'Extend In-App Updates',
				'Updates expired message':
					'Access to in-app updates expired {{days}} ago',
				'Free updates remaining message':
					'{{days}} of in-app updates remaining',

				'Week of': 'Week of',
				weeks: 'weeks',
				week: 'week',

				months: 'months',
				month: 'month',

				years: 'years',
				year: 'year',

				'Show Distances': 'Show Distances',
				Horizon: 'Horizon',
				'Breakout By': 'Breakout By',
				nothing: 'nothing',
				'Horizon Settings': 'Horizon Settings',

				'Breakout by Resource': 'Breakout by Resource',
				'Breakout by Status': 'Breakout by Status',
				'Repeating Events': 'Repeating Events',
				'Hide all Repetitions': 'Hide all Repetitions',
				'Hide daily': 'Hide daily',
				'Hide weekly': 'Hide weekly',
				'Hide monthly': 'Hide monthly',
				'Hide yearly': 'Hide yearly',

				Saved: 'Changes saved',
				Deleted: 'Deleted',
				Undo: 'Undo',
				'No Change History Available': 'No Change History Available',
				'Event may be off screen': 'Event may be off screen',

				'Operation Failed': 'Operation Failed',
				'Revert Changes': 'Revert Changes',
				'Changes Reverted': 'Changes Reverted',
				'Return To Event': 'Return To Event',

				'Authentication Successful': 'Authentication Successful',
				'Please close to return to the calendar':
					'Please close to return to the calendar',

				'See in-app updates available since then':
					'See in-app updates available since then',

				Views: 'Views',
				'Horizon View': 'Horizon View',
				'Resource View': 'Resource View',
				'Month View': 'Month View',
				Fluid: 'Fluid',
				'Fit to screen': 'Fit to screen',

				'Admin Settings': 'Admin Settings',
				'Administrator Settings': 'Administrator Settings',
				'My Settings': 'My Settings',
				'Contact Us And Docs': 'Contact Us & Docs',
				'Sign-Out': 'Sign-Out',
				Feedback: 'Feedback',
				'Schedule a Demo': 'Schedule a Demo',

				'events can only be moved to other calendars when they are first created':
					'{{source}} events can only be moved to other calendars when they are first created.',

				'Editing Scale': 'Editing Scale',

				'defend your time': 'defend your time',
				'add to calendar': 'add to calendar',
				More: 'More',

				'Invalid View': 'Invalid View',
				'Invalid view message':
					'An invalid view was used to navigate the calendar and has been ignored.',
				'Invalid Date': 'Invalid Date',
				'Invalid date message':
					'An invalid date was used to navigate the calendar and has been ignored.',
				'Invalid Bookmark ID': 'Invalid Bookmark ID',
				'Invalid Bookmark ID message':
					'Bookmark ID {{bookmarkID}} is invalid or not part of your DayBack Group',
				Error: 'Error',
				'Bookmark update filter error message':
					'The update can’t be performed because the filter list no longer matches that saved with the bookmark. Please create and share a new bookmark instead.',
				'Bookmark visit filter error message':
					'The filter list no longer matches that saved with the bookmark, so this bookmark may be out of date.',

				Continue: 'Continue',
				'Learn More': 'Learn More',

				Share: 'Share',
				'Bookmark or Share This View': 'Bookmark or Share This View',
				'Manage Bookmarks/Shares': 'Manage Bookmarks/Shares',
				'Recent Bookmarks': 'Recent Bookmarks',
				'Show All': 'Show All',
				'DayBack Mission': "DayBack's Mission",
				Blog: 'Blog',
				'Get Your Own DayBack': 'Get Your Own DayBack',
				'Contact DayBack': 'Contact DayBack',
				'About DayBack': 'About DayBack',

				'Enable Sharing': 'Enable Sharing',
				Off: 'Off',
				Administrator: 'Administrator',
				Administrators: 'Administrators',
				Everyone: 'Everyone',

				'Enabled for Administrators': 'Enabled for Administrators',
				'Sharing Disabled': 'Sharing Disabled',

				'DayBack Share Not Found': 'DayBack Share Not Found',
				'The share you have requested has expired or does not exist.':
					"The share you have requested has expired or doesn't exist.",
				'Why did this happen?': 'Why did this happen?',
				'Want more? Get your own DayBack.':
					'Want more? Get your own DayBack...',
				'Try DayBack for Free': 'Try DayBack for Free',

				Update: 'Update',
				Edit: 'Edit',
				Visit: 'Visit',
				'Bookmark/Share Detail': 'Bookmark/Share Detail',
				'Created by': 'Created by',
				'Created By': 'Created By',
				'Expires In': 'Expires In',
				Expires: 'Expires',
				Expired: 'Expired',
				New: 'New',
				Create: 'Create',
				'Show In Timezone': 'Show In Timezone',
				'Extend View': 'Extend View',
				'Create Share': 'Create Share',
				'Shared Dates': 'Shared Dates',
				'Share with': 'Share with',
				'Shared with': 'Shared with',
				'Just Me': 'Just Me',
				'My DayBack Group': 'My DayBack Group',
				Public: 'Public',
				'Public share options': 'Public share options',
				Bookmark: 'Bookmark',
				Created: 'Created',
				URL: 'URL',
				'Embed Code': 'Embed Code',
				'Update Share': 'Update Share',
				'Update Bookmark': 'Update Bookmark',
				'Copy URL': 'Copy URL',
				'Copy Embed Code': 'Copy Embed Code',
				'Copied to clipboard': 'Copied to clipboard',
				'Please copy the selected text':
					'Please copy the selected text',
				'Creating share': 'Creating share',
				'Updating share': 'Updating share',
				'Bookmark created successfully':
					'Bookmark created successfully',
				'Share updated successfully': 'Share updated successfully',
				'Updating takes you to the view you have shared':
					"Updating takes you to the view you've shared and re-syncs matching items.",
				'Remember changes made are automatically synced':
					'Remember, any changes made in DayBack are automatically synced to your shares.',
				'Updating this bookmark will reset the filters':
					"Updating this bookmark will reset the filters, view, and date range (if applicable) to what you're seeing right now.",

				'Authorization Failed': 'Authorization Failed',
				'Connecting To DayBack': 'Connecting To DayBack',
				'Get Started': 'Get Started',
				'Sharing not Activated': 'Sharing not Activated',
				'Sharing Enabled': 'Sharing Enabled',
				'There are no shares yet': 'There are no shares yet',

				Subscribe: 'Subscribe',
				'Sharing Trial Expired': 'Sharing Trial Expired',
				'Sharing Subscription Expired': 'Sharing Subscription Expired',

				'Custom Fields': 'Custom Fields',
				'Select Date': 'Select Date',

				'Add Field': 'Add Field',
				'Add Button': 'Add Button',

				'View In Source': 'View In {{source}}',

				'Basecamp HTTPS Required':
					'Basecamp requires a secure connection. Would you like to reload DayBack using HTTPS?',
				'HTTPS Connection Required': 'HTTPS Connection Required',
				'Reload as HTTPS': 'Reload as HTTPS',

				Total: 'Total',
				'Chart Data': 'Chart Data',
				Format: 'Format',
				Threshold: 'Threshold',
				'Chart the value of a specific field:':
					'Chart the value of a specific field:',
				'Chart the number of items': 'Chart the number of items',
				'Chart the duration of items': 'Chart the duration of items',
				'Show a threshold value on the chart':
					'Show a threshold value on the chart',
				Value: 'Value',
				'Line Color': 'Line Color',
				'Fill Color': 'Fill Color',
				'Fill Opacity': 'Fill Opacity',
				'color formats?': 'color formats?',
				'Color formats instructions':
					'Use any CSS color, including RGB, RGBA or Hex values',

				'Format as': 'Format as',
				'Label Before Value': 'Label Before Value',
				'Label After Value': 'Label After Value',
				'Decimal Symbol': 'Decimal Symbol',
				'Decimal Places': 'Decimal Places',
				'Thousands Separator': 'Thousands Separator',

				event: 'event',
				events: 'events',
				item: 'item',
				items: 'items',
				'Over by': 'Over by',
				'Under by': 'Under by',
				'Chart by day, week': 'Chart by day, week',
				Auto: 'Auto',

				'Add Calendar': 'Add Calendar',
				'Calendar is read-only': 'Calendar is read-only',

				Folder: 'Folder',
				'Folder Name': 'Folder Name',

				'Only applies to horizon view at day scale':
					'Only applies to horizon view at day scale',

				'Switch Accounts': 'Switch Accounts',
				Deauthorize: 'Deauthorize',
				Authorize: 'Authorize',
				'Maybe Later': 'Maybe Later',

				'Grant Access To Your Google Account':
					'Grant Access To Your {{type}} Account',
				'To view your Google Calendars you will want to allow DayBack to access your Google account':
					'To view your {{type}} Calendars you will want to allow DayBack to access your {{type}} account.',

				'Welcome To DayBack': 'Welcome To DayBack',

				'Waiting for events': 'Waiting for events',
				'Cancel Request': 'Cancel Request',

				Theme: 'Theme',

				'Moving sample data':
					'Moving sample data into place. This may take a moment.',

				'Switch to this date with the bookmark':
					'Switch to this date with the bookmark',
				'Changing switch date':
					"Changing this setting for a public bookmark will change the current date range for the view and update the events shared to match what's shown in the view",

				Account: 'Account',
				Accounts: 'Accounts',
				and: 'and',
				"Calendar Can't be found": "Calendar Can't be Found",
				'Bookmark calendar not found message':
					"The {{calendarSchedules}} can't be found. Please authorize the correct {{calendarSources}} to {{action}} this bookmark.",

				'There was a problem signing in':
					'There was a problem signing in {{account}}',
				'Your current DayBack license does not support this feature.':
					'Your current DayBack license does not support this feature.',
				'Your subscription does not have enough user licenses':
					'Your subscription does not have enough user licenses.',
				'There was an issue retrieving data from the specified group token':
					'There was an issue retrieving data from the specified group token.',
				'Please contact your FileMaker developer':
					'Please contact your FileMaker developer.',

				'First linked event': 'First linked event',
				'Previous linked event': 'Previous linked event',
				'Step through linked events': 'Step through linked events',
				'End of linked events': 'End of linked events',
				'Next linked event': 'Next linked event',
				'Last linked event': 'Last linked event',
				Pause: 'Pause',

				Attachments: 'Attachments',
				'The original event has attachments':
					'The original event has attachments. Those attachments will be removed from the duplicated event.',

				'Filter resources': 'Filter resources',
				'No resources match your criteria':
					'No resources match your criteria',

				Filter: 'Filter',
				Class: 'Class',
				Tags: 'Tags',
				Capacity: 'Capacity',
				'comma separated': 'comma separated',
				'No additional details': 'No additional details',
				'This item was created dynamically and should be modified in its original source':
					'This item was created dynamically and should be modified in its original source',

				'Over under threshold': 'Over / under threshold',
				over: 'over',
				'Includes partial week': 'Includes partial week',
				'Includes partial month': 'Includes partial month',
				'Public bookmarks expire essentials plan':
					'Public bookmarks expire in 72 hours in the Essentials plan. For public calendars that persist longer than 3 days, please upgrade to the Plus plan where you can set your own expiration date.',
				'Learn more about DayBack Plans and Pricing':
					'Learn more about DayBack Plans and Pricing',

				'No events found': 'No events found',
				'Service will end days unless payment is received':
					'Service will end in {{days}} days unless payment is received',
				'Service will end day unless payment is received':
					'Service will end in {{days}} day unless payment is received',

				Drop: 'Drop',
				'Count Unscheduled Items': '{{count}} Unscheduled Items',
				'Count Unscheduled Item': '{{count}} Unscheduled Item',
				'No Unscheduled Items': 'No Unscheduled Items',
				'Unscheduled is not available for this calendar':
					'Unscheduled is not available for this calendar',

				'Show as resource for me': 'Show as resource for me',

				Assigned: 'Assigned',

				'Public shares will be deleted 30 days after expiration':
					'Public shares will be deleted 30 days after expiration',
				'Will be deleted 30 days after expiration':
					'Will be deleted 30 days after expiration',
			},
		};

		//Spanish language and substitutions - Special thanks to Rodrigo, Jean Wenmeekers (and his students)
		defaultTranslations['es'] = {
			id: 'es',
			name: 'español',
			translations: {
				//These are name value pairs. Customize the values only (the item after the colon)
				Home: 'Inicio',
				Calendars: 'Calendarios',
				Filters: 'Filtros',
				Settings: 'Ajustes',

				'Hide Sidebar': 'Ocultar Sidebar',
				'Show Sidebar': 'Mostrar Sidebar',

				//repeating event items
				Repeat: 'Repetir',
				January: 'enero',
				February: 'febrero',
				March: 'marzo',
				April: 'abril',
				May: 'mayo',
				June: 'junio',
				July: 'julio',
				August: 'agosto',
				September: 'septiembre',
				October: 'octubre',
				November: 'noviembre',
				December: 'diciembre',
				Monday: 'lunes',
				Tuesday: 'martes',
				Wednesday: 'miércoles',
				Thursday: 'jueves',
				Friday: 'viernes',
				Saturday: 'sábado',
				Sunday: 'domingo',
				Daily: 'Diariamente',
				Weekly: 'Semanal',
				Monthly: 'Mensual',
				Yearly: 'Anual',
				'1st': 'primero',
				'2nd': 'Segundo',
				'3rd': 'tercero',
				'4th': 'cuarto',
				Last: 'Último',
				'repeat every': 'repite cada',
				'day on': 'en día',
				'days on': 'días en',
				'week on': 'semana en',
				'weeks on': 'semanas en',
				'month on': 'mes en',
				'months on': 'meses en',
				'year on': 'año en adelante',
				'years on': 'años después',
				'stop after': 'parar después',
				never: 'nunca',
				'on the': 'sobre el',
				'Your changes will only apply to this event, not to its other repetitions. Would you still like to save this change?':
					'Sus cambios solo se aplicarán a este evento, no a sus otras repeticiones. ¿Aún te gustaría guardar este cambio?',
				'Edit only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Editar sólo este elemento, esto y todas las repeticiones futuras, o todas las repeticiones? Esto no se puede deshacer.',
				'Delete only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Eliminar sólo este elemento, esto y todas las repeticiones futuras, o todas las repeticiones? Esto no se puede deshacer.',
				'Edit only this item or this and all future repetitions? This cannot be undone.':
					'Editar sólo este elemento o esta y todas las repeticiones futuras? Esto no se puede deshacer.',
				'Edit Repeating Item': 'Editar elemento repetitivo',
				'Delete Repeating Item': 'Eliminar elemento repetitivo',
				'Only This Item': 'Sólo este artículo',
				'All Future Repetitions': 'Todas las Repeticiones Futuras',
				'All Repetitions': 'Todas las repeticiones',
				'Would you like to delete all future repetitions and create new ones? This cannot be undone':
					'¿Desea eliminar todas las repeticiones futuras y crear otras nuevas? Esto no se puede deshacer',
				'Repeating Event': 'Evento de repetición',
				'Delete Only': 'Eliminar sólo',
				'Delete and New': 'Borrar y nuevo',
				'Future Repetitions Deleted': 'Repeticiones futuras eliminadas',
				'must be on or before': 'debe estar en o antes',

				Day: 'Dia',
				Schedule: 'Horario',
				List: 'Lista',
				Week: 'Semana',
				Month: 'Mes',
				Resource: 'Recurso',
				Grid: 'Cuadrícula',
				'Pivot Schedule': 'Horario de pivote',
				'Pivot List': 'Lista de pivote',

				'Snap To Month': 'Ajustar al mes',

				Today: 'Hoy',
				Resources: 'Recursos',
				resources: 'recursos',

				Calendar: 'Calendario',
				All_Day: 'Todo el Dia',
				Start: 'Inicio',
				End: 'Fin',
				Status: 'Estado',
				Location: 'Ubicación',
				Contact: 'Contacto',
				Project: 'Proyecto',
				Done: 'Hecho',

				Delete: 'Eliminar',
				Close: 'Cerrar',
				'Save And Close': 'Guardar y Cerrar',
				'Save And Back': 'Guardar y Volver',
				Back: 'Volver',

				'Show on Map': 'Mostrar en el mapa',
				'Open URL': 'Abrir URL',
				'Open Map': 'Abrir mapa',
				'Location not found': 'La ubicación no encontrado',
				'Enter an address or location name':
					'Introduzca un nombre de dirección o ubicación',

				Search: 'Búsqueda',
				none: 'ninguno',

				Description: 'Descripción',
				'Enter Title': 'Introduzca el Título',
				'resource inherited from calendar':
					'recurso heredado de calendario',

				'Clear Selection': 'Borrar selección',

				New_Event_Title: 'Nuevo Evento',
				'New Event': 'Nuevo Evento',
				'View Day': 'Ver Día',
				'You have no editable calendars selected':
					'No hay calendarios editables seleccionados',
				'You may not edit shared views':
					'No puedes editar vistas compartidas',
				'You have no calendar sources configured':
					'No hay fuentes de calendario configuradas',
				'You have no calendars selected that allow times':
					'No hay calendarios seleccionados que permitan tiempos',
				'Could not create a new event because all breakout items are hidden':
					'No se ha podido crear un nuevo evento porque todos los elementos del desglose están ocultos',
				"'All Day Event' is currently disabled for Events":
					"'Evento de todo el día' está actualmente deshabilitado para eventos",

				'Time Settings': 'Ajustes de tiempo',
				'Default Time': 'Tiempo predeterminado',
				'Earliest Time': 'Tiempo mas temprano',
				'Latest Time': 'Tiempo último',
				'Time Scale': 'Escala de tiempo',
				'View Settings': 'Ver configuración',
				'Compressed View': 'Ver comprimido',
				'Fluid Months': 'Meses superpuestos',

				Yes: 'Si',
				No: 'No',

				'Advanced Filters': 'Filtros Avanzados',
				'Clear Filters': 'Borrar filtros',

				Week_Number_Header: 'Sm',
				more: 'más',

				hour: 'hora',
				hours: 'horas',
				minute: 'minuto',
				minutes: 'minutos',

				Text: 'Texto',
				'Status Filters': 'Filtro del Estatus',
				'Resource Filters': 'Filtro del Recursos',
				'Project Filters': 'Filtro del Proyectos',
				'Contact Filters': 'Filtro del Contacto',

				'New Status': 'nuevo estado',
				'New Resource': 'nuevo recurso',
				'New Folder': 'nueva carpeta',

				Sources: 'Fuentes',
				Save: 'Guardar',
				Cancel: 'Cancelar',
				cancel: 'cancelar',

				'Show as resource': 'Mostrar como recurso',
				'Calendar for new entries': 'Calendario primaria',
				'for me': 'para mi',
				'for everyone': 'para todos',
				'disabled by your administrator':
					'Inhabilitado por su administrador',

				'Call your own scripts': 'llame sus propios scripts',

				'Status Filter': 'Estado Filtros',

				Name: 'designación',
				Color: 'Color',

				day: 'día',
				days: 'días',

				'Account Settings': 'Configuraciones del Registro',
				'Trial Mode': 'Modo de prueba',
				'Contact Us': 'Contáctenos',
				Help: 'Ayuda',
				Documentation: 'Documentación',
				'What Is New': 'Qué hay de nuevo',
				'Sign Out': 'Desconectar',

				'Check For Updates': 'Buscar Actualizaciones',
				'Install Update': 'Instale la Actualización',
				'what is new': 'Qué hay de nuevo',

				'Load Calendar': 'Comenzar Calendario',

				'Purchase DayBack': 'Comprar DayBack',
				'Switch To Subscription': 'Cambiar a Suscripción',
				'Manage Subscription': 'Gestionar suscripción',
				'Enter New License': 'Introduzca una nueva licencia',
				Activate: 'Activar',

				'Trial activated': 'Su prueba de {{days}} se ha activado',
				'Trial expired': 'Su prueba ha expirado',
				'Trial expired message':
					'Prueba expiró. Por favor, compre una licencia para continuar.',
				'Trial remains': '{{days}} que quedan en su prueba',
				'Trial expires in days': 'Su prueba expira en {{days}}.',

				'Subscription expired': 'Suscripción expiró hace {{days}}',
				'Subscription expired message':
					'Suscripción expiró. Gracias por renovar para continuar.',
				'Cease to function message':
					'Si no se introduce una nueva licencia el calendario dejará de funcionar en {{days}}',

				'Subscription renews': 'Suscripción renueva en {{days}}',
				'Subscription renews today': 'La suscripción se renueva hoy',
				'Subscription should have renewed':
					'La suscripción debería haberse renovado hace {{days}}',

				'Subscription overdue message admin':
					'El pago de la suscripción está vencido. Administre su suscripción y actualice la información de pago.',
				'Subscription overdue message non admin':
					'El pago de la suscripción está vencido. Administre su suscripción y actualice la información de pago.',

				Purchased: 'Comprado',

				Product: 'producto',
				'Order Number': 'Número de orden',
				Email: 'Email',
				'Registered To': 'registrado Para',

				'Current version': 'Usted utiliza la versión {{version}}',
				'Version available': 'versión {{versión}} disponible',
				'Running latest version':
					'Está utilizando la versión más reciente',
				'Cannot connect':
					'No se pudo conectar al servidor de actualizaciones',
				'Problem downloading update':
					'Hubo un problema al descargar la actualización',

				'Activation denied':
					'Se denegó la solicitud de activación. Por favor, compruebe sus datos e inténtelo de nuevo',
				'Activation successful': 'Activación exitosa',

				TooManyUsers1: 'Demasiados Usuarios',
				TooManyUsers2: 'Demasiados usuarios han abierto el calendario.',
				TooManyUsers3:
					'Usted puede comprar una actualización para permitir que más usuarios, o restablecer temporalmente los usuarios actualmente contados para que pueda seguir trabajando ahora.',
				'Purchase Upgrade': 'Compra de actualización',
				'Reset User Count': 'Restablecer recuento de usuario',

				'Mobile not supported':
					'Esto actualmente no es compatible con iOS',
				'Update Successful': 'Update Successful',

				'Resource Columns': 'Columnas de Recursos',

				Refresh: 'refrescar',
				'Show Weekends': 'Mostrar los fines de semana',
				Days: 'Días',
				of: 'de',

				Fetching: 'Recuperando',

				'Extend In-App Updates':
					'Extender las actualizaciones en la aplicación',
				'Updates expired message':
					'El acceso a las actualizaciones en la aplicación expiró hace {{days}}',
				'Free updates remaining message':
					'{{days}} de in-app actualizaciones restante',

				'Week of': 'Semana de',
				weeks: 'semanas',
				week: 'semana',

				months: 'meses',
				month: 'mes',

				years: 'años',
				year: 'año',

				'Show Distances': 'Mostrar distancias',
				Horizon: 'Horizonte',
				'Breakout By': 'Romper por',
				nothing: 'nada',
				'Horizon Settings': 'Configuración de Horizon',

				'Breakout by Resource': 'Desglose por recurso',
				'Breakout by Status': 'Desglose por estado',
				'Repeating Events': 'Eventos repetidos',
				'Hide all repetitions': 'Ocultar todas las repeticiones',
				'Hide daily': 'Ocultar diariamente',
				'Hide weekly': 'Ocultar semanalmente',
				'Hide monthly': 'Ocultar mensualmente',
				'Hide yearly': 'Ocultar anualmente',

				Saved: 'Salvado',
				Deleted: 'Suprimido',
				Undo: 'Deshacer',
				'No Change History Available':
					'No hay Historial de Cambios Disponible',
				'Event may be off screen':
					'El evento puede estar fuera de la pantalla',

				'Operation Failed': 'Operación fallida',
				'Revert Changes': 'Revertir cambios',
				'Changes Reverted': 'Cambios Revertidos',
				'Return To Event': 'Volver Para evento',

				'Authentication Successful': 'La autenticación exitosa',
				'Please close to return to the calendar':
					'Por favor, cierre para volver al calendario',

				'See in-app updates available since then':
					'Ver las actualizaciones en la aplicación disponible desde entonces',

				Views: 'Puntos de vista',
				'Horizon View': 'Vista del horizonte',
				'Resource View': 'Vista de recursos',
				'Month View': 'Vista de mes',
				Fluid: 'Superpuestos',
				'Fit to screen': 'Ajustar a la pantalla',

				'Admin Settings': 'Configuraciones de administración',
				'Administrator Settings': 'Configuración del administrador',
				'My Settings': 'Mi configuración',
				'Contact Us And Docs': 'Contacte con nosotros y Docs',
				'Sign-Out': 'Desconectar',
				Feedback: 'Realimentación',
				'Schedule a Demo': 'Programe una demostración',

				'events can only be moved to other calendars when they are first created':
					'{{source}} eventos sólo se pueden mover a otros calendarios cuando se crean por primera vez.',

				'Editing Scale': 'Escala de edición',

				'defend your time': 'defender a su tiempo',
				'add to calendar': 'añadir al calendario',
				More: 'Más',

				'Invalid View': 'Ver inválida',
				'Invalid view message':
					'Un punto de vista válido se utiliza para navegar por el calendario y ha sido ignorado.',
				'Invalid Date': 'Fecha invalida',
				'Invalid date message':
					'Una fecha no válida se utiliza para navegar por el calendario y ha sido ignorado.',
				'Invalid Bookmark ID': 'ID de marcador no válido',
				'Invalid Bookmark ID message':
					'ID de marcador {{bookmarkID}} no es válido o no forma parte de su grupo DayBack',
				Error: 'Error',
				'Bookmark update filter error message':
					'La actualización no se puede realizar porque la lista de filtros ya no coincide con la guardada con el marcador. En su lugar, cree y comparta un nuevo marcador.',
				'Bookmark visit filter error message':
					'La lista de filtros ya no coincide con la guardada con el marcador, por lo que este marcador puede estar desactualizado.',

				Continue: 'Continuar',
				'Learn More': 'Aprende más',

				Share: 'Compartir',
				'Bookmark or Share This View': 'Marcar o Compartir esta vista',
				'Manage Bookmarks/Shares': 'Administrar Marcadores/Compartidas',
				'Recent Bookmarks': 'Marcadores Recientes',
				'Show All': 'Mostrar Todo',
				'DayBack Mission': 'Misión de DayBack',
				Blog: 'Blog',
				'Get Your Own DayBack': 'Obtenga su propio DayBack',
				'Contact DayBack': 'Contactar DayBack',
				'About DayBack': 'Acerca de DayBack',

				'Enable Sharing': 'Habilitar compartir',
				Off: 'Apagado',
				Administrator: 'Administración',
				Administrators: 'Administradores',
				Everyone: 'Todo el mundo',

				'Enabled for Administrators': 'Habilitado para administradores',
				'Sharing Disabled': 'Compartir discapacitados',

				'DayBack Share Not Found': 'DayBack Compartir No encontrado',
				'The share you have requested has expired or does not exist.':
					'El recurso compartido que ha solicitado ha caducado o no existe.',
				'Why did this happen?': '¿Por qué pasó esto?',

				'Want more? Get your own DayBack.':
					'¿Quieren más? Obtenga su propio DayBack...',
				'Try DayBack for Free': 'Prueba DayBack Gratis',

				Update: 'Actualizar',
				Edit: 'Editar',
				Visit: 'Visitar',
				'Bookmark/Share Detail': 'Marcar/Compartir Detalle',
				'Created by': 'Creado por',
				'Created By': 'Creado por',
				'Expires In': 'Expira en',
				Expires: 'Caduca',
				Expired: 'Muerto',
				New: 'Nueva',
				Create: 'Crear',
				'Show In Timezone': 'Mostrar en zona horaria',
				'Extend View': 'Ampliar vista',
				'Create Share': 'Crear Compartir',
				'Shared Dates': 'Fechas compartidas',
				'Share with': 'Compartir con',
				'Shared with': 'Compartido con',
				'Just Me': 'Solo yo',
				'My DayBack Group': 'Mi grupo DayBack',
				Public: 'Publico',
				'Public share options': 'Opciones de compartidas públicas',
				Bookmark: 'Marcador',
				Created: 'Creado',
				URL: 'URL',
				'Embed Code': 'Empotrar Código',
				'Update Share': 'Actualizar Compartir',
				'Update Bookmark': 'Actualizar marcador',
				'Copy URL': 'Copiar URL',
				'Copy Embed Code': 'Copie el código empotrar',
				'Copied to clipboard': 'Copiado al portapapeles',
				'Please copy the selected text':
					'Por favor, copie el texto seleccionado',
				'Creating share': 'Crear cuota',
				'Updating share': 'Actualización del recurso compartido',
				'Bookmark created successfully':
					'Compartir creado correctamente',
				'Share updated successfully':
					'Compartir actualizado correctamente',
				'Updating takes you to the view you have shared':
					'La actualización le lleva a la vista que ha compartido y vuelve a sincronizar los elementos coincidentes.',
				'Remember changes made are automatically synced':
					'Recuerde, los cambios realizados en DayBack se sincronizan automáticamente con sus acciones.',
				'Updating this bookmark will reset the filters':
					'Actualizar este marcador restablecerá los filtros, la vista y el rango de fechas (si corresponde) a lo que está viendo en este momento.',

				'Authorization Failed': 'Autorización fallida',
				'Connecting To DayBack': 'Conectando a DayBack',
				'Get Started': 'Empezar',
				'Sharing not Activated': 'Compartir no activado',
				'Sharing Enabled': 'Compartir habilitado',
				'There are no shares yet': 'Todavía no hay acciones',

				Subscribe: 'Suscribir',
				'Sharing Trial Expired': 'El proceso de compartir expiró',
				'Sharing Subscription Expired':
					'Suscripción de compartir caducada',

				'Custom Fields': 'Campos Personalizados',
				'Select Date': 'Seleccione fecha',

				'Add Field': 'Agregue Campo',
				'Add Button': 'Agregar botón',

				'View In Source': 'Ver en {{source}}',

				'Basecamp HTTPS Required':
					'Basecamp requiere una conexión segura. ¿Quieres recargar DayBack usando HTTPS?',
				'HTTPS Connection Required': 'Se requiere conexión HTTPS',
				'Reload as HTTPS': 'Actualizar como HTTPS',

				Total: 'Total',
				'Chart Data': 'Datos del gráfico',
				Format: 'Formato',
				Threshold: 'Límite',
				'Chart the value of a specific field:':
					'Grafique el valor de un campo específico:',
				'Chart the number of items': 'Grafica la cantidad de artículos',
				'Chart the duration of items':
					'Grafica la duración de los artículos',
				'Show a threshold value on the chart':
					'Mostrar un valor de umbral en el gráfico',
				Value: 'Valor',
				'Line Color': 'Color de linea',
				'Fill Color': 'Color de relleno',
				'Fill Opacity': 'Llena la opacidad',
				'color formats?': 'formatos de color?',
				'Color formats instructions':
					'Utilice cualquier color CSS, incluidos los valores RGB, RGBA o Hex',

				'Format as': 'Formatear como',
				'Label Before Value': 'Etiqueta antes',
				'Label After Value': 'Etiqueta después',
				'Decimal Symbol': 'Símbolo decimal',
				'Decimal Places': 'Lugares decimales',
				'Thousands Separator': 'Separador de miles',

				event: 'evento',
				events: 'eventos',
				item: 'ít',
				items: 'elementos',
				'Over by': 'Más de',
				'Under by': 'Menos de',
				'Chart by day, week': 'Gráfico por día, semana',
				Auto: 'Auto',

				'Add Calendar': 'Añadir calendario',
				'Calendar is read-only': 'El calendario es de solo lectura',

				Folder: 'Carpeta',
				'Folder Name': 'Nombre de la carpeta',

				'Only applies to horizon view at day scale':
					'Solo se aplica a la vista del horizonte a escala de día',

				'Switch Accounts': 'Cambiar cuentas',
				Deauthorize: 'Desautorizar',
				Authorize: 'Autorizar',
				'Maybe Later': 'Quizas mas tarde',

				'Grant Access To Your Google Account':
					'Conceder acceso a su cuenta de {{type}}',
				'To view your Google Calendars you will want to allow DayBack to access your Google account':
					'Para ver sus calendarios de {{type}}, deberá permitir que DayBack acceda a su cuenta de {{type}}.',
				'Welcome To DayBack': 'Bienvenido a DayBack',

				'Waiting for events': 'Esperando eventos',
				'Cancel Request': 'Cancelar petición',

				Theme: 'Tema',

				'Moving sample data':
					'Mover datos de muestra a su lugar. Esto puede tomar un momento.',

				'Switch to this date with the bookmark':
					'Cambie a esta fecha con el marcador',
				'Changing switch date':
					'Cambiar esta configuración para un marcador público cambiará el intervalo de fechas actual para la vista y actualizará los eventos compartidos para que coincidan con lo que se muestra en la vista',

				'There was a problem signing in':
					'Hubo un problema al firmar en {{account}}',
				'Your current DayBack license does not support this feature.':
					'Su licencia actual de DayBack no es compatible con esta función.',
				'Your subscription does not have enough user licenses':
					'Su suscripción no tiene suficientes licencias de usuario.',
				'There was an issue retrieving data from the specified group token':
					'Se produjo un problema al recuperar los datos del token de grupo especificado.',
				'Please contact your FileMaker developer':
					'Póngase en contacto con su desarrollador de FileMaker.',
				Account: 'Cuenta',
				Accounts: 'Cuentas',
				and: 'y',
				"Calendar Can't be found":
					'No se puede encontrar el calendario',
				'Bookmark calendar not found message':
					'El {{calendarSchedules}} no se puede encontrar. Por favor autorice el {{calendarSources}} correcto para {{action}} this bookmark.',

				'First linked event': 'Primer evento vinculado',
				'Previous linked event': 'Evento vinculado anterior',
				'Step through linked events': 'Pasar por eventos vinculados',
				'End of linked events': 'Fin de eventos vinculados',
				'Next linked event': 'Próximo evento vinculado',
				'Last linked event': 'Último evento vinculado',
				Pause: 'Pausa',

				Attachments: 'Archivos adjuntos',
				'The original event has attachments':
					'El evento original tiene archivos adjuntos. Esos archivos adjuntos se eliminarán del evento duplicado.',

				'Filter resources': 'Filtrar recursos',
				'No resources match your criteria':
					'Ningún recurso coincide con sus criterios',

				Filter: 'Filtrar',
				Class: 'Class',
				Tags: 'Etiquetas',
				Capacity: 'Capacidad',
				'comma separated': 'Separado por comas',
				'No additional details': 'Sin detalles adicionales',
				'This item was created dynamically and should be modified in its original source':
					'Este elemento se creó dinámicamente y debe modificarse en su fuente original.',

				'Over under threshold': 'Por encima/por debajo del umbral',
				over: 'más de',
				'Includes partial week': 'Incluye semana parcial',
				'Includes partial month': 'Incluye mes parcial',
				'Public bookmarks expire essentials plan':
					'Los marcadores públicos vencen en 72 horas en el plan Essentials. Para los calendarios públicos que duran más de 3 días, actualice al plan Plus, donde puede establecer su propia fecha de vencimiento.',
				'Learn more about DayBack Plans and Pricing':
					'Obtenga más información sobre los planes y precios de DayBack',
				'No events found': 'No se encontraron eventos',

				'Service will end days unless payment is received':
					'El servicio terminará en {{days}} días a menos que se reciba el pago',
				'Service will end day unless payment is received':
					'El servicio terminará en {{days}} día a menos que se reciba el pago',

				Drop: 'soltar',
				'Count Unscheduled Items': '{{count}} elementos no programados',
				'Count Unscheduled Item': '{{count}} artículo no programado',
				'No Unscheduled Items': 'Sin elementos no programados',
				'Unscheduled is not available for this calendar':
					'No programado no está disponible para este calendario',

				'Show as resource for me': 'Mostrar como recurso para mí',

				Assigned: 'Asignado',

				'Public shares will be deleted 30 days after expiration':
					'Las acciones públicas se eliminarán 30 días después de su vencimiento.',
				'Will be deleted 30 days after expiration':
					'Se eliminará 30 días después del vencimiento',
			},
		};

		//French language and substitutions  - Spceial thanks to Bernard Leroy
		defaultTranslations['fr'] = {
			id: 'fr',
			name: 'français',
			translations: {
				//These are name value pairs. Customize the values only (the item after the colon)
				Home: 'Début',
				Calendars: 'Calendriers',
				Filters: 'Filtres',
				Settings: 'Paramètres',

				'Hide Sidebar': 'Masquer le menu latéral',
				'Show Sidebar': 'Afficher le menu latéral',

				//repeating event items
				Repeat: 'Répéter',
				January: 'janvier',
				February: 'février',
				March: 'Mars',
				April: 'avril',
				May: 'mai',
				June: 'juin',
				July: 'juillet',
				August: 'août',
				September: 'septembre',
				October: 'octobre',
				November: 'novembre',
				December: 'décembre',
				Monday: 'Lundi',
				Tuesday: 'Mardi',
				Wednesday: 'Mercredi',
				Thursday: 'Jeudi',
				Friday: 'Vendredi',
				Saturday: 'Samedi',
				Sunday: 'Dimanche',
				Daily: 'du quotidien',
				Weekly: 'Hebdomadaire',
				Monthly: 'Mensuel',
				Yearly: 'Annuel',
				'1st': 'Premier',
				'2nd': 'Seconde',
				'3rd': 'Troisième',
				'4th': 'Quatrième',
				Last: 'Dernier',
				'repeat every': 'répétez tous les',
				'day on': 'jour sur',
				'days on': 'jours après',
				'week on': 'semaine sur',
				'weeks on': 'semaines sur',
				'month on': 'mois sur',
				'months on': 'mois après',
				'year on': 'année sur',
				'years on': 'années après',
				'stop after': 'arrêter après',
				never: 'jamais',
				'on the': 'sur le',
				'Your changes will only apply to this event, not to its other repetitions. Would you still like to save this change?':
					"Vos modifications ne s'appliqueront qu'à cet événement, et non à ses autres répétitions. Voulez-vous toujours enregistrer cette modification?",
				'Edit only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Éditez uniquement cet élément, ceci et toutes les répétitions futures, ou tous les répétitions? Ça ne peut pas être annulé.',
				'Delete only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Supprimez uniquement cet élément, ceci et toutes les répétitions futures, ou tous les répétitions? Ça ne peut pas être annulé.',
				'Edit only this item or this and all future repetitions? This cannot be undone.':
					'Éditez uniquement cet élément ou ceci et toutes les répétitions futures? Ça ne peut pas être annulé.',
				'Edit Repeating Item': "Modifier l'article à répéter",
				'Delete Repeating Item': "Supprimer l'article en répétition",
				'Only This Item': 'Seulement cet élément',
				'All Future Repetitions': 'Toutes les répétitions futures',
				'All Repetitions': 'Toutes les répétitions',
				'Would you like to delete all future repetitions and create new ones? This cannot be undone':
					'Voulez-vous supprimer toutes les répétitions futures et en créer de nouvelles? Ça ne peut pas être annulé',
				'Repeating Event': 'Événement répétitif',
				'Delete Only': 'Supprimer uniquement',
				'Delete and New': 'Supprimer et nouveau',
				'Future Repetitions Deleted': 'Répétations futures supprimées',
				'must be on or before': 'doit être au plus tard',

				Day: 'Jour',
				Schedule: 'Planning',
				List: 'Liste',
				Week: 'Semaine',
				Month: 'Mois',
				Resource: 'Ressources',
				Grid: 'grille',
				'Pivot Schedule': 'Calendrier de Pivot',
				'Pivot List': 'Liste de Pivot',

				'Snap To Month': 'Ajoute au mois',

				Today: "Aujourd'hui",
				Resources: 'Ressources',
				resources: 'ressources',

				Calendar: 'Calendrier',
				All_Day: 'Jour entier',
				Start: 'Début',
				End: 'Fin',
				Status: 'Statut',
				Location: 'Emplacement',
				Contact: 'Contacts',
				Project: 'Projet',
				Done: 'Terminé',

				Delete: 'Effacer',
				Close: 'Fermer',
				'Save And Close': 'Enregistrer',
				'Save And Back': 'Enregistrer',
				Back: 'Retour',

				'Show on Map': 'Afficher sur la carte',
				'Open URL': 'Ouvrir le lien',
				'Open Map': 'Ouvrir la carte',
				'Location not found': 'Emplacement not found',
				'Enter an address or location name':
					'Entrez une adresse ou le nom du lieu',

				Search: 'Recherche',
				none: 'aucun',

				Description: 'description',
				'Enter Title': 'Entrez le titre',
				'resource inherited from calendar':
					'ressource héritée de calendrier',

				'Clear Selection': 'Effacer la sélection',

				New_Event_Title: 'Nouvel événement',
				'New Event': 'Nouvel événement',
				'View Day': 'Voir le Jour',
				'You have no editable calendars selected':
					"Vous n'avez pas de calendrier modifiable sélectionné",
				'You may not edit shared views':
					'Vous ne pouvez pas modifier les vues partagées',
				'You have no calendar sources configured':
					"Vous n'avez aucune source de calendrier configurée",
				'You have no calendars selected that allow times':
					"Vous n'avez pas de calendriers sélectionnés qui autorisent des périodes",
				'Could not create a new event because all breakout items are hidden':
					'Impossible de créer un nouvel événement, car tous les éléments de discussion sont masqués',
				"'All Day Event' is currently disabled for Events":
					'"Événement toute la journée" est actuellement désactivé pour les événements',

				'Time Settings': 'Paramêtres horaires',
				'Default Time': 'Délai par défaut',
				'Earliest Time': 'Première Heure',
				'Latest Time': 'Dernière heure',
				'Time Scale': 'Intervalle',
				'View Settings': "Paramètres d'affichage",
				'Compressed View': 'Voir comprimé',
				'Fluid Months': 'Affichage étendu',

				Yes: 'Oui',
				No: 'Non',

				'Advanced Filters': 'filtres avancés',
				'Clear Filters': 'Effacer les filtres',

				Week_Number_Header: 'Sem',
				more: 'plus',

				hour: 'heure',
				hours: 'heures',
				minute: 'minute',
				minutes: 'minute',

				Text: 'Texte',
				'Status Filters': "Filtres d'état",
				'Resource Filters': 'Filtres de ressources',
				'Project Filters': 'Filtres de projet',
				'Contact Filters': 'Filtres de contact',

				'New Status': 'Nouveau statut',
				'New Resource': 'Nouvelle ressource',
				'New Folder': 'Nouveau dossier',

				Sources: 'sources',
				Save: 'sauver',
				Cancel: 'annuler',
				cancel: 'annuler',

				'Show as resource': 'Afficher comme ressource',
				'Calendar for new entries': 'Calendrier primaire',
				'for me': 'pour moi',
				'for everyone': 'pour touts',
				'disabled by your administrator':
					'Désactivé par votre administrateur',

				'Call your own scripts': 'Appelez vos propres scripts',

				'Status Filter': "Filtre d'état",

				Name: 'nom',
				Color: 'Couleur',

				day: 'jour',
				days: 'jours',

				'Account Settings': 'Paramètres de enregistrement',
				'Trial Mode': 'Mode essai',
				'Contact Us': 'Contactez-nous',
				Help: 'Aidez-moi',
				Documentation: 'Documentation',
				'What Is New': 'Quoi de neuf',
				'Sign Out': 'Déconnexion',

				'Check For Updates': 'Vérifiez les mises à jour',
				'Install Update': 'Installer la mise à jou',
				'what is new': 'Quoi de neuf',

				'Load Calendar': 'Charger le calendrier',

				'Purchase DayBack': 'Achat DayBack',
				'Switch To Subscription': 'Passer à la souscription',
				'Manage Subscription': 'Gestion de votre souscription',
				'Enter New License': 'Entrez une nouvelle licence',
				Activate: 'Activer',

				'Trial activated':
					'Votre période d’essai de {{days}} a été activée',
				'Trial expired': 'La Période d’essai a expiré',
				'Trial expired message':
					'La Période d’essai a expiré . Vous pouvez acheter une licence pour continuer.',
				'Trial remains': '{{days}} laissés dans votre essai',
				'Trial expires in days': 'Votre essai expire dans {{days}}.',

				'Subscription expired':
					'Votre abonnement a expiré il y a {{days}}',
				'Subscription expired message':
					'Votre abonnement a expiré. Merci de le renouveler pour continuer.',
				'Cease to function message':
					'Si une nouvelle licence n’est pas saisie  le calendrier cessera de fonctionner dans {{days}}',

				'Subscription renews':
					'Votre abonnement a été renouvellé pour {{days}}',
				'Subscription renews today':
					"L'abonnement se renouvelle aujourd'hui",
				'Subscription should have renewed':
					"L'abonnement doit avoir été renouvelé il y a {{days}}",

				'Subscription overdue message admin':
					"Le paiement de l'abonnement est en souffrance. Gérez votre abonnement et mettez à jour les informations de paiement.",
				'Subscription overdue message non admin':
					"Le paiement de l'abonnement est en souffrance. Gérez votre abonnement et mettez à jour les informations de paiement.",

				Purchased: 'Acheté',

				Product: 'Produit',
				'Order Number': "Numéro d'ordre",
				Email: 'Email',
				'Registered To': 'Enregistré pour',

				'Current version': 'Vous utilisez la version: {{version}}',
				'Version available': 'La version {{version}} est disponible',
				'Running latest version': 'Vous utilisez la dernière version',
				'Cannot connect':
					'Impossible de se connecter à mettre à jour le serveur',
				'Problem downloading update':
					'Il y a eu un problème au téléchargement de la mise à jour.',

				'Activation denied':
					"La demande d'activation a été refusée. Merci de vérifier vos informations et réessayer",
				'Activation successful': 'Activation réussie',

				TooManyUsers1: "Trop d'utilisateurs",
				TooManyUsers2: "Trop d'utilisateurs ont ouvert le calendrier.",
				TooManyUsers3:
					'Vous pouvez acheter une mise à niveau pour permettre à plusieurs utilisateurs, ou réinitialiser temporairement les utilisateurs actuellement comptés afin que vous puissiez continuer à travailler maintenant.',
				'Purchase Upgrade': 'Achat de mise à niveau',
				'Reset User Count': "Réinitialiser Nombre d'utilisateurs",

				'Mobile not supported':
					'Ce ne est actuellement pas pris en charge sur iOS',
				'Update Successful': 'mise à jour réussie',

				'Resource Columns': 'Colonnes de ressources',

				Refresh: 'rafraîchir',
				'Show Weekends': 'Voir les week-ends',
				Days: 'journées',
				of: 'de',

				Fetching: 'Comparaison',

				'Extend In-App Updates':
					'Étendre les mises à jour intégrées aux applications',
				'Updates expired message':
					"L'accès aux mises à jour intégrées à l'application a expiré il y a {{days}}",
				'Free updates remaining message':
					'{{days}} des in-app mises à jour restantes',

				'Week of': 'Semaine de',
				weeks: 'semaines',
				week: 'semaine',

				months: 'mois',
				month: 'mois',

				years: 'années',
				year: 'an',

				'Show Distances': 'Afficher les distances',
				Horizon: 'Horizon',
				'Breakout By': 'Évasion par',
				nothing: 'rien',
				'Horizon Settings': 'Horizon Paramètres',

				'Breakout by Resource': 'Répartition par ressource',
				'Breakout by Status': 'Répartition par statut',
				'Repeating Events': 'Événements répétitifs',
				'Hide all repetitions': 'Masquer toutes les répétitions',
				'Hide daily': 'Masquer quotidiennement',
				'Hide weekly': 'Masquer chaque semaine',
				'Hide monthly': 'Masquer tous les mois',
				'Hide yearly': 'Masquer chaque année',

				Saved: 'Enregistré',
				Deleted: 'Supprimé',
				Undo: 'annuler',
				'No Change History Available':
					"Pas d'Histoire de Modification Disponible",
				'Event may be off screen':
					"L'événement peut être hors de l'écran",

				'Operation Failed': "L'opération a échoué",
				'Revert Changes': 'Inverser les modifications',
				'Changes Reverted': 'Modifications Annulées',
				'Return To Event': "Retour à l'événement",

				'Authentication Successful': 'authentification réussie',
				'Please close to return to the calendar':
					"S'il vous plaît près de revenir au calendrier",

				'See in-app updates available since then':
					'Voir les mises à jour in-app disponibles depuis lors',

				Views: 'Vues',
				'Horizon View': 'Vue horizon',
				'Resource View': 'Vue des ressources',
				'Month View': 'Vue du mois',
				Fluid: 'Fluide',
				'Fit to screen': "S'adapter a l'ecran",

				'Admin Settings': 'Paramètres administrateur',
				'Administrator Settings': 'Réglages Administrateur',
				'My Settings': 'Mes paramètres',
				'Contact Us And Docs': 'Contactez-nous & Docs',
				'Sign-Out': 'Se déconnecter',
				Feedback: "Retour d'information",
				'Schedule a Demo': 'Planifier une démo',

				'events can only be moved to other calendars when they are first created':
					"{{source}} les événements ne peuvent être déplacés vers d'autres calendriers quand ils sont d'abord créés.",

				'Editing Scale': 'Montage échelle',

				'defend your time': 'défendre votre temps',
				'add to calendar': 'ajouter au calendrier',
				More: 'Plus',

				'Invalid View': 'Invalid Voir',
				'Invalid view message':
					'Un point de vue non valide a été utilisé pour naviguer dans le calendrier et a été ignoré.',
				'Invalid Date': 'Date invalide',
				'Invalid date message':
					'Une date non valide a été utilisé pour naviguer dans le calendrier et a été ignoré.',
				'Invalid Bookmark ID': 'ID de signet non valide',
				'Invalid Bookmark ID message':
					"ID de signet {{bookmarkID}} n'est pas valide ou ne fait pas partie de votre groupe DayBack",
				Error: 'Erreur',
				'Bookmark update filter error message':
					'La mise à jour ne peut pas être effectuée, car la liste de filtres ne correspond plus à celle enregistrée avec le signet. Veuillez créer et partager un nouveau signet à la place.',
				'Bookmark visit filter error message':
					'La liste de filtres ne correspond plus à celle enregistrée avec le signet, il est donc possible que ce signet soit obsolète.',

				Continue: 'Continuer',
				'Learn More': 'Apprendre encore plus',

				Share: 'Partager',
				'Bookmark or Share This View': 'Marquer ou partager cette vue',
				'Manage Bookmarks/Shares': 'Gérer les signets/les partages',
				'Recent Bookmarks': 'Signets récents',
				'Show All': 'Afficher tout',
				'DayBack Mission': 'Mission de DayBack',
				Blog: 'Blog',
				'Get Your Own DayBack': 'Obtenir votre propre DayBack',
				'Contact DayBack': 'Contactez DayBack',
				'About DayBack': 'À propos de DayBack',

				'Enable Sharing': 'Activer le partage',
				Off: 'De',
				Administrator: 'Administrateur',
				Administrators: 'Administrateurs',
				Everyone: 'Toutes les personnes',

				'Enabled for Administrators': 'Activé pour les administrateurs',
				'Sharing Disabled': 'Partage de personnes handicapées',

				'DayBack Share Not Found': 'DayBack Part non trouvée',
				'The share you have requested has expired or does not exist.':
					"Le partage que vous avez demandé est périmé ou n'existe pas.",
				'Why did this happen?': 'Pourquoi est-ce arrivé?',

				'Want more? Get your own DayBack.':
					'Vouloir plus? Obtenez votre propre DayBack...',
				'Try DayBack for Free': 'Essayez DayBack Gratuit',

				Update: 'Mettre à jour',
				Edit: 'Éditer',
				Visit: 'Visite',
				'Bookmark/Share Detail': 'Signet/Partager detalle',
				'Created by': 'Créé par',
				'Created By': 'Créé par',
				'Expires In': 'Expire dans',
				Expires: 'Expire',
				Expired: 'Expiré',
				New: 'Nouvelle',
				Create: 'Créer',
				'Show In Timezone': 'Afficher dans le fuseau horaire',
				'Extend View': 'Étendre la vue',
				'Create Share': 'Créer un compte',
				'Shared Dates': 'Dates partagées',
				'Share with': 'Partager avec',
				'Shared with': 'Partagé avec',
				'Just Me': 'Juste moi',
				'My DayBack Group': 'Mon groupe DayBack',
				Public: 'Public',
				'Public share options': 'Options de partage public',
				Bookmark: 'Signet',
				Created: 'Créé',
				URL: 'URL',
				'Embed Code': "Code d'intégration",
				'Update Share': 'Mettre à jour le signet',
				'Update Bookmark': 'Actualizar marcador',
				'Copy URL': 'Copier le lien',
				'Copy Embed Code': "Copier le code d'intégration",
				'Copied to clipboard': 'Copié dans le presse-papier',
				'Please copy the selected text':
					'Veuillez recopier le texte sélectionné.',
				'Creating share': 'Création de partage',
				'Updating share': 'Mise à jour de partage',
				'Bookmark created successfully': 'Signet créé avec succès',
				'Share updated successfully': 'Partager mis à jour avec succès',
				'Updating takes you to the view you have shared':
					'La mise à jour vous amène à la vue que vous avez partagée et re-synchronise les éléments correspondants.',
				'Remember changes made are automatically synced':
					"N'oubliez pas que les modifications apportées à DayBack sont automatiquement synchronisées avec vos partages.",
				'Updating this bookmark will reset the filters':
					'La mise à jour de ce signet réinitialisera les filtres, la vue et la plage de dates (le cas échéant) sur ce que vous voyez actuellement.',

				'Authorization Failed': "L'autorisation à échouée",
				'Connecting To DayBack': 'Connexion au DayBack',
				'Get Started': 'Commencer',
				'Sharing not Activated': 'Partage pas activé',
				'Sharing Enabled': 'Partage activé',
				'There are no shares yet': "Pas encore d'actions",

				Subscribe: 'Souscrire',
				'Sharing Trial Expired': 'Échantillonnage de partage expiré',
				'Sharing Subscription Expired':
					"Partage de l'abonnement expiré",

				'Custom Fields': 'Les champs personnalisés',
				'Select Date': 'Sélectionnez la date',

				'Add Field': 'Ajouter le champ',
				'Add Button': 'Ajouter un bouton',

				'View In Source': 'Afficher en {{source}}',

				'Basecamp HTTPS Required':
					'Basecamp nécessite une connexion sécurisée. Voulez-vous recharger DayBack en utilisant HTTPS?',
				'HTTPS Connection Required': 'Connexion HTTPS requise',
				'Reload as HTTPS': 'Rechargez comme HTTPS',

				Total: 'Total',
				'Chart Data': 'Données de graphique',
				Format: 'Format',
				Threshold: 'Seuil',
				'Chart the value of a specific field:':
					"Représenter la valeur d'un champ spécifique:",
				'Chart the number of items': "Graphique le nombre d'éléments",
				'Chart the duration of items':
					'Graphique la durée des éléments',
				'Show a threshold value on the chart':
					'Afficher une valeur de seuil sur le graphique',
				Value: 'Valeur',
				'Line Color': 'Couleur de la ligne',
				'Fill Color': 'La couleur de remplissage',
				'Fill Opacity': "Remplir l'opacité",
				'color formats?': 'formats de couleurs?',
				'Color formats instructions':
					"Utilisez n'importe quelle couleur CSS, y compris les valeurs RGB, RGBA ou Hex",

				'Format as': 'Format comme',
				'Label Before Value': 'Étiquette avant',
				'Label After Value': 'Étiquette après',
				'Decimal Symbol': 'Symbole décimal',
				'Decimal Places': 'Décimales',
				'Thousands Separator': 'Séparateur de milliers',

				event: 'un événement',
				events: 'événements',
				item: 'Objet',
				items: 'éléments',
				'Over by': 'Terminé à',
				'Under by': 'Moins de',
				'Chart by day, week': 'Graphique par jour, semaine',
				Auto: 'Auto',

				'Add Calendar': 'Ajouter un calendrier',
				'Calendar is read-only': 'Le calendrier est en lecture seule',

				Folder: 'Dossier',
				'Folder Name': 'Nom de dossier',

				'Only applies to horizon view at day scale':
					"S'applique uniquement à la vue d'horizon à l'échelle du jour",

				'Switch Accounts': 'Changer de compte',
				Deauthorize: "Annuler l'autorisation",
				Authorize: 'Autoriser',
				'Maybe Later': 'Peut-être plus tard',

				'Grant Access To Your Google Account':
					"Accordez l'accès à votre compte {{type}}",
				'To view your Google Calendars you will want to allow DayBack to access your Google account':
					'Pour afficher vos calendriers {{type}}, vous devez autoriser DayBack à accéder à votre compte {{type}}.',
				'Welcome To DayBack': 'Bienvenue chez DayBack',

				'Waiting for events': "En attente d'événements",
				'Cancel Request': "Demande d'annulation",

				Theme: 'Thème',

				'Moving sample data':
					'Déplacement des exemples de données en place. Cela peut prendre un moment.',

				'Switch to this date with the bookmark':
					'Passer à cette date avec le signet',
				'Changing switch date':
					'La modification de ce paramètre pour un signet public modifiera la plage de dates actuelle pour la vue et mettra à jour les événements partagés pour correspondre à ce qui est affiché dans la vue',

				'There was a problem signing in':
					'Un problème est survenu lors de la signature de {{account}}',
				'Your current DayBack license does not support this feature.':
					'Votre licence DayBack actuelle ne prend pas en charge cette fonctionnalité.',
				'Your subscription does not have enough user licenses':
					'Votre abonnement ne dispose pas de suffisamment de licences utilisateur.',
				'There was an issue retrieving data from the specified group token':
					'Un problème est survenu lors de la récupération des données du jeton de groupe spécifié.',
				'Please contact your FileMaker developer':
					'Veuillez contacter votre développeur FileMaker.',
				account: 'Compte',
				Accounts: 'Comptes',
				and: 'et',
				"Calendar Can't be found": 'Calendrier introuvable',
				'Bookmark calendar not found message':
					'Le {{calendarSchedules}} ne peut pas être trouvé. Veuillez autoriser le {{calendarSources}} correct pour {{action}} ce signet.',

				'First linked event': 'Premier événement lié',
				'Previous linked event': 'Événement lié précédent',
				'Step through linked events': 'Parcourez les événements liés',
				'End of linked events': 'Fin des événements liés',
				'Next linked event': 'Prochain événement lié',
				'Last linked event': 'Dernier événement lié',
				Pause: 'Pause',

				Attachments: 'Pièces jointes',
				'The original event has attachments':
					"L'événement d'origine a des pièces jointes. Ces pièces jointes seront supprimées de l'événement dupliqué.",

				'Filter resources': 'Filtrer les ressources',
				'No resources match your criteria':
					'Aucune ressource ne correspond à vos critères',

				Filter: 'Filtre',
				Class: 'Class',
				Tags: 'Mots clés',
				Capacity: 'Capacité',
				'comma separated': 'virgules',
				'No additional details': 'Pas de détails supplémentaires',
				'This item was created dynamically and should be modified in its original source':
					"Cet élément a été créé dynamiquement et doit être modifié dans sa source d'origine",

				'Over under threshold': 'Seuil supérieur/inférieur',
				over: 'sur',
				'Includes partial week': 'Comprend une semaine partielle',
				'Includes partial month': 'Comprend un mois partiel',
				'Public bookmarks expire essentials plan':
					"Les signets publics expirent au bout de 72 heures dans le plan Essentials. Pour les calendriers publics qui durent plus de 3 jours, veuillez passer au plan Plus où vous pouvez définir votre propre date d'expiration.",
				'Learn more about DayBack Plans and Pricing':
					'En savoir plus sur les forfaits DayBack et leurs tarifs',
				'No events found': 'Aucun événement trouvé',
				'Service will end days unless payment is received':
					'Le service se terminera dans {{days}} jours à moins que le paiement ne soit reçu',
				'Service will end day unless payment is received':
					'Le service se terminera dans {{days}} jour à moins que le paiement ne soit reçu',

				Drop: 'déposer',
				'Count Unscheduled Items': '{{count}} éléments non planifiés',
				'Count Unscheduled Item': '{{count}} élément non planifié',
				'No Unscheduled Items': 'Aucun élément non planifié',
				'Unscheduled is not available for this calendar':
					"Non planifié n'est pas disponible pour ce calendrier",

				'Show as resource for me': 'Afficher comme ressource pour moi',

				Assigned: 'Attribué',

				'Public shares will be deleted 30 days after expiration':
					"Les actions publiques seront supprimées 30 jours après l'expiration",
				'Will be deleted 30 days after expiration':
					'Sera supprimé 30 jours après expiration',
			},
		};

		//German language and substitutions - Special thanks to Juergen Joeckel and Maria Gross
		defaultTranslations['de'] = {
			id: 'de',
			name: 'Deutsch',
			translations: {
				//These are name value pairs. Customize the values only (the item after the colon)
				Home: 'Home',
				Calendars: 'Kalender',
				Filters: 'Filter',
				Settings: 'Einstellungen',

				'Hide Sidebar': 'Seitenleiste ausblenden',
				'Show Sidebar': 'Seitenleiste anzeigen',

				//repeating event items
				Repeat: 'Wiederholen',
				January: 'Januar',
				February: 'Februar',
				March: 'März',
				April: 'April',
				May: 'Mai',
				June: 'Juni',
				July: 'Juli',
				August: 'August',
				September: 'September',
				October: 'Oktober',
				November: 'November',
				December: 'Dezember',
				Monday: 'Montag',
				Tuesday: 'Dienstag',
				Wednesday: 'Mittwoch',
				Thursday: 'Donnerstag',
				Friday: 'Freitag',
				Saturday: 'Samstag',
				Sunday: 'Sonntag',
				Daily: 'Täglich',
				Weekly: 'Wöchentlich',
				Monthly: 'Monatlich',
				Yearly: 'Jährlich',
				'1st': 'Zuerst',
				'2nd': 'Zweite',
				'3rd': 'Dritte',
				'4th': 'Vierte',
				Last: 'Letzte',
				'repeat every': 'wiederholen sie alle',
				'day on': 'tag an',
				'days on': 'tage auf',
				'week on': 'woche auf',
				'weeks on': 'wochen auf',
				'month on': 'monat auf',
				'months on': 'monate auf',
				'year on': 'jahr auf',
				'years on': 'jahre auf',
				'stop after': 'halt nach',
				never: 'nie',
				'on the': 'auf der',
				'Your changes will only apply to this event, not to its other repetitions. Would you still like to save this change?':
					'Ihre Änderungen gelten nur für diese Veranstaltung, nicht für ihre anderen Wiederholungen. Möchten Sie diese Änderung noch speichern?',
				'Edit only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Bearbeiten Sie nur dieses Element, diese und alle zukünftigen Wiederholungen oder alle Wiederholungen? Das kann nicht rückgängig gemacht werden.',
				'Delete only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Löschen Sie nur dieses Element, diese und alle zukünftigen Wiederholungen oder alle Wiederholungen? Das kann nicht rückgängig gemacht werden.',
				'Edit only this item or this and all future repetitions? This cannot be undone.':
					'Bearbeiten Sie nur diesen Artikel oder diese und alle zukünftigen Wiederholungen? Das kann nicht rückgängig gemacht werden.',
				'Edit Repeating Item': 'Bearbeiten Wiederholungsobjekt',
				'Delete Repeating Item': 'Wiederholungsobjekt löschen',
				'Only This Item': 'Nur dieser Artikel',
				'All Future Repetitions': 'Alle zukünftigen Wiederholungen',
				'All Repetitions': 'Alle Wiederholungen',
				'Would you like to delete all future repetitions and create new ones? This cannot be undone':
					'Möchten Sie alle zukünftigen Wiederholungen löschen und neue erstellen? Das kann nicht rückgängig gemacht werden',
				'Repeating Event': 'Wiederholungsereignis',
				'Delete Only': 'Nur löschen',
				'Delete and New': 'Löschen und neu',
				'Future Repetitions Deleted':
					'Zukünftige Wiederholungen gelöscht',
				'must be on or before': 'muss vor oder vor sein',

				Day: 'Tag',
				Schedule: 'Plan',
				List: 'Liste',
				Week: 'Woche',
				Month: 'Monat',
				Resource: 'Ressource',
				Grid: 'Gitternetz',
				'Pivot Schedule': 'Pivot Zeitplan',
				'Pivot List': 'Pivot-Liste',

				'Snap To Month': 'schnappen bis zum monat',

				Today: 'Heute',
				Resources: 'Ressourcen',
				resources: 'ressourcen',

				Calendar: 'Kalender',
				All_Day: 'Ganztags',
				Start: 'Start',
				End: 'Ende',
				Status: 'Status',
				Location: 'Ort',
				Contact: 'Kontakt',
				Project: 'Projekt',
				Done: 'Getan',

				Delete: 'Löschen',
				Close: 'Schließen',
				'Save And Close': 'Speichern',
				'Save And Back': 'Speichern',
				Back: 'Zurück',

				'Show on Map': 'Auf der Karte anzeigen',
				'Open URL': 'Öffne URL',
				'Open Map': 'Karte öffnen',
				'Location not found': 'Die Lage ist nicht gefunden',
				'Enter an address or location name':
					'Geben Sie eine Adresse oder Ortsnamen',

				Search: 'Suche',
				none: 'keine(r)',

				Description: 'Beschreibung',
				'Enter Title': 'Titel eingeben',
				'resource inherited from calendar':
					'Ressource geerbt aus dem Kalender',

				'Clear Selection': 'Auswahl löschen',

				New_Event_Title: 'Neuer Termin',
				'New Event': 'Neuer Termin',
				'View Day': 'Tag anzeigen',
				'You have no editable calendars selected':
					'Sie haben keine bearbeitbaren Kalender ausgewählt',
				'You may not edit shared views':
					'Sie dürfen keine freigegebenen Ansichten bearbeiten',
				'You have no calendar sources configured':
					'Sie haben keine Kalenderquellen konfiguriert',
				'You have no calendars selected that allow times':
					'Sie haben keine Kalender ausgewählt, die Zeiten erlauben',
				'Could not create a new event because all breakout items are hidden':
					'Es konnte kein neues Ereignis erstellt werden, da alle Ausbruchselemente ausgeblendet sind',
				"'All Day Event' is currently disabled for Events":
					'Ganztagsereignis ist derzeit für Veranstaltungen deaktiviert',

				'Time Settings': 'Einstellung Zeit',
				'Default Time': 'Standardzeit',
				'Earliest Time': 'Früheste Zeit',
				'Latest Time': 'Späteste Zeit',
				'Time Scale': 'Zeitraum',
				'View Settings': 'Einstellungen anzeigen',
				'Compressed View': 'Druckansicht',
				'Fluid Months': 'Fließende Monate',

				Yes: 'Ja',
				No: 'Nein',

				'Advanced Filters': 'Erweiterte Filter',
				'Clear Filters': 'Filter löschen',

				Week_Number_Header: 'KW',
				more: 'mehr',

				hour: 'Stunde',
				hours: 'Stunden',
				minute: 'Minute',
				minutes: 'Minuten',

				Text: 'Text',
				'Status Filters': 'Status Filter',
				'Resource Filters': 'Ressource Filter',
				'Project Filters': 'Projekt Filter',
				'Contact Filters': 'Kontakt Filter',

				'New Status': 'Neuer Status',
				'New Resource': 'Neue Ressourcen',
				'New Folder': 'Neuer Ordner',

				Sources: 'Quellen',
				Save: 'Speichern',
				Cancel: 'Abbrechen',
				cancel: 'abbrechen',

				'Show as resource': 'Stellen Sie als Ressource',
				'Calendar for new entries': 'primären Kalender',
				'for me': 'für mich',
				'for everyone': 'für jeden',
				'disabled by your administrator': 'Deaktiviert',

				'Call your own scripts': 'Eigene Skripte aufrufen',

				'Status Filter': 'Status Filter',

				Name: 'Name',
				Color: 'Farbe',

				day: 'Tag',
				days: 'Tage',

				'Account Settings': 'Registrierungseinstellungen',
				'Trial Mode': 'Test Modus',
				'Contact Us': 'Kontaktieren sie uns',
				Help: 'Hilfe',
				Documentation: 'Dokumentation',
				'What Is New': "Was gibt's Neues",
				'Sign Out': 'Ausloggen',

				'Check For Updates': 'Nach Updates suchen',
				'Install Update': 'Update installieren',
				'what is new': "Was gibt's Neues?",

				'Load Calendar': 'Starten Kalender',

				'Purchase DayBack': 'DayBack kaufen',
				'Switch To Subscription': 'Zum Abonnement wechseln',
				'Manage Subscription': 'Abonnement verwalten',
				'Enter New License': 'Neue Lizenz eingeben',
				Activate: 'Aktivieren',

				'Trial activated': 'Ihre {{days}} Testzeit wurde aktiviert',
				'Trial expired': 'Ihre Testversion ist abgelaufen',
				'Trial expired message':
					'Testversion ist abgelaufen. Bitte erwerben Sie eine Lizenz, um fortzufahren.',
				'Trial remains': '{{days}} in Ihrer Testperiode verbleiben',
				'Trial expires in days':
					'Ihre Testversion läuft in {{days}} ab.',

				'Subscription expired': 'Abonnement vor {{days}} abgelaufen',
				'Subscription expired message':
					'Abonnement abgelaufen. Bitte erneuern.',
				'Cease to function message':
					'Wird keine neue Lizenz eingegeben wird der Kalender in {{days}} nicht mehr funktionieren',

				'Subscription renews': 'Abonnement erneuert sich in {{days}}',
				'Subscription renews today':
					'Das Abonnement verlängert sich heute',
				'Subscription should have renewed':
					'Das Abonnement sollte vor {{days}} verlängert worden sein',

				'Subscription overdue message admin':
					'Die Abonnementzahlung ist überfällig. Verwalten Sie Ihr Abonnement und aktualisieren Sie Zahlungsinformationen.',
				'Subscription overdue message non admin':
					'Die Abonnementzahlung ist überfällig. Verwalten Sie Ihr Abonnement und aktualisieren Sie Zahlungsinformationen.',

				Purchased: 'Gekauft',

				Product: 'Produkt',
				'Order Number': 'Bestellnummer',
				Email: 'Email',
				'Registered To': 'Registriert für',

				'Current version': 'Sie haben Version {{version}}',
				'Version available': 'Version {{version}} verfügbar',
				'Running latest version':
					'Sie arbeiten mit der neuesten Version',
				'Cannot connect':
					'Es konnte keine Verbindung zum update Server hergestellt werden',
				'Problem downloading update':
					'Es gab ein Problem beim Herunterladen der updates',

				'Activation denied':
					'Die Aktivierungsanforderung wurde abgelehnt. Bitte überprüfen Sie Ihre Angaben und versuchen es erneut',
				'Activation successful': 'Aktivierung erfolgreich',

				TooManyUsers1: 'Zu viele Benutzer',
				TooManyUsers2: 'Zu viele Benutzer haben den Kalender geöffnet.',
				TooManyUsers3:
					'Erwerben Sie ein Upgrade, um mehr Nutzer freizugeben, oder setzen Sie den Zähler für die zur Zeit aktiven Benutzer zurück, um sofort fortzufahren.',
				'Purchase Upgrade': 'Upgrade kaufen',
				'Reset User Count': 'Benutzeranzahl zurücksetzen',

				'Mobile not supported':
					'Dies wird derzeit nicht von iOS unterstützt',
				'Update Successful': 'Update erfolgreich',

				'Resource Columns': 'Ressourcen-Spalten',

				Refresh: 'aktualisieren',
				'Show Weekends': 'Zeige Wochenenden',
				Days: 'Tage',
				of: 'von',

				Fetching: 'Abrufen',

				'Extend In-App Updates': 'Erweitern Sie In-App-Updates',
				'Updates expired message':
					'Der Zugriff auf In-App-Updates ist vor {{days}} abgelaufen',
				'Free updates remaining message':
					'{{days}} von In-App-Updates verbleibenden',

				'Week of': 'der Woche',
				weeks: 'Wochen',
				week: 'Woche',

				months: 'Monate',
				month: 'Monat',

				years: 'Jahre',
				year: 'Jahr',

				'Show Distances': 'Entfernungen anzeigen',
				Horizon: 'Horizont',
				'Breakout By': 'Breakout Von',
				nothing: 'nichts',
				'Horizon Settings': 'Horizon-Einstellungen',

				'Breakout by Resource': 'Aufschlüsselung nach Ressourcen',
				'Breakout by Status': 'Breakout nach Status',
				'Repeating Events': 'Ereignisse wiederholen',
				'Hide all repetitions': 'Alle Wiederholungen ausblenden',
				'Hide daily': 'Täglich verstecken',
				'Hide weekly': 'Verstecke dich wöchentlich',
				'Hide monthly': 'Monatlich ausblenden',
				'Hide yearly': 'Jährlich ausblenden',

				Saved: 'Gesichert',
				Deleted: 'Gelöscht',
				Undo: 'Rückgängig machen',
				'No Change History Available':
					'Kein Änderungsverlauf verfügbar',
				'Event may be off screen':
					'Das Ereignis könnte außerhalb des Bildschirms sein',

				'Operation Failed': 'Operation fehlgeschlagen',
				'Revert Changes': 'Zufällt Änderungen',
				'Changes Reverted': 'Änderungen rückgängig gemacht',
				'Return To Event': 'Rückkehr Zum Ereignis',

				'Authentication Successful': 'Authentifizierung erfolgreich',
				'Please close to return to the calendar':
					'Bitte schließen Sie an den Kalender zurückzukehren',

				'See in-app updates available since then':
					'Siehe In-App-Updates zur Verfügung seitdem',

				Views: 'Ansichten',
				'Horizon View': 'Horizont Blick',
				'Resource View': 'Ressourcenansicht',
				'Month View': 'Monatsansicht',
				Fluid: 'Flüssigkeit',
				'Fit to screen': 'Vollbild',

				'Admin Settings': 'Admin-Einstellungen',
				'Administrator Settings': 'Administrator-Einstellungen',
				'My Settings': 'Meine Einstellungen',
				'Contact Us And Docs': 'Kontakt & Docs',
				'Sign-Out': 'Ausloggen',
				Feedback: 'Feedback',
				'Schedule a Demo': 'Planen Sie eine Demo',

				'events can only be moved to other calendars when they are first created':
					'{{source}} ereignisse können nur auf andere Kalender verschoben werden, wenn sie zum ersten Mal erstellt werden.',

				'Editing Scale': 'Editing-Skala',

				'defend your time': 'verteidigen Sie Ihre Zeit',
				'add to calendar': 'In Kalender',
				More: 'Mehr',

				'Invalid View': 'Ungültige anzeigen',
				'Invalid view message':
					'Eine ungültige Ansicht wurde verwendet, um den Kalender zu navigieren und wurde ignoriert.',
				'Invalid Date': 'Ungültiges Datum',
				'Invalid date message':
					'Ein ungültiges Datum wurde verwendet, um den Kalender zu navigieren und wurde ignoriert.',
				'Invalid Bookmark ID': 'Ungültige Lesezeichen-ID',
				'Invalid Bookmark ID message':
					'Lesezeichen-ID {{bookmarkID}} ist ungültig oder nicht Teil Ihrer DayBack-Gruppe',
				Error: 'Error',
				'Bookmark update filter error message':
					'Das Update kann nicht durchgeführt werden, da die Filterliste nicht mehr mit der mit dem Lesezeichen gespeicherten übereinstimmt. Bitte erstellen und teilen Sie stattdessen ein neues Lesezeichen.',
				'Bookmark visit filter error message':
					'Die Filterliste stimmt nicht mehr mit der mit dem Lesezeichen gespeicherten überein, sodass dieses Lesezeichen möglicherweise veraltet ist.',

				Continue: 'Fortsetzen',
				'Learn More': 'Erfahren Sie mehr',

				Share: 'Freigaben',
				'Bookmark or Share This View':
					'Lesezeichen setzen oder diese Ansicht teilen',
				'Manage Bookmarks/Shares': 'Lesezeichen/Freigaben verwalten',
				'Recent Bookmarks': 'Letzte Lesezeichen',
				'Show All': 'Zeige alles',
				'Share This View': 'Diese Ansicht Freigaben',
				'Manage Shares': 'Freigaben verwalten',
				'DayBack Mission': 'Die Mission von DayBack',
				Blog: 'Blog',
				'Get Your Own DayBack': 'Erhalten Sie Ihr eigenes DayBack',
				'Contact DayBack': 'Kontakt DayBack',
				'About DayBack': 'Über DayBack',

				'Enable Sharing': 'Freigabe freigeben',
				Off: 'Aus',
				Administrator: 'Administrator',
				Administrators: 'Administratoren',
				Everyone: 'Jeder',

				'Enabled for Administrators': 'Aktiviert für Administratoren',
				'Sharing Disabled': 'Freigeben deaktiviert',

				'DayBack Share Not Found': 'DayBack Share Nicht gefunden',
				'The share you have requested has expired or does not exist.':
					'Der von Ihnen angeforderte Anteil ist abgelaufen oder existiert nicht.',
				'Why did this happen?': 'Warum ist das passiert?',

				'Want more? Get your own DayBack.':
					'Mehr wollen? Holen Sie sich Ihren eigenen DayBack...',
				'Try DayBack for Free': 'Probieren Sie DayBack Free',

				Update: 'Aktualisieren',
				Edit: 'Bearbeiten',
				Visit: 'Besuch',
				'Bookmark/Share Detail': 'Lesezeichen/Freigabedetails',
				'Created by': 'Erstellt von',
				'Created By': 'Erstellt von',
				'Expires In': 'Läuft ab in',
				Expires: 'Läuft ab',
				Expired: 'Abgelaufen',
				New: 'Neue',
				Create: 'Erstellen',
				'Show In Timezone': 'In Zeitzone anzeigen',
				'Extend View': 'Ansicht erweitern',
				'Create Share': 'Anteil Erstellen',
				'Shared Dates': 'Gemeinsame Daten',
				'Share with': 'Teilen mit',
				'Shared with': 'Geteilt mit',
				'Just Me': 'Nur ich',
				'My DayBack Group': 'Meine DayBack-Gruppe',
				Public: 'Öffentlichkeit',
				'Public share options': 'Öffentliche Aktienoptionen',
				Bookmark: 'Lesezeichen',
				Created: 'Erstellt',
				URL: 'URL',
				'Embed Code': 'Einbettungscode',
				'Update Share': 'Aktualisieren',
				'Update Bookmark': 'Lesezeichen aktualisieren',
				'Copy URL': 'URL kopieren',
				'Copy Embed Code': 'Einbettungscode kopieren',
				'Copied to clipboard': 'In die Zwischenablage kopiert',
				'Please copy the selected text':
					'Bitte kopiere den markierten Text',
				'Creating share': 'Anteil anlegen',
				'Updating share': 'Aktien freigeben',
				'Bookmark created successfully':
					'Lesezeichen wurde erfolgreich erstellt',
				'Share updated successfully':
					'Freigaben erfolgreich aktualisiert',
				'Updating takes you to the view you have shared':
					'Die Aktualisierung führt Sie zu der Ansicht, die Sie freigegeben haben, und re-synchronisiert passende Elemente.',
				'Remember changes made are automatically synced':
					'Denken Sie daran, dass alle Änderungen, die in DayBack vorgenommen wurden, automatisch mit Ihren Aktien synchronisiert werden.',
				'Updating this bookmark will reset the filters':
					'Wenn Sie dieses Lesezeichen aktualisieren, werden die Filter, die Ansicht und der Datumsbereich (falls zutreffend) auf das zurückgesetzt, was Sie gerade sehen.',

				'Authorization Failed': 'Autorisation fehlgeschlagen',
				'Connecting To DayBack': 'Anschließen an DayBack',
				'Get Started': 'Freigabe erteilen',
				'Sharing not Activated': 'Freigeben nicht aktiviert',
				'Sharing Enabled': 'Freigeben aktiviert',
				'There are no shares yet': 'Es gibt noch keine Aktien',

				Subscribe: 'Abonnieren',
				'Sharing Trial Expired': 'Freigabe der Studie abgelaufen',
				'Sharing Subscription Expired':
					'Freigabe des Abonnements abgelaufen',

				'Custom Fields': 'Benutzerdefinierte Felder',
				'Select Date': 'Datum auswählen',

				'Add Field': 'Feld hinzufügen',
				'Add Button': 'Schaltfläche hinzufügen',

				'View In Source': 'Ansicht in {{source}}',

				'Basecamp HTTPS Required':
					'Basecamp benötigt eine sichere Verbindung. Möchten Sie DayBack mit HTTPS neu laden?',
				'HTTPS Connection Required': 'HTTPS Verbindung erforderlich',
				'Reload as HTTPS': 'Als HTTPS neu laden',

				Total: 'Gesamt',
				'Chart Data': 'Diagrammdaten',
				Format: 'Format',
				Threshold: 'Schwelle',
				'Chart the value of a specific field:':
					'Chart den Wert eines bestimmten Feldes:',
				'Chart the number of items': 'Chart die Anzahl der Gegenstände',
				'Chart the duration of items':
					'Chart die Dauer der Gegenstände',
				'Show a threshold value on the chart':
					'Zeigen Sie einen Schwellenwert im Diagramm an',
				Value: 'Wert',
				'Line Color': 'Linienfarbe',
				'Fill Color': 'Füllfarbe',
				'Fill Opacity': 'Füllen Sie die Deckkraft',
				'color formats?': 'Farbformate?',
				'Color formats instructions':
					'Verwenden Sie eine beliebige CSS-Farbe, einschließlich RGB-, RGBA- oder Hex-Werten',

				'Format as': 'Formatieren als',
				'Label Before Value': 'Etikett vor Wert',
				'Label After Value': 'Label nach Wert',
				'Decimal Symbol': 'Dezimalzeichen',
				'Decimal Places': 'Nachkommastellen',
				'Thousands Separator': 'Tausendertrennzeichen',

				event: 'Veranstaltung',
				events: 'Veranstaltungen',
				item: 'Artikel',
				items: 'Produkte',
				'Over by': 'Vorbei vorbei',
				'Under by': 'Unter',
				'Chart by day, week': 'Diagramm für Tag, Woche',
				Auto: 'Auto',

				'Add Calendar': 'Kalender hinzufügen',
				'Calendar is read-only': 'Kalender ist schreibgeschützt',

				Folder: 'Mappe',
				'Folder Name': 'Ordnernamen',

				'Only applies to horizon view at day scale':
					'Gilt nur für die Horizontansicht im Tagesmaßstab',

				'Switch Accounts': 'Konten wechseln',
				Deauthorize: 'Deauthorize',
				Authorize: 'Autorisieren',
				'Maybe Later': 'Vielleicht später',

				'Grant Access To Your Google Account':
					'Gewähren Sie Zugriff auf Ihr {{type}}-Konto',
				'To view your Google Calendars you will want to allow DayBack to access your Google account':
					'Um Ihre {{type}} Kalender anzuzeigen, möchten Sie DayBack den Zugriff auf Ihr {{type}}-Konto gewähren.',
				'Welcome To DayBack': 'Willkommen bei DayBack',

				'Waiting for events': 'Warten auf Ereignisse',
				'Cancel Request': 'Anfrage abbrechen',

				Theme: 'Thema',

				'Moving sample data':
					'Verschieben von Beispieldaten an Ort und Stelle. Dies kann einen Moment dauern.',

				'Switch to this date with the bookmark':
					'Wechseln Sie mit dem Lesezeichen zu diesem Datum',
				'Changing switch date':
					'Durch Ändern dieser Einstellung für ein öffentliches Lesezeichen wird der aktuelle Datumsbereich für die Ansicht geändert und die freigegebenen Ereignisse so aktualisiert, dass sie mit den in der Ansicht angezeigten übereinstimmen',

				'There was a problem signing in':
					'Es gab ein Problem bei der Anmeldung bei {{account}}',
				'Your current DayBack license does not support this feature.':
					'Ihre aktuelle DayBack-Lizenz unterstützt diese Funktion nicht.',
				'Your subscription does not have enough user licenses':
					'Ihr Abonnement verfügt nicht über genügend Benutzerlizenzen.',
				'There was an issue retrieving data from the specified group token':
					'Beim Abrufen von Daten vom angegebenen Gruppentoken ist ein Problem aufgetreten.',
				'Please contact your FileMaker developer':
					'Bitte wenden Sie sich an Ihren FileMaker-Entwickler.',

				Account: 'Konto',
				Accounts: 'Konten',
				and: 'und',
				"Calendar Can't be found":
					'Kalender kann nicht gefunden werden',
				'Bookmark calendar not found message':
					'Der {{calendarSchedules}} kann nicht gefunden werden. Bitte autorisieren Sie die korrekter {{calendarSources}} zu {{action}} dieses Lesezeichen.',

				'First linked event': 'Erstes verknüpftes Ereignis',
				'Previous linked event': 'Vorheriges verknüpftes Ereignis',
				'Step through linked events':
					'Schritt durch verknüpfte Ereignisse',
				'End of linked events': 'Ende verknüpfter Ereignisse',
				'Next linked event': 'Nächstes verknüpftes Ereignis',
				'Last linked event': 'Letztes verknüpftes Ereignis',
				Pause: 'Pause',

				Attachments: 'Anhänge',
				'The original event has attachments':
					'Das ursprüngliche Ereignis hat Anhänge. Diese Anhänge werden aus dem duplizierten Ereignis entfernt.',

				'Filter resources': 'Ressourcen filtern',
				'No resources match your criteria':
					'Keine Ressourcen entsprechen Ihren Kriterien',

				Filter: 'Filter',
				Class: 'Class',
				Tags: 'Stichworte',
				Capacity: 'Kapazität',
				'comma separated': 'getrennt',
				'No additional details': 'Keine weiteren Angaben',
				'This item was created dynamically and should be modified in its original source':
					'Dieser Artikel wurde dynamisch erstellt und sollte in seiner ursprünglichen Quelle geändert werden',

				'Over under threshold': 'Über / unter Schwelle',
				over: 'über',
				'Includes partial week': 'Beinhaltet Teilwoche',
				'Includes partial month': 'Beinhaltet Teilmonate',
				'Public bookmarks expire essentials plan':
					'Öffentliche Lesezeichen laufen im Essentials-Plan nach 72 Stunden ab. Für öffentliche Kalender, die länger als 3 Tage bestehen, führen Sie bitte ein Upgrade auf den Plus-Plan durch, bei dem Sie Ihr eigenes Ablaufdatum festlegen können.',
				'Learn more about DayBack Plans and Pricing':
					'Erfahren Sie mehr über DayBack-Pläne und Preise',
				'No events found': 'Keine Veranstaltungen gefunden',

				'Service will end days unless payment is received':
					'Der Service endet in {{days}} Tagen, sofern keine Zahlung eingegangen ist',
				'Service will end day unless payment is received':
					'Der Service endet in {{days}} Tag, sofern keine Zahlung eingegangen ist',

				Drop: 'Ablegen',
				'Count Unscheduled Items': '{{count}} Nicht geplante Artikel',
				'Count Unscheduled Item': '{{count}} ungeplanter Artikel',
				'No Unscheduled Items': 'Keine ungeplanten Artikel',
				'Unscheduled is not available for this calendar':
					'„Unplanmäßig“ ist für diesen Kalender nicht verfügbar',

				'Show as resource for me': 'Als Ressource für mich anzeigen',

				Assigned: 'Zugewiesen',

				'Public shares will be deleted 30 days after expiration':
					'Öffentliche Freigaben werden 30 Tage nach Ablauf gelöscht',
				'Will be deleted 30 days after expiration':
					'Wird 30 Tage nach Ablauf gelöscht',
			},
		};

		//Italian language and substitutions
		defaultTranslations['it'] = {
			id: 'it',
			name: 'italiano',
			translations: {
				//These are name value pairs. Customize the values only (the item after the colon)
				Home: 'Home',
				Calendars: 'Calendari',
				Filters: 'Filtri',
				Settings: 'Impostazioni',

				'Hide Sidebar': 'Nascondi Sidebar',
				'Show Sidebar': 'Mostra Sidebar',

				//repeating event items
				Repeat: 'Ripetere',
				January: 'gennaio',
				February: 'febbraio',
				March: 'marzo',
				April: 'aprile',
				May: 'maggio',
				June: 'giugno',
				July: 'luglio',
				August: 'agosto',
				September: 'settembre',
				October: 'ottobre',
				November: 'novembre',
				December: 'dicembre',
				Monday: 'Lunedi',
				Tuesday: 'Martedì',
				Wednesday: 'Mercoledì',
				Thursday: 'Giovedi',
				Friday: 'Venerdì',
				Saturday: 'Sabato',
				Sunday: 'Domenica',
				Daily: 'Quotidiano',
				Weekly: 'Settimanalmente',
				Monthly: 'Mensile',
				Yearly: 'Annuale',
				'1st': 'Primo',
				'2nd': 'Secondo',
				'3rd': 'Terzo',
				'4th': 'Il-quarto',
				Last: 'Ultimo',
				'repeat every': 'ripetere ogni cosa',
				'day on': 'giorno dopo',
				'days on': 'giorni',
				'week on': 'settimana su',
				'weeks on': 'settimane',
				'month on': 'mese dopo',
				'months on': 'mesi',
				'year on': 'anno dopo',
				'years on': 'anni',
				'stop after': 'fermarsi dopo',
				never: 'mai',
				'on the': 'sul',
				'Your changes will only apply to this event, not to its other repetitions. Would you still like to save this change?':
					'Le modifiche verranno applicate solo a questo evento, non alle altre ripetizioni. Vuoi ancora salvare questo cambiamento?',
				'Edit only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Modifica solo questo elemento, questo e tutte le ripetizioni future o tutti i ripetizioni? Questo non può essere annullato.',
				'Delete only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Eliminare solo questo elemento, questo e tutte le ripetizioni future o tutti i ripetizioni? Questo non può essere annullato.',
				'Edit only this item or this and all future repetitions? This cannot be undone.':
					'Modifica solo questo elemento o questo e tutte le ripetizioni future? Questo non può essere annullato.',
				'Edit Repeating Item': "Modifica dell'articolo ripetuto",
				'Delete Repeating Item': "Eliminare l'elemento ripetuto",
				'Only This Item': 'Solo questo articolo',
				'All Future Repetitions': 'Tutte le ripetizioni future',
				'All Repetitions': 'Tutte le ripetizioni',
				'Would you like to delete all future repetitions and create new ones? This cannot be undone':
					'Vuoi cancellare tutte le future ripetizioni e creare nuove? Questo non può essere annullato',
				'Repeating Event': "Ripetizione dell'evento",
				'Delete Only': 'eliminare solo',
				'Delete and New': 'eliminare e nuovo',
				'Future Repetitions Deleted': 'Ripetizioni future cancellate',
				'must be on or before': 'deve essere sopra o prima',

				Day: 'Giorno',
				Schedule: 'Schedule',
				List: 'Elenco',
				Week: 'Settimana',
				Month: 'Mese',
				Resource: 'Risorsa',
				Grid: 'griglia',
				'Pivot Schedule': 'Schedule Pivot',
				'Pivot List': 'Lista Pivot',

				'Snap To Month': 'Snap al mese',

				Today: 'Oggi',
				Resources: 'Risorse',
				resources: 'risorse',

				Calendar: 'calendario',
				All_Day: 'All Day',
				Start: 'Inizio',
				End: 'Fine',
				Status: 'Stato',
				Location: 'luogo',
				Contact: 'Contatto',
				Project: 'Progetto',
				Done: 'Fatto',

				Delete: 'Cancellare',
				Close: 'Chiudi',
				'Save And Close': 'Salva e chiudi',
				'Save And Back': 'Salvare e Indietro',
				Back: 'Indietro',

				'Show on Map': 'Mostra sulla mappa',
				'Open URL': 'Apri URL',
				'Open Map': 'Apri Mappa',
				'Location not found': 'Posizione non trovata',
				'Enter an address or location name':
					'Immettere il nome di indirizzo o la posizione',

				Search: 'Ricerca',
				none: 'nessuno',

				Description: 'Descrizione',
				'Enter Title': 'Inserire Titolo',
				'resource inherited from calendar':
					'risorsa ereditata dal calendario',

				'Clear Selection': 'Annulla selezione',

				New_Event_Title: 'Nuovo Evento',
				'New Event': 'Nuovo Evento',
				'View Day': 'Guarda il giorno',
				'You have no editable calendars selected':
					'Non ci sono i calendari modificabili selezionato',
				'You may not edit shared views':
					'Tu non puoi modificare le viste condivise',
				'You have no calendar sources configured':
					'Non ci sono fonti di calendario configurato',
				'You have no calendars selected that allow times':
					'Non ci sono calendari selezionati che consentono volte',
				'Could not create a new event because all breakout items are hidden':
					'Impossibile creare un nuovo evento perché tutti gli articoli di breakout sono nascosti',
				"'All Day Event' is currently disabled for Events":
					'"evento tutto l\'anno" è disattivato per Eventi',

				'Time Settings': 'Impostazioni ora',
				'Default Time': 'Tempo predefinito',
				'Earliest Time': 'Apertura Tempo',
				'Latest Time': 'Ultime Tempo',
				'Time Scale': 'Scala',
				'View Settings': 'Impostazioni di visualizzazione',
				'Compressed View': 'Compressed View',
				'Fluid Months': 'Mesi Fluid',

				Yes: 'Sì',
				No: 'No',

				'Advanced Filters': 'Filtri avanzati',
				'Clear Filters': 'Cancella filtri',

				Week_Number_Header: 'Wk',
				more: 'di più',

				hour: 'ora',
				hours: 'orario',
				minute: 'minuto',
				minutes: 'verbale',

				Text: 'Testo',
				'Status Filters': 'Filtri di stato',
				'Resource Filters': 'Filtri di risorse',
				'Project Filters': 'Filtri di progetto',
				'Contact Filters': 'Filtri di contatto',

				'New Status': 'Nuovo stato',
				'New Resource': 'Nuova risorsa',
				'New Folder': 'Nuova cartella',

				Sources: 'Fonti',
				Save: 'Salva',
				Cancel: 'annullare',
				cancel: 'annullare',

				'Show as resource': 'Visualizza come risorsa',
				'Calendar for new entries': 'calendario principale',
				'for me': 'per me',
				'for everyone': 'per tutti',
				'disabled by your administrator':
					"disattivato dall'amministratore",

				'Call your own scripts': 'Chiama i propri script',

				'Status Filter': 'Filtro di stato',

				Name: 'nome',
				Color: 'Colore',

				day: 'giorno',
				days: 'giorni',

				'Account Settings': 'Impostazioni di registrazione',
				'Trial Mode': 'Modalità Trial',
				'Contact Us': 'Contattaci',
				Help: 'Aiuto',
				Documentation: 'Documentazione',
				'What Is New': "Cosa c'è di nuovo",
				'Sign Out': 'Disconnessione',

				'Check For Updates': 'Controlla aggiornamenti',
				'Install Update': "Installare l'aggiornamento",
				'what is new': "Cosa c'è di nuovo",

				'Load Calendar': 'Inizia Calendario',

				'Purchase DayBack': 'Acquisto DayBack',
				'Switch To Subscription': 'Passa a Abbonamento',
				'Manage Subscription': 'Gestisci Abbonamento',
				'Enter New License': 'Inserisci nuova licenza',
				Activate: 'Attivare',

				'Trial activated':
					'È stata attivata la versione di prova {{days}}',
				'Trial expired': 'Il periodo di prova è scaduto',
				'Trial expired message':
					'Di prova è scaduto. Si prega di acquistare una licenza per continuare.',
				'Trial remains': '{{days}} lasciati in versione di prova',
				'Trial expires in days':
					'Il periodo di prova scade tra {{days}}.',

				'Subscription expired': 'Abbonamento scaduto {{days}} ago',
				'Subscription expired message':
					'Abbonamento scaduto. Si prega di rinnovo per continuare.',
				'Cease to function message':
					"Se non viene immessa una nuova licenza l'interfaccia calendario cesserà di funzionare in {{days}}",

				'Subscription renews': 'Abbonamento rinnova in {{days}}',
				'Subscription renews today': "L'abbonamento si rinnova oggi",
				'Subscription should have renewed':
					"L'abbonamento dovrebbe essere rinnovato {{days}} fa",

				'Subscription overdue message admin':
					"Il pagamento dell'abbonamento è scaduto. Gestisci il tuo abbonamento e aggiorna le informazioni di pagamento.",
				'Subscription overdue message non admin':
					"Il pagamento dell'abbonamento è scaduto. Gestisci il tuo abbonamento e aggiorna le informazioni di pagamento.",

				Purchased: 'Acquistato',

				Product: 'prodotto',
				'Order Number': "Numero d'ordine",
				Email: 'Email',
				'Registered To': 'Registrato Per',

				'Current version': 'Si sta eseguendo la versione {{version}}',
				'Version available': 'Versione {{version}} disponibile',
				'Running latest version': 'Si esegue la versione più recente',
				'Cannot connect':
					'Impossibile connettersi al server di aggiornamento',
				'Problem downloading update':
					"Si è verificato un problema durante il download l'aggiornamento",

				'Activation denied':
					'La richiesta di attivazione è stata negata. Controllare le informazioni inserite e riprova',
				'Activation successful': 'Attivazione di successo',

				TooManyUsers1: 'Troppi utenti',
				TooManyUsers2: 'Troppi utenti hanno aperto il calendario.',
				TooManyUsers3:
					'È possibile acquistare un aggiornamento per consentire a più utenti, o ripristinare temporaneamente gli utenti attualmente censiti in modo da poter continuare a lavorare oggi.',
				'Purchase Upgrade': 'Acquista aggiornamento',
				'Reset User Count': 'Ripristino conteggio utente',

				'Mobile not supported':
					'Questo non è attualmente supportato su iOS',
				'Update Successful': 'Aggiornamento di successo',

				'Resource Columns': 'Colonne risorse',

				Refresh: 'rinfrescare',
				'Show Weekends': 'Mostra Fine settimana',
				Days: 'Giorni',
				of: 'di',

				Fetching: 'recupero',

				'Extend In-App Updates': 'Estendi gli aggiornamenti in-app',
				'Updates expired message':
					'Accesso agli aggiornamenti in-app scaduti {{days}} fa',
				'Free updates remaining message':
					'{{days}} di in-app aggiornamenti rimanenti',

				'Week of': 'settimana di',
				weeks: 'settimane',
				week: 'settimana',

				months: 'mesi',
				month: 'mese',

				years: 'anni',
				year: 'anno',

				'Show Distances': 'Mostra distanze',
				Horizon: 'Orizzonte',
				'Breakout By': 'Breakout Di',
				nothing: 'Niente',
				'Horizon Settings': 'Impostazioni Horizon',

				'Breakout by Resource': 'Breakout per risorsa',
				'Breakout by Status': 'Breakout per stato',
				'Repeating Events': 'Eventi ricorrenti',
				'Hide all repetitions': 'Nascondi tutte le ripetizioni',
				'Hide daily': 'Nascondi ogni giorno',
				'Hide weekly': 'Nascondi settimanalmente',
				'Hide monthly': 'Nascondi mensilmente',
				'Hide yearly': 'Nascondi annualmente',

				Saved: 'Salvato',
				Deleted: 'eliminata',
				Undo: 'Disfare',
				'No Change History Available':
					'Nessuna Cronologia delle Modifiche Disponibile',
				'Event may be off screen':
					"L'evento potrebbe essere fuori schermo",

				'Operation Failed': 'Operazione fallita',
				'Revert Changes': 'Annulla modifiche',
				'Changes Reverted': 'Modifiche Annullate',
				'Return To Event': 'Tornare alla manifestazione',

				'Authentication Successful': 'autenticazione di successo',
				'Please close to return to the calendar':
					'Si prega di chiudere per tornare al calendario',

				'See in-app updates available since then':
					'Vedere gli aggiornamenti in-app disponibili da allora',

				Views: 'Visualizzazioni',
				'Horizon View': "Vista l'orizzonte",
				'Resource View': 'Visualizzazione risorse',
				'Month View': 'Vista mese',
				Fluid: 'Fluido',
				'Fit to screen': 'Adatta allo schermo',

				'Admin Settings': 'impostazioni di amministrazione',
				'Administrator Settings': 'Impostazioni amministratore',
				'My Settings': 'Le mie impostazioni',
				'Contact Us And Docs': 'Contattaci & Docs',
				'Sign-Out': 'Disconnessione',
				Feedback: 'Risposta',
				'Schedule a Demo': 'Pianifica una demo',

				'events can only be moved to other calendars when they are first created':
					'{{source}} gli eventi possono essere spostati solo ad altri calendari quando vengono creati prima.',

				'Editing Scale': 'Modifica Scala',

				'defend your time': 'difendere il tuo tempo',
				'add to calendar': 'aggiungi al calendario',
				More: 'Di Più',

				'Invalid View': 'View non valido',
				'Invalid view message':
					'Un punto di vista valido è stato utilizzato per navigare il calendario ed è stato ignorato.',
				'Invalid Date': 'Data non valida',
				'Invalid date message':
					'Una data non valida è stata utilizzata per navigare il calendario ed è stato ignorato.',
				'Invalid Bookmark ID': 'ID segnalibro non valido',
				'Invalid Bookmark ID message':
					'ID segnalibro {{bookmarkID}} non è valido o non fa parte del tuo gruppo DayBack',
				Error: 'Errore',
				'Bookmark update filter error message':
					"L'aggiornamento non può essere eseguito perché l'elenco dei filtri non corrisponde più a quello salvato con il segnalibro. Crea e condividi invece un nuovo segnalibro.",
				'Bookmark visit filter error message':
					"L'elenco dei filtri non corrisponde più a quello salvato con il segnalibro, quindi questo segnalibro potrebbe non essere aggiornato.",

				Continue: 'proseguire',
				'Learn More': 'Per saperne di più',

				Share: 'Condividere',
				'Bookmark or Share This View':
					'Aggiungi ai Preferiti o Condividi questa vista',
				'Manage Bookmarks/Shares': 'Gestisci segnalibri/azioni',
				'Recent Bookmarks': 'Segnalibri recenti',
				'Show All': 'Mostra tutto',
				'DayBack Mission': 'La missione di DayBack',
				Blog: 'blog',
				'Get Your Own DayBack': 'Prendi la tua DayBack',
				'Contact DayBack': 'Contatto DayBack',
				'About DayBack': 'A proposito di DayBack',

				'Enable Sharing': 'Abilita Condivisione',
				Off: 'via',
				Administrator: 'Amministratore',
				Administrators: 'Amministratori',
				Everyone: 'Tutti',

				'Enabled for Administrators': 'Abilitato per amministratori',
				'Sharing Disabled': 'Condivisione disabili',

				'DayBack Share Not Found': 'DayBack Condividi non trovato',
				'The share you have requested has expired or does not exist.':
					'La quota hai richiesto è scaduto o non esiste.',
				'Why did this happen?': 'Perchè è successo questo?',

				'Want more? Get your own DayBack.':
					'Voglio di più? Prendi il tuo DayBack...',
				'Try DayBack for Free': 'Prova DayBack gratis',

				Update: 'Aggiornare',
				Edit: 'modificare',
				Visit: 'Visitare',
				'Bookmark/Share Detail': 'Segnalibro/Condividi Dettagli',
				'Created by': 'Creato da',
				'Created By': 'Creato da',
				'Expires In': 'Scade tra',
				Expires: 'Scade',
				Expired: 'Scaduto',
				New: 'Nuovo',
				Create: 'Creare',
				'Show In Timezone': 'Mostra nel fuso orario',
				'Extend View': 'Estendi vista',
				'Create Share': 'Crea Condividi',
				'Shared Dates': 'Date condivisi',
				'Share with': 'Condividi con',
				'Shared with': 'Condiviso con',
				'Just Me': 'Solo io',
				'My DayBack Group': 'Il mio gruppo DayBack',
				Public: 'Pubblico',
				'Public share options': 'Opzioni di condivisione pubbliche',
				Bookmark: 'Segnalibro',
				Created: 'Creato',
				URL: 'URL',
				'Embed Code': 'Codice Incluso',
				'Update Share': 'Aggiorna segnalibro',
				'Copy URL': 'Copia URL',
				'Copy Embed Code': 'Copiare il codice incluso',
				'Copied to clipboard': 'Copiato negli appunti',
				'Please copy the selected text':
					'Si prega di copiare il testo selezionato',
				'Creating share': 'Creazione di condivisione',
				'Updating share': 'Aggiorna la condivisione',
				'Bookmark created successfully':
					'La segnalibro è stata creata correttamente',
				'Share updated successfully':
					'Condividi aggiornato con successo',
				'Updating takes you to the view you have shared':
					"L'aggiornamento porta alla visualizzazione hai condiviso e re-sincronizza gli elementi corrispondenti.",
				'Remember changes made are automatically synced':
					'Ricordate, tutte le modifiche apportate in DayBack vengono sincronizzati automaticamente alle vostre azioni.',
				'Updating this bookmark will reset the filters':
					"L'aggiornamento di questo segnalibro reimposterà i filtri, la vista e l'intervallo di date (se applicabile) a ciò che stai vedendo in questo momento.",

				'Authorization Failed': 'Autorizzazione fallita',
				'Connecting To DayBack': 'Collegamento a DayBack',
				'Get Started': 'Iniziare',
				'Sharing not Activated': 'La condivisione non Attivato',
				'Sharing Enabled': 'Condivisione Abilitato',
				'There are no shares yet': 'Non ci sono ancora azioni',

				Subscribe: 'sottoscrivi',
				'Sharing Trial Expired': 'La condivisione di prova scaduto',
				'Sharing Subscription Expired':
					'Abbonamento Condivisione scaduto',

				'Custom Fields': 'Campi personalizzati',
				'Select Date': 'Seleziona Data',

				'Add Field': 'Aggiungi campo',
				'Add Button': 'Aggiungi pulsante',

				'View In Source': 'Visualizza in {{source}}',

				'Basecamp HTTPS Required':
					'Basecamp richiede una connessione sicura. Vuoi ricaricare DayBack utilizzando HTTPS?',
				'HTTPS Connection Required': 'Connessione HTTPS necessaria',
				'Reload as HTTPS': 'Ricarica come HTTPS',

				Total: 'Totale',
				'Chart Data': 'Dati del grafico',
				Format: 'Formato',
				Threshold: 'Soglia',
				'Chart the value of a specific field:':
					'Grafico il valore di un campo specifico:',
				'Chart the number of items': 'Tracciare il numero di elementi',
				'Chart the duration of items':
					'Traccia la durata degli articoli',
				'Show a threshold value on the chart':
					'Mostra un valore soglia sul grafico',
				Value: 'Valore',
				'Line Color': 'Colore della linea',
				'Fill Color': 'Colore di riempimento',
				'Fill Opacity': "Riempi l'opacità",
				'color formats?': 'formati di colore?',
				'Color formats instructions':
					'Utilizzare qualsiasi colore CSS, inclusi i valori RGB, RGBA o Hex',

				'Format as': 'Formato come',
				'Label Before Value': 'Etichetta prima',
				'Label After Value': 'Etichetta dopo',
				'Decimal Symbol': 'Simbolo decimale',
				'Decimal Places': 'Decimali',
				'Thousands Separator': 'Separatore di migliaia',

				event: 'evento',
				events: 'eventi',
				item: 'elemento',
				items: 'Oggetti',
				'Over by': 'Oltre',
				'Under by': 'Sotto di',
				'Chart by day, week': 'Grafico per giorno, settimana',
				Auto: 'Auto',

				'Add Calendar': 'Aggiungi calendario',
				'Calendar is read-only': 'Il calendario è di sola lettura',

				Folder: 'Cartella',
				'Folder Name': 'Nome della cartella',

				'Only applies to horizon view at day scale':
					"Si applica solo alla vista dell'orizzonte su scala giornaliera",

				'Switch Accounts': 'Cambia account',
				Deauthorize: 'autorizzazione',
				Authorize: 'Autorizzare',
				'Maybe Later': 'Forse più tardi',

				'Grant Access To Your Google Account':
					"Concedi l'accesso al tuo account {{type}}",
				'To view your Google Calendars you will want to allow DayBack to access your Google account':
					'Per visualizzare i tuoi calendari {{type}}, dovrai consentire a DayBack di accedere al tuo account {{type}}.',
				'Welcome To DayBack': 'Benvenuti in DayBack',

				'Waiting for events': 'In attesa di eventi',
				'Cancel Request': 'Richiesta cancellata',

				Theme: 'Tema',

				'Moving sample data':
					'Spostamento dei dati di esempio in posizione. Questo potrebbe richiedere un momento.',

				'Switch to this date with the bookmark':
					'Passa a questa data con il segnalibro',
				'Changing switch date':
					"La modifica di questa impostazione per un segnalibro pubblico cambierà l'intervallo di date corrente per la vista e aggiornerà gli eventi condivisi in modo che corrispondano a quanto mostrato nella vista",

				'There was a problem signing in':
					"Si è verificato un problema durante l'accesso a {{account}}",
				'Your current DayBack license does not support this feature.':
					'La tua attuale licenza DayBack non supporta questa funzione.',
				'Your subscription does not have enough user licenses':
					'Il tuo abbonamento non ha abbastanza licenze utente.',
				'There was an issue retrieving data from the specified group token':
					'Si è verificato un problema durante il recupero dei dati dal token di gruppo specificato.',
				'Please contact your FileMaker developer':
					'Si prega di contattare lo sviluppatore FileMaker.',
				Account: 'Conto',
				Accounts: 'Conti',
				and: 'e',
				"Calendar Can't be found": 'Calendario non può essere trovato',
				'Bookmark calendar not found message':
					'Il {{calendarSchedules}} non può essere trovato. Si prega di autorizzare il {{calendarSources}} corretto di {{action}} questo segnalibro.',

				'First linked event': 'Primo evento collegato',
				'Previous linked event': 'Precedente evento collegato',
				'Step through linked events': 'Scorri gli eventi collegati',
				'End of linked events': 'Fine degli eventi collegati',
				'Next linked event': 'Prossimo evento collegato',
				'Last linked event': 'Ultimo evento collegato',
				Pause: 'Pausa',

				Attachments: 'Allegati',
				'The original event has attachments':
					"L'evento originale ha allegati. Tali allegati verranno rimossi dall'evento duplicato.",

				'Filter resources': 'Filtra risorse',
				'No resources match your criteria':
					'Nessuna risorsa corrisponde ai tuoi criteri',

				Filter: 'Filtro',
				Class: 'Class',
				Tags: 'tag',
				Capacity: 'Capacità',
				'comma separated': 'separato da virgola',
				'No additional details': 'Nessun dettaglio aggiuntivo',
				'This item was created dynamically and should be modified in its original source':
					'Questo elemento è stato creato dinamicamente e dovrebbe essere modificato nella sua fonte originale',

				'Over under threshold': 'Sopra/sotto soglia',
				over: 'oltre',
				'Includes partial week': 'Include settimana parziale',
				'Includes partial month': 'Include mese parziale',
				'Public bookmarks expire essentials plan':
					"I segnalibri pubblici scadono dopo 72 ore nel piano Essentials. Per i calendari pubblici che persistono per più di 3 giorni, esegui l'upgrade al piano Plus dove puoi impostare la tua data di scadenza.",
				'Learn more about DayBack Plans and Pricing':
					'Ulteriori informazioni sui piani e sui prezzi di DayBack',
				'No events found': 'Nessun evento trovato',

				'Service will end days unless payment is received':
					'Der Service endet in {{days}} Tagen, sofern keine Zahlung eingegangen ist',
				'Service will end day unless payment is received':
					'Il servizio terminerà in {{days}} giorno a meno che non venga ricevuto il pagamento',

				Drop: 'Far',
				'Count Unscheduled Items': '{{count}} articoli non programmati',
				'Count Unscheduled Item': '{{count}} articolo non programmato',
				'No Unscheduled Items': 'Nessun articolo non programmato',
				'Unscheduled is not available for this calendar':
					'Non programmato non è disponibile per questo calendario',

				'Show as resource for me': 'Mostra come risorsa per me',

				Assigned: 'Assegnato',

				'Public shares will be deleted 30 days after expiration':
					'Le azioni pubbliche verranno eliminate 30 giorni dopo la scadenza',
				'Will be deleted 30 days after expiration':
					'Verrà eliminato 30 giorni dopo la scadenza',
			},
		};

		//Danish language and substitutions
		defaultTranslations['da'] = {
			id: 'da',
			name: 'dansk',
			translations: {
				//These are name value pairs. Customize the values only (the item after the colon)
				Home: 'Hjem',
				Calendars: 'Kalendere',
				Filters: 'Filtre',
				Settings: 'Indstillinger',

				'Hide Sidebar': 'Skjul sidebjælke',
				'Show Sidebar': 'Vis Sidebar',

				//repeating event items
				Repeat: 'Gentage',
				January: 'januar',
				February: 'februar',
				March: 'marts',
				April: 'april',
				May: 'maj',
				June: 'juni',
				July: 'juli',
				August: 'august',
				September: 'september',
				October: 'oktober',
				November: 'november',
				December: 'december',
				Monday: 'Mandag',
				Tuesday: 'Tirsdag',
				Wednesday: 'Onsdag',
				Thursday: 'Torsdag',
				Friday: 'Fredag',
				Saturday: 'Lørdag',
				Sunday: 'Søndag',
				Daily: 'Daglige',
				Weekly: 'Ugentlig',
				Monthly: 'Månedlige',
				Yearly: 'årlig',
				'1st': 'Først',
				'2nd': 'Anden',
				'3rd': 'Tredje',
				'4th': 'Fjerde',
				Last: 'Sidst',
				'repeat every': 'gentag hver',
				'day on': 'dag på',
				'days on': 'dage på',
				'week on': 'uge på',
				'weeks on': 'uger på',
				'month on': 'måned på',
				'months on': 'måneder på',
				'year on': 'år på',
				'years on': 'år på',
				'stop after': 'stop efter',
				never: 'aldrig',
				'on the': 'på den',
				'Your changes will only apply to this event, not to its other repetitions. Would you still like to save this change?':
					'Dine ændringer gælder kun for denne begivenhed, ikke til dens andre gentagelser. Vil du stadig gemme denne ændring?',
				'Edit only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Rediger kun denne genstand, dette og alle fremtidige gentagelser eller alle gentagelser? Dette kan ikke fortrydes.',
				'Delete only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Slet kun denne genstand, dette og alle fremtidige gentagelser eller alle gentagelser? Dette kan ikke fortrydes.',
				'Edit only this item or this and all future repetitions? This cannot be undone.':
					'Rediger kun denne genstand eller dette og alle fremtidige gentagelser? Dette kan ikke fortrydes.',
				'Edit Repeating Item': 'Rediger Gentag element',
				'Delete Repeating Item': 'Slet Gentag element',
				'Only This Item': 'Kun denne vare',
				'All Future Repetitions': 'Alle fremtidige gentagelser',
				'All Repetitions': 'Alle gentagelser',
				'Would you like to delete all future repetitions and create new ones? This cannot be undone':
					'Vil du slette alle fremtidige gentagelser og oprette nye? Dette kan ikke fortrydes',
				'Repeating Event': 'Gentagende begivenhed',
				'Delete Only': 'Slet kun',
				'Delete and New': 'Slet og nyt',
				'Future Repetitions Deleted': 'Fremtidige gentagelser slettet',
				'must be on or before': 'skal være på eller før',

				Day: 'Dag',
				Schedule: 'Tidsplan',
				List: 'Liste',
				Week: 'Uge',
				Month: 'Måned',
				Resource: 'Ressource',
				Grid: 'Grid',
				'Pivot Schedule': 'Pivot Tidsplan',
				'Pivot List': 'Pivot Liste',

				'Snap To Month': 'Snap til måned',

				Today: 'I dag',
				Resources: 'Ressourcer',
				resources: 'ressourcer',

				Calendar: 'kalender',
				All_Day: 'All Day',
				Start: 'Start',
				End: 'End',
				Status: 'Status',
				Location: 'Beliggenhed',
				Contact: 'Kontakt',
				Project: 'Projekt',
				Done: 'Færdig',

				Delete: 'Slet',
				Close: 'Luk',
				'Save And Close': 'Gem og luk',
				'Save And Back': 'Gem og Tilbage',
				Back: 'Tilbage',

				'Show on Map': 'Vis på kort',
				'Open URL': 'Åbn URL',
				'Open Map': 'åbne kort',
				'Location not found': 'Placering ikke fundet',
				'Enter an address or location name':
					'Indtast en adresse eller stednavn',

				Search: 'Søg',
				none: 'Ingen',

				Description: 'Beschrijving',
				'Enter Title': 'Voer Titel',
				'resource inherited from calendar':
					'ressource arvet fra kalender',

				'Clear Selection': 'Ryd markering',

				New_Event_Title: 'Nieuwe gebeurtenis',
				'New Event': 'Nieuwe gebeurtenis',
				'View Day': 'Dagweergave',
				'You have no editable calendars selected':
					'Du har ingen redigerbare kalendere valgt',
				'You may not edit shared views':
					'Du må ikke redigere delte visninger',
				'You have no calendar sources configured':
					'Du har ingen kalender kilder konfigureret',
				'You have no calendars selected that allow times':
					'Du har ingen valgte kalendere, der tillader gange',
				'Could not create a new event because all breakout items are hidden':
					'Kunne ikke oprette en ny begivenhed, fordi alle breakout elementer er skjult',
				"'All Day Event' is currently disabled for Events":
					'"hele dagen begivenhed" er i øjeblikket deaktiveret for arrangementer',

				'Time Settings': 'tidsindstillinger',
				'Default Time': 'standard Time',
				'Earliest Time': 'Vroegste tijd',
				'Latest Time': 'Nieuwste Tijd ',
				'Time Scale': 'Tidsplan',
				'View Settings': 'Vis indstillinger',
				'Compressed View': 'Compressed View',
				'Fluid Months': 'Fluid Maanden',

				Yes: 'Ja',
				No: 'Geen',

				'Advanced Filters': 'avancerede filtre',
				'Clear Filters': 'Ryd Filtrer',

				Week_Number_Header: 'Uge',
				more: 'mere',

				hour: 'time',
				hours: 'timer',
				minute: 'minut',
				minutes: 'minutter',

				Text: 'Tekst',
				'Status Filters': 'Status filtre',
				'Resource Filters': 'Resource filtre',
				'Project Filters': 'Projekt filtre',
				'Contact Filters': 'Kontakt filtre',

				'New Status': 'Ny status',
				'New Resource': 'Ny ressource',
				'New Folder': 'Ny mappe',

				Sources: 'kilder',
				Save: 'Gem',
				Cancel: 'Annuller',
				cancel: 'annuller',

				'Show as resource': 'Vis som ressource',
				'Calendar for new entries': 'Kalender for nye indgange',
				'for me': 'for mig',
				'for everyone': 'for alle',
				'disabled by your administrator':
					'deaktiveret af din administrator',

				'Call your own scripts': 'Ring til dine egne scripts',

				'Status Filter': 'Status Filter',

				Name: 'navn',
				Color: 'Kulør',

				day: 'dag',
				days: 'dage',

				'Account Settings': 'Registrering Indstillinger',
				'Trial Mode': 'Trial Mode',
				'Contact Us': 'Kontakt os',
				Help: 'Hjælp',
				Documentation: 'Dokumentation',
				'What Is New': 'Hvad er nyt',
				'Sign Out': 'Log ud',

				'Check For Updates': 'Kontroller for opdateringer',
				'Install Update': 'Installer Opdatering',
				'what is new': 'Hvad er nyt?',

				'Load Calendar': 'Start Kalender',

				'Purchase DayBack': 'Købe DayBack',
				'Switch To Subscription': 'Skift til Abonnement',
				'Manage Subscription': 'Administrer abonnement',
				'Enter New License': 'Indtast ny licens',
				Activate: 'Aktiver',

				'Trial activated':
					'Din {{days}} prøveversion er blevet aktiveret',
				'Trial expired': 'Din prøveversion er udløbet',
				'Trial expired message':
					'Trial udløbet. Venligst købe en licens for at fortsætte.',
				'Trial remains': '{{days}} tilbage i din prøveversion',
				'Trial expires in days': 'Din prøve udløber om {{days}}.',

				'Subscription expired': 'Abonnement udløbet {{days}} siden',
				'Subscription expired message':
					'Abonnement udløbet. Forny for at fortsætte.',
				'Cease to function message':
					'Hvis en ny licens ikke er indtastet kalenderen interfacet vil ophøre med at fungere i {{days}}',

				'Subscription renews': 'Abonnement fornyer i {{days}}',
				'Subscription renews today': 'Abonnementet fornys i dag',
				'Subscription should have renewed':
					'Abonnementet skulle have været fornyet for {{days}} siden',

				'Subscription overdue message admin':
					'Abonnementsbetaling er forfalden. Administrer dit abonnement og opdater betalingsoplysninger.',
				'Subscription overdue message non admin':
					'Abonnementsbetaling er forfalden. Administrer dit abonnement og opdater betalingsoplysninger.',

				Purchased: 'Købt',

				Product: 'Produkt',
				'Order Number': 'Ordrenummer',
				Email: 'Email',
				'Registered To': 'Registreret til',

				'Current version': 'Du kører version {{version}}',
				'Version available': 'Version {{version}} tilgængelig',
				'Running latest version': 'Du bruger den nyeste version',
				'Cannot connect': 'Kunne ikke forbinde til opdatere server',
				'Problem downloading update':
					'Der var et problem med at hente opdateringen',

				'Activation denied':
					'Anmodningen aktivering blev nægtet. Tjek venligst dine oplysninger og prøv igen',
				'Activation successful': 'Aktivering succesfulde',

				TooManyUsers1: 'Alt for mange brugere',
				TooManyUsers2: 'Alt for mange brugere har åbnet kalenderen.',
				TooManyUsers3:
					'Du kan købe en opgradering til at tillade flere brugere, eller midlertidigt nulstille tiden tælles brugere, så du kan fortsætte med at arbejde nu.',
				'Purchase Upgrade': 'Køb Upgrade',
				'Reset User Count': 'Reset Bruger Count',

				'Mobile not supported':
					'Det er i øjeblikket ikke understøttet på iOS',
				'Update Successful': 'Opdater vellykket',

				'Resource Columns': 'Ressource kolonner',

				Refresh: 'rinfrescare',
				'Show Weekends': 'Mostra Fine settimana',
				Days: 'Giorni',
				of: 'di',

				Fetching: 'Henter',

				'Extend In-App Updates': 'Udvid opdateringer i appen',
				'Updates expired message':
					'Adgang til opdateringer i appen udløb for {{days}} siden',
				'Free updates remaining message':
					'{{days}} af in-app-opdateringer resterende',

				'Week of': 'Ugens',
				weeks: 'uger',
				week: 'uge',

				months: 'måneder',
				month: 'måned',

				years: 'flere år',
				year: 'år',

				'Show Distances': 'Vis Afstande',
				Horizon: 'Horisont',
				'Breakout By': 'breakout By',
				nothing: 'intet',
				'Horizon Settings': 'Horizon Indstillinger',

				'Breakout by Resource': 'Opdeling efter ressource',
				'Breakout by Status': 'Opdeling efter status',
				'Repeating Events': 'Gentagne begivenheder',
				'Hide all repetitions': 'Skjul alle gentagelser',
				'Hide daily': 'Skjul dagligt',
				'Hide weekly': 'Skjul ugentligt',
				'Hide monthly': 'Skjul månedligt',
				'Hide yearly': 'Skjul årligt',

				Saved: 'Gemt',
				Deleted: 'slettet',
				Undo: 'Fortryd',
				'No Change History Available':
					'Ingen Tilgængelig Ændringshistorik',
				'Event may be off screen':
					'Begivenheden kan være uden for skærmen',

				'Operation Failed': 'Handlingen mislykkedes',
				'Revert Changes': 'Vend tilbage Ændringer',
				'Changes Reverted': 'Ændringer Fortrudt',
				'Return To Event': 'Retur til begivenhed',

				'Authentication Successful': 'godkendelse vellykket',
				'Please close to return to the calendar':
					'Luk for at gå tilbage til kalenderen',

				'See in-app updates available since then':
					'Se i app opdateringer tilgængelige siden da',

				Views: 'Visninger',
				'Horizon View': 'View horisont',
				'Resource View': 'View ressource',
				'Month View': 'måned Vis',
				Fluid: 'Væske',
				'Fit to screen': 'Tilpas til skærm',

				'Admin Settings': 'Admin Indstillinger',
				'Administrator Settings': 'administratorindstillinger',
				'My Settings': 'Mine indstillinger',
				'Contact Us And Docs': 'Kontakt os & Docs',
				'Sign-Out': 'Log ud',
				Feedback: 'Feedback',
				'Schedule a Demo': 'Planlæg en demo',

				'events can only be moved to other calendars when they are first created':
					'{{source}} begivenheder kan kun flyttes til andre kalendere, når de først er oprettet.',

				'Editing Scale': 'Redigering Scale',

				'defend your time': 'forsvare din tid',
				'add to calendar': 'Føj til kalender',
				More: 'Mere',

				'Invalid View': 'Ugyldig View',
				'Invalid view message':
					'En ugyldig synspunkt blev brugt til at navigere i kalenderen og er blevet ignoreret.',
				'Invalid Date': 'Ugyldig dato',
				'Invalid date message':
					'En ugyldig dato blev brugt til at navigere i kalenderen og er blevet ignoreret.',
				'Invalid Bookmark ID': 'Ugyldigt bogmærke-id',
				'Invalid Bookmark ID message':
					'Bogmærke-id {{bookmarkID}} er ugyldig eller ikke en del af din DayBack Group',
				Error: 'Fejl',
				'Bookmark update filter error message':
					'Opdateringen kan ikke udføres, fordi filterlisten ikke længere matcher den, der er gemt med bogmærket. Opret og del et nyt bogmærke i stedet.',
				'Bookmark visit filter error message':
					'Filterlisten matcher ikke længere den, der er gemt med bogmærket, så dette bogmærke er muligvis forældet.',

				Continue: 'Blive ved',
				'Learn More': 'Lær mere',

				Share: 'Del',
				'Bookmark or Share This View':
					'Bogmærke eller del denne opfattelse',
				'Manage Bookmarks/Shares': 'Administrer bogmærker/aktier',
				'Recent Bookmarks': 'Seneste bogmærker',
				'Show All': 'Vis alt',
				'DayBack Mission': 'DayBack mission',
				Blog: 'Blog',
				'Get Your Own DayBack': 'Få din egen DayBack',
				'Contact DayBack': 'Kontakt DayBack',
				'About DayBack': 'Om DayBack',

				'Enable Sharing': 'Aktiver Deling',
				Off: 'Af',
				Administrator: 'Administrator',
				Administrators: 'administratorer',
				Everyone: 'Alle sammen',

				'Enabled for Administrators': 'Aktiveret for administratorer',
				'Sharing Disabled': 'Deling deaktiveret',

				'DayBack Share Not Found': 'DayBack Del ikke fundet',
				'The share you have requested has expired or does not exist.':
					'Andelen du har anmodet om, er udløbet eller findes ikke.',
				'Why did this happen?': 'Hvorfor skete det?',

				'Want more? Get your own DayBack.':
					'Ønsker mere? Få din egen DayBack...',
				'Try DayBack for Free': 'Prøv DayBack gratis',

				Update: 'Opdatering',
				Edit: 'Redigere',
				Visit: 'Besøg',
				'Bookmark/Share Detail': 'Bogmærke/Del Detaljer',
				'Created by': 'Lavet af',
				'Created By': 'Lavet af',
				'Expires In': 'udløber I',
				Expires: 'udløber',
				Expired: 'Udløbet',
				New: 'Ny',
				Create: 'skab',
				'Show In Timezone': 'Vis i tidszone',
				'Extend View': 'Udvid visningen',
				'Create Share': 'Opret Del',
				'Shared Dates': 'Delte Datoer',
				'Share with': 'Del med',
				'Shared with': 'Delt med',
				'Just Me': 'Kun mig',
				'My DayBack Group': 'Min DayBack-gruppe',
				Public: 'Offentlig',
				'Public share options': 'Aktieoptioner',
				Bookmark: 'bogmærke',
				Created: 'Oprettet',
				URL: 'URL',
				'Embed Code': 'Integreret Kode',
				'Update Share': 'Opdatering Del',
				'Update Bookmark': 'Opdater bogmærke',
				'Copy URL': 'Kopier URL',
				'Copy Embed Code': 'Kopier integreret kode',
				'Copied to clipboard': 'Kopieret til udklipsholderen',
				'Please copy the selected text':
					'Kopier venligst den markerede tekst',
				'Creating share': 'Oprettelse af andel',
				'Updating share': 'Opdatering del',
				'Bookmark created successfully': 'Bogmærke oprettet med succes',
				'Share updated successfully': 'Del opdateret',
				'Updating takes you to the view you have shared':
					'Opdatering tager dig til den visning, du har delt og re-synkroniserer matchende poster.',
				'Remember changes made are automatically synced':
					'Husk, eventuelle ændringer i DayBack automatisk synkroniseret til dine aktier.',
				'Updating this bookmark will reset the filters':
					'Hvis du opdaterer dette bogmærke, nulstilles filtrene, visningen og datointervallet (hvis relevant) til det, du ser lige nu.',

				'Authorization Failed': 'godkendelse mislykkedes',
				'Connecting To DayBack': 'Tilslutning til DayBack',
				'Get Started': 'Kom igang',
				'Sharing not Activated': 'Deling ikke Aktiveret',
				'Sharing Enabled': 'Deling Aktiveret',
				'There are no shares yet': 'Der er ingen aktier endnu',

				Subscribe: 'Tilmeld',
				'Sharing Trial Expired': 'Deling Trial Udløbet',
				'Sharing Subscription Expired': 'Deling Abonnement udløbet',

				'Custom Fields': 'Tilpassede felter',
				'Select Date': 'Vælg Dato',

				'Add Field': 'Tilføj felt',
				'Add Button': 'Tilføj knap',

				'View In Source': 'Se i {{source}}',

				'Basecamp HTTPS Required':
					'Basecamp kræver en sikker forbindelse. Vil du gerne genindlæse DayBack ved hjælp af HTTPS?',
				'HTTPS Connection Required': 'HTTPS-forbindelse er påkrævet',
				'Reload as HTTPS': 'Genindlæs som HTTPS',

				Total: 'Total',
				'Chart Data': 'Diagramdata',
				Format: 'Format',
				Threshold: 'Grænseværdi',
				'Chart the value of a specific field:':
					'Diagrammet værdien af et bestemt felt:',
				'Chart the number of items': 'Diagram antallet af elementer',
				'Chart the duration of items': 'Diagram varigheden af varer',
				'Show a threshold value on the chart':
					'Vis en tærskelværdi på diagrammet',
				Value: 'Værdi',
				'Line Color': 'Linjens farve',
				'Fill Color': 'Fyld farve',
				'Fill Opacity': 'Fyld opacitet',
				'color formats?': 'farveformater?',
				'Color formats instructions':
					'Brug en CSS-farve, herunder RGB-, RGBA- eller Hex-værdier',

				'Format as': 'Format som',
				'Label Before Value': 'Etiket før værdi',
				'Label After Value': 'Etiket efter værdi',
				'Decimal Symbol': 'Decimalt Symbol',
				'Decimal Places': 'Decimale Steder',
				'Thousands Separator': 'Tusinder Separator',

				event: 'begivenhed',
				events: 'begivenheder',
				item: 'vare',
				items: 'genstande',
				'Over by': 'Over ved',
				'Under by': 'Under',
				'Chart by day, week': 'Diagram efter dag, uge',
				Auto: 'auto',

				'Add Calendar': 'Tilføj kalender',
				'Calendar is read-only': 'Kalender er skrivebeskyttet',

				Folder: 'Folder',
				'Folder Name': 'Mappenavn',

				'Only applies to horizon view at day scale':
					'Gælder kun horisontvisning i dagsskala',

				'Switch Accounts': 'Skift konti',
				Deauthorize: 'godkendelse',
				Authorize: 'Bemyndige',
				'Maybe Later': 'Måske senere',

				'Grant Access To Your Google Account':
					'Giv adgang til din {{type}}-konto',
				'To view your Google Calendars you will want to allow DayBack to access your Google account':
					'For at se dine {{type}}-kalendere skal du give DayBack adgang til din {{type}}-konto.',
				'Welcome To DayBack': 'Velkommen til DayBack',

				'Waiting for events': 'Venter på begivenheder',
				'Cancel Request': 'Annuller anmodning',

				Theme: 'Tema',

				'Moving sample data':
					'Flytning af eksempeldata på plads. Dette kan tage et øjeblik.',

				'Switch to this date with the bookmark':
					'Skift til denne dato med bogmærket',
				'Changing switch date':
					'Ændring af denne indstilling for et offentligt bogmærke ændrer det aktuelle datointerval for visningen og opdaterer de delte begivenheder for at matche det, der vises i visningen',

				'There was a problem signing in':
					'Der opstod et problem med at logge ind {{account}}',
				'Your current DayBack license does not support this feature.':
					'Din nuværende DayBack-licens understøtter ikke denne funktion.',
				'Your subscription does not have enough user licenses':
					'Dit abonnement har ikke nok brugerlicenser.',
				'There was an issue retrieving data from the specified group token':
					'Der opstod et problem med at hente data fra det specificerede gruppetoken.',
				'Please contact your FileMaker developer':
					'Kontakt din FileMaker-udvikler.',
				Account: 'Rekening',
				Accounts: 'Rekeningen',
				and: 'en',
				"Calendar Can't be found": 'Kalender kan niet worden gevonden',
				'Bookmark calendar not found message':
					'De {{calendarSchedules}} kan niet worden gevonden. Gelieve de korrekt {{calendarSources}} naar {{action}} deze bladwijzer.',

				'First linked event': 'Første sammenkædede begivenhed',
				'Previous linked event': 'Tidligere linket begivenhed',
				'Step through linked events':
					'Gå gennem sammenkædede begivenheder',
				'End of linked events':
					'Afslutning af sammenkædede begivenheder',
				'Next linked event': 'Næste sammenkædede begivenhed',
				'Last linked event': 'Sidste linkede begivenhed',
				Pause: 'Pause',

				Attachments: 'Vedhæftede filer',
				'The original event has attachments':
					'Den oprindelige begivenhed har vedhæftede filer. Disse vedhæftede filer fjernes fra den duplikerede begivenhed.',

				'Filter resources': 'Filtrer ressourcer',
				'No resources match your criteria':
					'Ingen ressourcer matcher dine kriterier',

				Filter: 'Filter',
				Class: 'Class',
				Tags: 'Tags',
				Capacity: 'Kapacitet',
				'comma separated': 'komma separeret',
				'No additional details': 'Ingen yderligere detaljer',
				'This item was created dynamically and should be modified in its original source':
					'Dette element blev oprettet dynamisk og skulle ændres i den originale kilde',

				'Over under threshold': 'Over/under tærskel',
				over: 'over',
				'Includes partial week': 'Inkluderer delvis uge',
				'Includes partial month': 'Inkluderer delvis måned',
				'Public bookmarks expire essentials plan':
					'Offentlige bogmærker udløber om 72 timer i Essentials-planen. For offentlige kalendere, der varer længere end 3 dage, bedes du opgradere til Plus-abonnementet, hvor du kan indstille din egen udløbsdato.(Edited)Restore original',
				'Learn more about DayBack Plans and Pricing':
					'Få mere at vide om DayBack-planer og priser',
				'No events found': 'Ingen begivenheder fundet',

				'Service will end days unless payment is received':
					'Der Service endet in {{days}} Tagen, sofern keine Zahlung eingegangen ist',
				'Service will end day unless payment is received':
					'Tjenesten slutter om {{days}} dag, medmindre betaling er modtaget',

				Drop: 'Droppe',
				'Count Unscheduled Items': '{{count}} ikke-planlagte elementer',
				'Count Unscheduled Item': '{{count}} ikke-planlagt vare',
				'No Unscheduled Items': 'Ingen uplanlagte varer',
				'Unscheduled is not available for this calendar':
					'Uplanlagt er ikke tilgængelig for denne kalender',

				'Show as resource for me': 'Vis som ressource for mig',

				Assigned: 'Tildelt',

				'Public shares will be deleted 30 days after expiration':
					'Offentlige aktier vil blive slettet 30 dage efter udløb',
				'Will be deleted 30 days after expiration':
					'Vil blive slettet 30 dage efter udløb',
			},
		};

		//Dutch language and substitutions - Special thanks to Jean Wenmeekers
		defaultTranslations['nl'] = {
			id: 'nl',
			name: 'Nederlands',
			translations: {
				//These are name value pairs. Customize the values only (the item after the colon)
				Home: 'Home',
				Calendars: 'Kalender',
				Filters: 'Filters',
				Settings: 'Instellingen',

				'Hide Sidebar': 'Verberg sidebar',
				'Show Sidebar': 'Toon sidebar',

				//repeating event items
				Repeat: 'Herhaling',
				January: 'januari-',
				February: 'februari',
				March: 'maart',
				April: 'april',
				May: 'mei',
				June: 'juni-',
				July: 'juli-',
				August: 'augustus',
				September: 'september',
				October: 'oktober',
				November: 'november',
				December: 'december',
				Monday: 'maandag',
				Tuesday: 'dinsdag',
				Wednesday: 'woensdag',
				Thursday: 'donderdag',
				Friday: 'vrijdag',
				Saturday: 'zaterdag',
				Sunday: 'zondag',
				Daily: 'Dagelijks',
				Weekly: 'wekelijks',
				Monthly: 'Maandelijks',
				Yearly: 'jaar-',
				'1st': 'Eerste',
				'2nd': 'Seconde',
				'3rd': 'Derde',
				'4th': 'Vierde',
				Last: 'Laatste',
				'repeat every': 'herhaal elke',
				'day on': 'dag op',
				'days on': 'dagen op',
				'week on': 'week op',
				'weeks on': 'weken op',
				'month on': 'maand op',
				'months on': 'maanden later',
				'year on': 'jaar op',
				'years on': 'jaren later',
				'stop after': 'stop na',
				never: 'nooit',
				'on the': 'op de',
				'Your changes will only apply to this event, not to its other repetitions. Would you still like to save this change?':
					'Uw wijzigingen zijn alleen van toepassing op dit evenement, niet voor de andere herhalingen. Wilt u deze wijziging nog steeds opslaan?',
				'Edit only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Bewerk alleen dit item, dit en alle toekomstige herhalingen, of alle herhalingen? Dit kan niet ongedaan gemaakt worden.',
				'Delete only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Verwijder alleen dit item, dit en alle toekomstige herhalingen, of alle herhalingen? Dit kan niet ongedaan gemaakt worden.',
				'Edit only this item or this and all future repetitions? This cannot be undone.':
					'Bewerk alleen dit item of dit en alle toekomstige herhalingen? Dit kan niet ongedaan gemaakt worden.',
				'Edit Repeating Item': 'Herhaal item herstellen',
				'Delete Repeating Item': 'Verwijder herhalend item',
				'Only This Item': 'Alleen dit artikel',
				'All Future Repetitions': 'Alle toekomstige herhalingen',
				'All Repetitions': 'Alle herhalingen',
				'Would you like to delete all future repetitions and create new ones? This cannot be undone':
					'Wilt u alle toekomstige herhalingen verwijderen en nieuwe creëren? Dit kan niet ongedaan gemaakt worden',
				'Repeating Event': 'Herhaling',
				'Delete Only': 'Alleen verwijderen',
				'Delete and New': 'Verwijderen en nieuw',
				'Future Repetitions Deleted':
					'Toekomstige herhalingen verwijderd',
				'must be on or before': 'moet op of voordien zijn',

				Day: 'Dag',
				Schedule: 'Tijdsplan',
				List: 'Lijst',
				Week: 'Week',
				Month: 'Maand',
				Resource: 'Bron',
				Grid: 'rooster',
				'Pivot Schedule': 'Pivot Schema',
				'Pivot List': 'pivot Lijst',

				'Snap To Month': 'Snap tot maand',

				Today: 'Vandaag',
				Resources: 'Bronnen',
				resources: 'bronnen',

				Calendar: 'kalender',
				All_Day: 'Ganse dag',
				Start: 'Start',
				End: 'Einde',
				Status: 'Status',
				Location: 'plaats',
				Contact: 'Kontakt',
				Project: 'Projekt',
				Done: 'Gedaan',

				Delete: 'Verwijder',
				Close: 'Sluiten',
				'Save And Close': 'Opslaan',
				'Save And Back': 'Opslaan en Terug',
				Back: 'Terug',

				'Show on Map': 'Toon op kaart',
				'Open URL': 'Open url',
				'Open Map': 'Open Kaart',
				'Location not found': 'Locatie is niet gevonden',
				'Enter an address or location name':
					'Voer een adres of plaatsnaam',

				Search: 'Zoek',
				none: 'geen',

				Description: 'Beschrijving',
				'Enter Title': 'Voer titel in',
				'resource inherited from calendar':
					'resource geërfd van de kalender',

				'Clear Selection': 'Verwijder selectie',

				New_Event_Title: 'nieuw evenement',
				'New Event': 'nieuw evenement',
				'View Day': 'Dagweergave',
				'You have no editable calendars selected':
					"Je hebt geen bewerkbare agenda's geselecteerd",
				'You may not edit shared views':
					'Je mag geen gedeelde weergaven bewerken',
				'You have no calendar sources configured':
					'Je hebt geen kalender bronnen geconfigureerd',
				'You have no calendars selected that allow times':
					"Je hebt geen agenda's geselecteerd die het mogelijk maken tijden",
				'Could not create a new event because all breakout items are hidden':
					'Kan een nieuw evenement niet maken omdat alle breakout artikelen zijn verborgen',
				"'All Day Event' is currently disabled for Events":
					'het hele dag evenement is momenteel uitgeschakeld voor evenementen',

				'Time Settings': 'Tijdsinstellingen',
				'Default Time': 'Standaard tijd',
				'Earliest Time': 'Vroegste tijd',
				'Latest Time': 'Laatste tijd',
				'Time Scale': 'Tijdschaal',
				'View Settings': 'Instellingen bekijken',
				'Compressed View': 'Compressed View',
				'Fluid Months': 'Glijdende maanden',

				Yes: 'Ja',
				No: 'Neen',

				'Advanced Filters': 'Geavanceerde filters',
				'Clear Filters': 'Filters wissen',

				Week_Number_Header: 'W',
				more: 'meer',

				hour: 'uur',
				hours: 'uren',
				minute: 'minuut',
				minutes: 'minute',

				Text: 'Tekst',
				'Status Filters': 'Status Filters',
				'Resource Filters': 'Resource Filters',
				'Project Filters': 'Project Filters',
				'Contact Filters': 'Contact Filters',

				'New Status': 'nieuwe status',
				'New Resource': 'nieuwe resource',
				'New Folder': 'nieuwe map',

				Sources: 'Bronnen',
				Save: 'Opslaan',
				Cancel: 'Annuleren',
				cancel: 'annuleren',

				'Show as resource': 'Toon als resource',
				'Calendar for new entries': 'Primaire Kalender',
				'for me': 'voor mij',
				'for everyone': 'voor iedereen',
				'disabled by your administrator':
					'uitgeschakeld door uw beheerder',

				'Call your own scripts': 'Selecteer uw eigen script',

				'Status Filter': 'Status Filter',

				Name: 'Naam',
				Color: 'Kleur',

				day: 'dag',
				days: 'dagen',

				'Account Settings': 'Registratie Instellingen',
				'Trial Mode': 'Test modus',
				'Contact Us': 'Contacteer ons',
				Help: 'Helpen',
				Documentation: 'Documentatie',
				'What Is New': 'Wat is er nieuw',
				'Sign Out': 'Afmelden',

				'Check For Updates': 'Controleer voor updates',
				'Install Update': 'Installeer updates',
				'what is new': 'Wat is er nieuw?',

				'Load Calendar': 'Start Kalender',

				'Purchase DayBack': 'Koop DayBack',
				'Switch To Subscription': 'Schakel over naar abonnement',
				'Manage Subscription': 'Abonnement beheren',
				'Enter New License': 'Voer nieuwe licentie in',
				Activate: 'Activeren',

				'Trial activated': 'Uw {{days}} proefperiode is geactiveerd',
				'Trial expired': 'Uw proefperiode is verlopen',
				'Trial expired message':
					'Proef periode verlopen. Schaf een licentie aan om door te gaan.',
				'Trial remains': 'Nog {{days}} in de proefperiode',
				'Trial expires in days': 'Uw proef verloopt over {{days}}.',

				'Subscription expired': 'Abonnement is {{days}} verlopen',
				'Subscription expired message':
					'Het abonnement is verlopen. Vernieuw hier om door te gaan.',
				'Cease to function message':
					'Als een nieuwe licentie niet wordt ingevoerd, zal de agenda ophouden te functineren in {{days}}',

				'Subscription renews': 'Abonnement te vernieuwen in {{days}}',
				'Subscription renews today':
					'Het abonnement wordt vandaag verlengd',
				'Subscription should have renewed':
					'Het abonnement had {{days}} geleden moeten worden verlengd',

				'Subscription overdue message admin':
					'Abonnementsbetaling is achterstallig. Beheer uw abonnement en update betalingsgegevens.',
				'Subscription overdue message non admin':
					'Abonnementsbetaling is achterstallig. Beheer uw abonnement en update betalingsgegevens.',

				Purchased: 'Gekocht',

				Product: 'product',
				'Order Number': 'Ordernummer',
				Email: 'Email',
				'Registered To': 'Geregistreerd op naam van',

				'Current version': 'U gebruikt versie {{version}}',
				'Version available': 'Versie {{version}} is beschikbaar',
				'Running latest version': 'U werkt met de laatste versie',
				'Cannot connect':
					'Kan geen verbinding met de server maken om te updaten',
				'Problem downloading update':
					'Er is een probleem met het downloaden van de update',

				'Activation denied':
					'Het activeringsverzoek werd geweigerd. Controleer uw gegevens en prober opnieuw',
				'Activation successful': 'Activering geslaagd',

				TooManyUsers1: 'Te veel gebruikers',
				TooManyUsers2: 'Te veel gebruikers hebben de kalender geopend.',
				TooManyUsers3:
					'U kunt een upgrade kopen om meer gebruikers toe te staan of tijdelijk opheft het momenteel geteld gebruikers, zodat u kunt blijven werken nu.',
				'Purchase Upgrade': 'Aankoop Upgrade',
				'Reset User Count': 'Reset User Graaf',

				'Mobile not supported':
					'Dit wordt momenteel niet ondersteund op iOS',
				'Update Successful': 'actualiseren Succesvolle',

				'Resource Columns': 'Hulpbronnen Columns',

				Refresh: 'verversen',
				'Show Weekends': 'Show Weekends',
				Days: 'dagen',
				of: 'van',

				Fetching: 'ophalen',

				'Extend In-App Updates': 'Breid in-app-updates uit',
				'Updates expired message':
					'Toegang tot in-app-updates verlopen {{days}} geleden',
				'Free updates remaining message':
					'{{days}} van in-app-updates blijven',

				'Week of': 'week van',
				weeks: 'weken',
				week: 'week',

				months: 'maanden',
				month: 'maand',

				years: 'jaar',
				year: 'jaar',

				'Show Distances': 'Toon Afstanden',
				Horizon: 'Horizon',
				'Breakout By': 'Breakout Door',
				nothing: 'niks',
				'Horizon Settings': 'horizon Instellingen',

				'Breakout by Resource': 'Opsplitsing naar bron',
				'Breakout by Status': 'Opsplitsing naar status',
				'Repeating Events': 'Herhalende evenementen',
				'Hide all repetitions': 'Verberg alle herhalingen',
				'Hide daily': 'Dagelijks verbergen',
				'Hide weekly': 'Wekelijks verbergen',
				'Hide monthly': 'Maandelijks verbergen',
				'Hide yearly': 'Jaarlijks verbergen',

				Saved: 'gered',
				Deleted: 'Verwijderd',
				Undo: 'ongedaan maken',
				'No Change History Available':
					'Geen Beschikbare Wijzigingsgeschiedenis',
				'Event may be off screen':
					'Begivenheden kan være uden for skærmen',

				'Operation Failed': 'Operatie mislukt',
				'Revert Changes': 'terugkeren veranderingen',
				'Changes Reverted': 'Wijzigingen Teruggedraaid',
				'Return To Event': 'Keer terug naar evenement',

				'Authentication Successful': 'authenticatie Succesvolle',
				'Please close to return to the calendar':
					'Sluit om terug te keren naar de agenda',

				'See in-app updates available since then':
					'Zie in-app-updates beschikbaar zijn sindsdien',

				Views: 'Uitzichten',
				'Horizon View': 'Horizon View',
				'Resource View': 'Bronnenweergave',
				'Month View': 'maand View',
				Fluid: 'Vloeistof',
				'Fit to screen': 'Fit om het scherm',

				'Admin Settings': 'Admin-instellingen',
				'Administrator Settings': 'beheerdersinstellingen',
				'My Settings': 'Mijn instellingen',
				'Contact Us And Docs': 'Contact met ons op en docs',
				'Sign-Out': 'Afmelden',
				Feedback: 'terugkoppeling',
				'Schedule a Demo': 'Plan een demo',

				'events can only be moved to other calendars when they are first created':
					"{{source}} events kan alleen worden verplaatst naar andere agenda's toen ze voor het eerst worden gemaakt.",

				'Editing Scale': 'editing Schaal',

				'defend your time': 'verdedig je tijd',
				'add to calendar': 'toevoegen aan agenda',
				More: 'Meer',

				'Invalid View': 'ongeldige View',
				'Invalid view message':
					'Er is een ongeldige oog werd gebruikt om de kalender te navigeren en is genegeerd.',
				'Invalid Date': 'Ongeldige datum',
				'Invalid date message':
					'Een ongeldige datum werd gebruikt om de kalender te navigeren en is genegeerd.',
				'Invalid Bookmark ID': 'Ongeldige bladwijzer-ID',
				'Invalid Bookmark ID message':
					'Bladwijzer-ID {{bookmarkID}} is ongeldig of maakt geen deel uit van uw DayBack-groep',
				Error: 'Fout',
				'Bookmark update filter error message':
					'De update kan niet worden uitgevoerd omdat de filterlijst niet langer overeenkomt met de lijst met de bladwijzer. Maak en deel in plaats daarvan een nieuwe bladwijzer.',
				'Bookmark visit filter error message':
					'De filterlijst komt niet meer overeen met de lijst die met de bladwijzer is opgeslagen, dus deze bladwijzer is mogelijk verouderd.',

				Continue: 'voortzetten',
				'Learn More': 'Kom meer te weten',

				Share: 'Delen',
				'Bookmark or Share This View': 'Bladwijzer of deel deze view',
				'Manage Bookmarks/Shares': 'Beheer bladwijzers/aandelen',
				'Recent Bookmarks': 'Recente bladwijzers',
				'Show All': 'Toon alles',
				'DayBack Mission': "Mission DayBack's",
				Blog: 'blog',
				'Get Your Own DayBack': 'Ontvang je eigen DayBack',
				'Contact DayBack': 'Contact DayBack',
				'About DayBack': 'over DayBack',

				'Enable Sharing': 'inschakelen Sharing',
				Off: 'Uit',
				Administrator: 'Beheerder',
				Administrators: 'Beheerders',
				Everyone: 'Iedereen',

				'Enabled for Administrators': 'Ingeschakeld voor beheerders',
				'Sharing Disabled': 'Het delen van een handicap',

				'DayBack Share Not Found': 'DayBack Deel niet gevonden',
				'The share you have requested has expired or does not exist.':
					'De door u gevraagde aandeel is verlopen of niet bestaat.',
				'Why did this happen?': 'Waarom is dit gebeurd?',

				'Want more? Get your own DayBack.':
					'Meer willen? Haal je eigen DayBack...',
				'Try DayBack for Free': 'Probeer DayBack gratis',

				Update: 'Bijwerken',
				Edit: 'Bewerk',
				Visit: 'Bezoek',
				'Bookmark/Share Detail': 'Bladwijzer/Aandeel Detail',
				'Created by': 'Gemaakt door',
				'Created By': 'Gemaakt door',
				'Expires In': 'Verloopt in',
				Expires: 'verloopt',
				Expired: 'Niet meer geldig',
				New: 'Nieuw',
				Create: 'Creëer',
				'Show In Timezone': 'Weergeven in tijdzone',
				'Extend View': 'Breid de weergave uit',
				'Create Share': 'Maken Delen',
				'Shared Dates': 'gedeelde data',
				'Share with': 'Delen met',
				'Shared with': 'Gedeeld met',
				'Just Me': 'Alleen ik',
				'My DayBack Group': 'Mijn DayBack Group',
				Public: 'Openbaar',
				'Public share options': 'Openbare aandelenopties',
				Bookmark: 'Bladwijzer',
				Created: 'Gemaakt',
				URL: 'URL',
				'Embed Code': 'Embed Code',
				'Update Share': 'aandeel bijwerken',
				'Update Bookmark': 'Bladwijzer bijwerken',
				'Copy URL': 'Kopie URL',
				'Copy Embed Code': 'Kopieer de embed code',
				'Copied to clipboard': 'Gekopieerd naar het klembord',
				'Please copy the selected text':
					'Kopieer de geselecteerde tekst',
				'Creating share': 'Aandeel creëren',
				'Updating share': 'Het delen van updates',
				'Bookmark created successfully':
					'Bladwijzer is succesvol gemaakt',
				'Share updated successfully': 'Share bijgewerkt',
				'Updating takes you to the view you have shared':
					'Updaten neemt je mee naar de weergave die u hebt gedeeld en re-synchroniseert passende punten.',
				'Remember changes made are automatically synced':
					'Vergeet niet dat de wijzigingen in recuperatieperiode worden automatisch gesynchroniseerd met uw aandelen.',
				'Updating this bookmark will reset the filters':
					'Als u deze bladwijzer bijwerkt, worden de filters, de weergave en het datumbereik (indien van toepassing) gereset naar wat u nu ziet.',

				'Authorization Failed': 'Autorisatie mislukt',
				'Connecting To DayBack': 'Aansluiten op DayBack',
				'Get Started': 'Begin',
				'Sharing not Activated': 'Delen is niet geactiveerd',
				'Sharing Enabled': 'sharing Ingeschakeld',
				'There are no shares yet': 'Er zijn nog geen aandelen',

				Subscribe: 'abonneren',
				'Sharing Trial Expired': 'Het delen van Trial Verlopen',
				'Sharing Subscription Expired':
					'Het delen Het abonnement is verlopen',

				'Custom Fields': 'Aangepaste velden',
				'Select Date': 'Selecteer Datum',

				'Add Field': 'Veld toevoegen',
				'Add Button': 'Knop toevoegen',

				'View In Source': 'Bekijk in {{source}}',

				'Basecamp HTTPS Required':
					'Basecamp heeft een beveiligde verbinding nodig. Wilt u DayBack herhalen met HTTPS?',
				'HTTPS Connection Required': 'HTTPS-verbinding vereist',
				'Reload as HTTPS': 'Herlaad als HTTPS',

				Total: 'Totaal',
				'Chart Data': 'Grafiekgegevens',
				Format: 'Formaat',
				Threshold: 'Drempel',
				'Chart the value of a specific field:':
					'Breng de waarde van een specifiek veld in kaart:',
				'Chart the number of items': 'Breng het aantal items in kaart',
				'Chart the duration of items':
					'Breng de duur van items in kaart',
				'Show a threshold value on the chart':
					'Geef een drempelwaarde weer op het diagram',
				Value: 'Waarde',
				'Line Color': 'Lijnkleur',
				'Fill Color': 'Opvulkleur',
				'Fill Opacity': 'Vul de dekking',
				'color formats?': 'kleur formaten?',
				'Color formats instructions':
					'Gebruik elke CSS-kleur, inclusief RGB-, RGBA- of Hex-waarden',

				'Format as': 'Formatteer als',
				'Label Before Value': 'Label vóór waarde',
				'Label After Value': 'Label na waarde',
				'Decimal Symbol': 'Decimaal symbool',
				'Decimal Places': 'Decimale plaatsen',
				'Thousands Separator': 'Duizenden scheidingsteken',

				event: 'evenement',
				events: 'events',
				item: 'item',
				items: 'artikelen',
				'Over by': 'Voorbij',
				'Under by': 'Onder met',
				'Chart by day, week': 'Grafiek per dag, week',
				Auto: 'Auto',

				'Add Calendar': 'Agenda toevoegen',
				'Calendar is read-only': 'Agenda is alleen-lezen',

				Folder: 'Map',
				'Folder Name': 'Naam van de map',

				'Only applies to horizon view at day scale':
					'Geldt alleen voor horizonweergave op dagschaal',

				'Switch Accounts': 'Accounts wisselen',
				Deauthorize: 'Autorisatie ongedaan maken',
				Authorize: 'Toestemming geven',
				'Maybe Later': 'Misschien later',

				'Grant Access To Your Google Account':
					'Toegang verlenen tot uw {{type}}-account',
				'To view your Google Calendars you will want to allow DayBack to access your Google account':
					"Als u uw {{type}}-agenda's wilt bekijken, wilt u DayBack toegang geven tot uw {{type}}-account.",
				'Welcome To DayBack': 'Welkom bij DayBack',

				'Waiting for events': 'Wachten op evenementen',
				'Cancel Request': 'Annuleer verzoek',

				Theme: 'Thema',

				'Moving sample data':
					'Voorbeeldgegevens op hun plaats zetten. Dit kan even duren.',

				'Switch to this date with the bookmark':
					'Schakel over naar deze datum met de bladwijzer',
				'Changing switch date':
					'Als u deze instelling wijzigt voor een openbare bladwijzer, wordt het huidige datumbereik voor de weergave gewijzigd en worden de gedeelde evenementen bijgewerkt zodat ze overeenkomen met wat in de weergave wordt weergegeven',

				'There was a problem signing in':
					'Er is een probleem opgetreden bij het aanmelden bij {{account}}',
				'Your current DayBack license does not support this feature.':
					'Uw huidige DayBack-licentie ondersteunt deze functie niet.',
				'Your subscription does not have enough user licenses':
					'Uw abonnement heeft niet genoeg gebruikerslicenties.',
				'There was an issue retrieving data from the specified group token':
					'Er is een probleem opgetreden bij het ophalen van gegevens uit het opgegeven groepstoken.',
				'Please contact your FileMaker developer':
					'Neem contact op met uw FileMaker-ontwikkelaar.',
				Account: 'Konto',
				Accounts: 'Konti',
				and: 'og',
				"Calendar Can't be found": 'Kalender kan ikke findes',
				'Bookmark calendar not found message':
					'Det {{calendarSchedules}} kan ikke findes. Venligst juiste {{calendarSources}} til {{action}} deze bladwijzer.',

				'First linked event': 'Eerste gekoppelde evenement',
				'Previous linked event': 'Vorige gekoppelde afspraak',
				'Step through linked events':
					'Stap door gekoppelde evenementen',
				'End of linked events': 'Einde van gekoppelde evenementen',
				'Next linked event': 'Volgende gekoppelde afspraak',
				'Last linked event': 'Laatste gekoppelde afspraak',
				Pause: 'Pauze',

				Attachments: 'Bijlagen',
				'The original event has attachments':
					'De oorspronkelijke afspraak heeft bijlagen. Die bijlagen worden verwijderd uit het gedupliceerde evenement.',

				'Filter resources': 'Bronnen filteren',
				'No resources match your criteria':
					'Er zijn geen bronnen die aan uw criteria voldoen',

				Filter: 'Filter',
				Class: 'Class',
				Tags: 'Tags',
				Capacity: 'Capaciteit',
				'comma separated': 'kommagescheiden',
				'No additional details': 'Geen aanvullende details',
				'This item was created dynamically and should be modified in its original source':
					'Dit item is dynamisch gemaakt en moet worden gewijzigd in de oorspronkelijke bron',

				'Over under threshold': 'Over/onder drempel',
				over: 'meer dan',
				'Includes partial week': 'Inclusief gedeeltelijke week',
				'Includes partial month': 'Inclusief gedeeltelijke maand',
				'Public bookmarks expire essentials plan':
					"Openbare bladwijzers verlopen na 72 uur in het Essentials-abonnement. Voor openbare agenda's die langer dan 3 dagen blijven bestaan, moet u upgraden naar het Plus-abonnement, waar u uw eigen vervaldatum kunt instellen.",
				'Learn more about DayBack Plans and Pricing':
					'Meer informatie over DayBack-abonnementen en prijzen',
				'No events found': 'Geen evenementen gevonden',

				'Service will end days unless payment is received':
					'De service eindigt over {{days}} dagen, tenzij de betaling is ontvangen',
				'Service will end day unless payment is received':
					'De service eindigt over {{days}} dag, tenzij de betaling is ontvangen',

				Drop: 'Vallen',
				'Count Unscheduled Items': '{{count}} ongeplande items',
				'Count Unscheduled Item': '{{count}} ongepland item',
				'No Unscheduled Items': 'Geen ongeplande items',
				'Unscheduled is not available for this calendar':
					'Ongepland is niet beschikbaar voor deze agenda',

				'Show as resource for me': 'Toon als bron voor mij',

				Assigned: 'Toegewezen',

				'Public shares will be deleted 30 days after expiration':
					'Openbare shares worden 30 dagen na afloop verwijderd',
				'Will be deleted 30 days after expiration':
					'Wordt 30 dagen na afloop verwijderd',
			},
		};

		//Swedish language and substitutions - Special thanks to Simon Danielsson - Quadevo AB - Sweden
		defaultTranslations['sv'] = {
			id: 'sv',
			name: 'svenska',
			translations: {
				//These are name value pairs. Customize the values only (the item after the colon)
				Home: 'Hem',
				Calendars: 'Kalendrar',
				Filters: 'Filter',
				Settings: 'Inställningar',

				'Hide Sidebar': 'Göm sidopanel',
				'Show Sidebar': 'Visa sidopanel',

				//repeating event items
				Repeat: 'Upprepa',
				January: 'januari',
				February: 'februari',
				March: 'Mars',
				April: 'april',
				May: 'maj',
				June: 'juni',
				July: 'juli',
				August: 'augusti',
				September: 'september',
				October: 'oktober',
				November: 'november',
				December: 'december',
				Monday: 'måndag',
				Tuesday: 'tisdag',
				Wednesday: 'onsdag',
				Thursday: 'torsdag',
				Friday: 'fredag',
				Saturday: 'lördag',
				Sunday: 'söndag',
				Daily: 'Dagligen',
				Weekly: 'Varje vecka',
				Monthly: 'En gång i månaden',
				Yearly: 'Årlig',
				'1st': 'Först',
				'2nd': 'Andra',
				'3rd': 'Tredje',
				'4th': 'Fjärde',
				Last: 'Sista',
				'repeat every': 'upprepa varje',
				'day on': 'dag på',
				'days on': 'dagar på',
				'week on': 'vecka på',
				'weeks on': 'veckor på',
				'month on': 'månad på',
				'months on': 'månader på',
				'year on': 'år på',
				'years on': 'år på',
				'stop after': 'sluta efter',
				never: 'aldrig',
				'on the': 'på',
				'Your changes will only apply to this event, not to its other repetitions. Would you still like to save this change?':
					'Dina ändringar gäller endast för den här händelsen, inte för dess andra upprepningar. Vill du fortfarande spara den här ändringen?',
				'Edit only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Redigera bara detta objekt, detta och alla framtida repetitioner, eller alla repetitoner? Detta kan inte göras ogjort.',
				'Delete only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'Ta bort endast detta objekt, detta och alla framtida repetitioner, eller alla repetitoner? Detta kan inte göras ogjort.',
				'Edit only this item or this and all future repetitions? This cannot be undone.':
					'Redigera bara detta objekt eller detta och alla framtida repetitioner? Detta kan inte göras ogjort.',
				'Edit Repeating Item': 'Redigera repeterande objekt',
				'Delete Repeating Item': 'Ta bort Repeating Item',
				'Only This Item': 'Endast denna artikel',
				'All Future Repetitions': 'Alla framtida upprepningar',
				'All Repetitions': 'Alla upprepningar',
				'Would you like to delete all future repetitions and create new ones? This cannot be undone':
					'Vill du radera alla framtida repetitioner och skapa nya? Detta kan inte göras ogjort',
				'Repeating Event': 'Upprepande händelse',
				'Delete Only': 'Radera bara',
				'Delete and New': 'Radera och nya',
				'Future Repetitions Deleted': 'Framtida repetitioner raderas',
				'must be on or before': 'måste vara på eller innan',

				Day: 'Dag',
				Schedule: 'Schema',
				List: 'Lista',
				Week: 'Vecka',
				Month: 'Månad',
				Resource: 'Resurs',
				Grid: 'Grid',
				'Pivot Schedule': 'Pivot Schema',
				'Pivot List': 'Pivot Lista',

				'Snap To Month': 'Snap till månad',

				Today: 'Idag',
				Resources: 'Resurser',
				resources: 'resurser',

				Calendar: 'Kalender',
				All_Day: 'Hela dagen',
				Start: 'Start',
				End: 'Slut',
				Status: 'Status',
				Location: 'Plats',
				Contact: 'Kontakt',
				Project: 'Projekt',
				Done: 'Gjort',

				Delete: 'Radera',
				Close: 'Stäng',
				'Save And Close': 'Spara och stäng',
				'Save And Back': 'Spara och Tillbaka',
				Back: 'Tillbaka',

				'Show on Map': 'Visa på kartan',
				'Open URL': 'Öppna URL',
				'Open Map': 'Öppna stor karta',
				'Location not found': 'Plats kunde inte hittas',
				'Enter an address or location name':
					'Skriv in en adress eller platsnamnet',

				Search: 'Sök',
				none: 'inget',

				Description: 'Beskrivning',
				'Enter Title': 'Ange titel',
				'resource inherited from calendar':
					'resurs ärvt från kalendern',

				'Clear Selection': 'Rensa val',

				New_Event_Title: 'Ny händelse',
				'New Event': 'Ny händelse',
				'View Day': 'Granska dag',
				'You have no editable calendars selected':
					'Du har inga redigerbara kalendrar valts',
				'You may not edit shared views':
					'Du får inte redigera delade vyer',
				'You have no calendar sources configured':
					'Du har ingen kalenderkällor konfigurerade',
				'You have no calendars selected that allow times':
					'Du har inga kalendrar utvalda som tillåter gånger',
				'Could not create a new event because all breakout items are hidden':
					'Det gick inte att skapa en ny händelse eftersom alla breakout objekt döljs',
				"'All Day Event' is currently disabled for Events":
					'händelsen "hela dagen" är för närvarande inaktiverad för händelser',

				'Time Settings': 'Tidsinställningar',
				'Default Time': 'Standrardtid',
				'Earliest Time': 'Tidigaste tid',
				'Latest Time': 'Senaste tid',
				'Time Scale': 'Tidsskala',
				'View Settings': 'Visa inställningar',
				'Compressed View': 'Komprimerad View',
				'Fluid Months': 'Flytande månader',

				Yes: 'Ja',
				No: 'Nej',

				'Advanced Filters': 'Advancerade filter',
				'Clear Filters': 'Rensa filter',

				Week_Number_Header: 'V',
				more: 'mer',

				hour: 'timme',
				hours: 'timmar',
				minute: 'minut',
				minutes: 'minuter',

				Text: 'Text',
				'Status Filters': 'status filter',
				'Resource Filters': 'resurs filter',
				'Project Filters': 'projekt filter',
				'Contact Filters': 'kontakt filter',

				'New Status': 'Ny status',
				'New Resource': 'Ny resurs',
				'New Folder': 'Ny mapp',

				Sources: 'Källor',
				Save: 'Spara',
				Cancel: 'Avbryt',
				cancel: 'avbryt',

				'Show as resource': 'Visa som resurs',
				'Calendar for new entries': 'Kalender för nya poster',
				'for me': 'för mig',
				'for everyone': 'för alla',
				'disabled by your administrator':
					'inaktiverats av administratören',

				'Call your own scripts': 'Ring egna skript',

				'Status Filter': 'Status Filtrera',

				Name: 'namn',
				Color: 'Kulör',

				day: 'dag',
				days: 'dagar',

				'Account Settings': 'Registrering Inställningar',
				'Trial Mode': 'testläge',
				'Contact Us': 'Kontakta oss',
				Help: 'Hjälpa',
				Documentation: 'Dokumentation',
				'What Is New': 'Vad är nytt',
				'Sign Out': 'Logga ut',

				'Check For Updates': 'Leta efter uppdateringar',
				'Install Update': 'Installera uppdatering',
				'what is new': 'Vad är nytt?',

				'Load Calendar': 'Start Kalender',

				'Purchase DayBack': 'Inköpa DayBack',
				'Switch To Subscription': 'Växla till Teckning',
				'Manage Subscription': 'Hantera prenumeration',
				'Enter New License': 'Ange ny licens',
				Activate: 'Aktivera',

				'Trial activated': 'Din {{days}} prov har aktiverats',
				'Trial expired': 'Din testperioden är slut',
				'Trial expired message':
					'Trial löpt ut. Vänligen köpa en licens för att fortsätta.',
				'Trial remains': '{{days}} kvar i din testperioden',
				'Trial expires in days': 'Din försök löper ut om {{days}}.',

				'Subscription expired': 'Prenumeration passerat {{days}} sedan',
				'Subscription expired message':
					'Prenumeration löpt ut. Vänligen förnya fortsätta.',
				'Cease to function message':
					'Om en ny licens inte anges kalendern gränssnittet kommer att upphöra att fungera i {{days}}',

				'Subscription renews': 'Prenumeration förnyar i {{days}}',
				'Subscription renews today': 'Prenumerationen förnyas idag',
				'Subscription should have renewed':
					'Prenumerationen borde ha förnyats för {{days}} sedan',

				'Subscription overdue message admin':
					'Prenumerationsbetalning är förfallen. Hantera din prenumeration och uppdatera betalningsinformation.',
				'Subscription overdue message non admin':
					'Prenumerationsbetalning är förfallen. Hantera din prenumeration och uppdatera betalningsinformation.',

				Purchased: 'Köpt',

				Product: 'Produkt',
				'Order Number': 'Beställningsnummer',
				Email: 'Email',
				'Registered To': 'Registrerat Till',

				'Current version': 'Du kör version {{version}}',
				'Version available': 'Version {{version}} tillgängligt',
				'Running latest version': 'Du kör den senaste versionen',
				'Cannot connect': 'Kunde inte ansluta till uppdatera servern',
				'Problem downloading update':
					'Det fanns ett problem att ladda ner uppdateringen',

				'Activation denied':
					'Begäran Aktiveringen nekades. Kontrollera dina uppgifter och försök igen',
				'Activation successful': 'Aktivering framgångsrik',

				TooManyUsers1: 'Alltför många användare.',
				TooManyUsers2: 'Alltför många användares öppnat kalendern.',
				TooManyUsers3:
					'Du kan köpa en uppgradering för att tillåta flera användare, eller temporärt återställa närvarande räknade användare så att du kan fortsätta arbeta nu.',
				'Purchase Upgrade': 'Inköps uppgradering',
				'Reset User Count': 'Återställ Användar Count',

				'Mobile not supported':
					'Detta stöds för närvarande inte på iOS',
				'Update Successful': 'uppdateringen lyckades',

				'Resource Columns': 'Resurs Kolumner',

				Refresh: 'Uppdatera',
				'Show Weekends': 'Visa veckoslut',
				Days: 'dagar',
				of: 'av',

				Fetching: 'Hämtar',

				'Extend In-App Updates': 'Utöka uppdateringar i appen',
				'Updates expired message':
					'Tillgång till uppdateringar i appen upphörde för {{days}} sedan',
				'Free updates remaining message':
					'{{days}} av in-app uppdateringar kvar',

				'Week of': 'veckan',
				weeks: 'veckor',
				week: 'vecka',

				months: 'månader',
				month: 'månad',

				years: 'år',
				year: 'år',

				'Show Distances': 'Visa Avstånd',
				Horizon: 'Horisont',
				'Breakout By': 'breakout Genom',
				nothing: 'ingenting',
				'Horizon Settings': 'horisont Settings',

				'Breakout by Resource': 'Utdelning efter resurs',
				'Breakout by Status': 'Uppdelning efter status',
				'Repeating Events': 'Upprepa händelser',
				'Hide all repetitions': 'Dölj alla upprepningar',
				'Hide daily': 'Dölj dagligen',
				'Hide weekly': 'Göm varje vecka',
				'Hide monthly': 'Dölj varje månad',
				'Hide yearly': 'Göm varje år',

				Saved: 'sparade',
				Deleted: 'utgår',
				Undo: 'Ångra',
				'No Change History Available':
					'Ingen Tillgänglig Ändringshistorik',
				'Event may be off screen': 'Händelsen kan vara utanför skärmen',

				'Operation Failed': 'Operationen misslyckades',
				'Revert Changes': 'Återställ ändringar',
				'Changes Reverted': 'Ändringar Återställda',
				'Return To Event': 'Återgå till händelse',

				'Authentication Successful': 'autentisering Framgångsrik',
				'Please close to return to the calendar':
					'Stäng för att återgå till kalendern',

				'See in-app updates available since then':
					'Se i appar uppdateringar tillgängliga sedan dess',

				Views: 'Visningar',
				'Horizon View': 'Horisont View',
				'Resource View': 'Resurs View',
				'Month View': 'Visa månad',
				Fluid: 'Vätska',
				'Fit to screen': 'Anpassa till skärmen',

				'Admin Settings': 'admin Inställningar',
				'Administrator Settings': 'admin Inställningar',
				'My Settings': 'Mina inställningar',
				'Contact Us And Docs': 'Kontakta oss & Dokument',
				'Sign-Out': 'Logga ut',
				Feedback: 'Återkoppling',
				'Schedule a Demo': 'Schemalägg en demo',

				'events can only be moved to other calendars when they are first created':
					'{{source}} händelser kan endast flyttas till andra kalendrar när de först skapades.',

				'Editing Scale': 'redigering Skala',

				'defend your time': 'försvara din tid',
				'add to calendar': 'Lägg till i kalender',
				More: 'Mer',

				'Invalid View': 'ogiltig View',
				'Invalid view message':
					'En ogiltig uppfattning används för att navigera i kalendern och har ignorerats.',
				'Invalid Date': 'Ogiltigt datum',
				'Invalid date message':
					'Ett ogiltigt datum användes för att navigera i kalendern och har ignorerats.',
				'Invalid Bookmark ID': 'Ogiltigt bokmärkes-ID',
				'Invalid Bookmark ID message':
					'Bokmärkes-ID {{bookmarkID}} är ogiltig eller inte en del av din DayBack-grupp',
				Error: 'Fel',
				'Bookmark update filter error message':
					'Uppdateringen kan inte utföras eftersom filterlistan inte längre matchar den som sparats med bokmärket. Skapa och dela ett nytt bokmärke istället.',
				'Bookmark visit filter error message':
					'Filterlistan matchar inte längre det som sparats med bokmärket, så det här bokmärket kan vara inaktuellt.',

				Continue: 'Fortsätt',
				'Learn More': 'Läs mer',

				Share: 'Dela',
				'Bookmark or Share This View': 'Bokmärk eller dela den här vyn',
				'Manage Bookmarks/Shares': 'Hantera bokmärken/aktier',
				'Recent Bookmarks': 'Senaste bokmärken',
				'Show All': 'Visa allt',
				'DayBack Mission': 'DayBack beskickning',
				Blog: 'blogg',
				'Get Your Own DayBack': 'Få din egen DayBack',
				'Contact DayBack': 'kontakt DayBack',
				'About DayBack': 'om DayBack',

				'Enable Sharing': 'aktivera delning',
				Off: 'Av',
				Administrator: 'Administratör',
				Administrators: 'Administratörer',
				Everyone: 'Alla',

				'Enabled for Administrators': 'Aktiverad för administratörer',
				'Sharing Disabled': 'delning inaktiverad',

				'DayBack Share Not Found': 'DayBack Dela hittades inte',
				'The share you have requested has expired or does not exist.':
					'Andelen du har begärt har gått ut eller inte existerar.',
				'Why did this happen?': 'Varför hände detta?',

				'Want more? Get your own DayBack.':
					'Vill ha mer? Få din egen DayBack...',
				'Try DayBack for Free': 'Försök DayBack gratis',

				Update: 'Uppdatering',
				Edit: 'Redigera',
				Visit: 'Besök',
				'Bookmark/Share Detail': 'Bokmärk/Dela Detalj',
				'Created by': 'Skapad av',
				'Created By': 'Skapad av',
				'Expires In': 'Går ut om',
				Expires: 'Utgår',
				Expired: 'Utgånget',
				New: 'Ny',
				Create: 'Skapa',
				'Show In Timezone': 'Visa i tidszon',
				'Extend View': 'Förläng vy',
				'Create Share': 'skapa andel',
				'Shared Dates': 'delade Datum',
				'Share with': 'Dela med',
				'Shared with': 'Delad med',
				'Just Me': 'Bara jag',
				'My DayBack Group': 'Min DayBack-grupp',
				Public: 'offentlig',
				'Public share options': 'Aktieoptioner',
				Bookmark: 'Bokmärke',
				Created: 'Skapad',
				URL: 'URL',
				'Embed Code': 'Bädda in Kod',
				'Update Share': 'Uppdatera aktie',
				'Update Bookmark': 'Uppdatera bokmärke',
				'Copy URL': 'Kopiera URL',
				'Copy Embed Code': 'Kopiera Bädda in kod',
				'Copied to clipboard': 'Kopieras till urklipp',
				'Please copy the selected text': 'Kopiera den markerade texten',
				'Creating share': 'Skapa andel',
				'Updating share': 'Uppdatera dela',
				'Bookmark created successfully':
					'Bokmärke skapad framgångsrikt',
				'Share updated successfully': 'Dela uppdaterats',
				'Updating takes you to the view you have shared':
					'Uppdatering tar dig till den vy du har delat och re-synkroniserar träffar.',
				'Remember changes made are automatically synced':
					'Kom ihåg att alla ändringar som görs i DayBack automatiskt synkroniseras till dina aktier.',
				'Updating this bookmark will reset the filters':
					'Om du uppdaterar det här bokmärket återställs filtren, vyn och datumintervallet (om tillämpligt) till det du ser just nu.',

				'Authorization Failed': 'Auktorisering misslyckades',
				'Connecting To DayBack': 'Anslutning till DayBack',
				'Get Started': 'Komma igång',
				'Sharing not Activated': 'Delning inte aktiverad',
				'Sharing Enabled': 'delning aktiverad',
				'There are no shares yet': 'Det finns inga aktier ännu',

				Subscribe: 'Prenumerera',
				'Sharing Trial Expired': 'Dela Trial Utgånget',
				'Sharing Subscription Expired':
					'Dela prenumeration har gått ut',

				'Custom Fields': 'Anpassade fält',
				'Select Date': 'Välj datum',

				'Add Field': 'Lägg till fält',
				'Add Button': 'Lägg till-knapp',

				'View In Source': 'Visa i {{source}}',

				'Basecamp HTTPS Required':
					'Basecamp kräver en säker anslutning. Vill du ladda om DayBack med HTTPS?',
				'HTTPS Connection Required': 'HTTPS-anslutning krävs',
				'Reload as HTTPS': 'Uppdatera som HTTPS',

				Total: 'Total',
				'Chart Data': 'Diagramdata',
				Format: 'Formatera',
				Threshold: 'Tröskel',
				'Chart the value of a specific field:':
					'Diagram värdet av ett visst fält:',
				'Chart the number of items': 'Ange antalet poster',
				'Chart the duration of items':
					'Visa varaktigheten för artiklarna',
				'Show a threshold value on the chart':
					'Visa ett tröskelvärde i diagrammet',
				Value: 'Värde',
				'Line Color': 'Linje färg',
				'Fill Color': 'Fyllnadsfärg',
				'Fill Opacity': 'Fyll opacitet',
				'color formats?': 'färgformat',
				'Color formats instructions':
					'Använd någon CSS-färg, inklusive RGB, RGBA eller Hex-värden',

				'Format as': 'Format som',
				'Label Before Value': 'Etikett före värde',
				'Label After Value': 'Etikett efter värde',
				'Decimal Symbol': 'Decimal symbol',
				'Decimal Places': 'Decimala platser',
				'Thousands Separator': 'Tusentals separator',

				event: 'händelse',
				events: 'evenemang',
				item: 'Artikel',
				items: 'föremål',
				'Over by': 'Över genom',
				'Under by': 'Under med',
				'Chart by day, week': 'Diagram efter dag, vecka',
				Auto: 'Bil',

				'Add Calendar': 'Lägg till kalender',
				'Calendar is read-only': 'Kalender är skrivskyddad',

				Folder: 'Mapp',
				'Folder Name': 'Mapp namn',

				'Only applies to horizon view at day scale':
					'Gäller endast horisontvy på dagsskala',

				'Switch Accounts': 'Byt konton',
				Deauthorize: 'avauktorisera',
				Authorize: 'Godkänna',
				'Maybe Later': 'Kanske senare',

				'Grant Access To Your Google Account':
					'Ge åtkomst till ditt {{type}}-konto',
				'To view your Google Calendars you will want to allow DayBack to access your Google account':
					'Om du vill se dina {{type}}-kalendrar vill du låta DayBack komma åt ditt {{type}}-konto.',
				'Welcome To DayBack': 'Välkommen till DayBack',

				'Waiting for events': 'Väntar på händelser',
				'Cancel Request': 'Avbryta begäran',

				Theme: 'Tema',

				'Moving sample data':
					'Flytta provdata på plats. Det kan ta ett ögonblick.',

				'Switch to this date with the bookmark':
					'Byt till detta datum med bokmärket',
				'Changing switch date':
					'Att ändra denna inställning för ett offentligt bokmärke kommer att ändra det aktuella datumintervallet för vyn och uppdatera de delade händelserna för att matcha vad som visas i vyn',

				'There was a problem signing in':
					'Det uppstod ett problem med att logga in i {{account}}',
				'Your current DayBack license does not support this feature.':
					'Din nuvarande DayBack-licens stöder inte den här funktionen.',
				'Your subscription does not have enough user licenses':
					'Din prenumeration har inte tillräckligt med användarlicenser.',
				'There was an issue retrieving data from the specified group token':
					'Det gick inte att hämta data från det angivna grupptoken.',
				'Please contact your FileMaker developer':
					'Kontakta din FileMaker-utvecklare.',
				Account: 'Konto',
				Accounts: 'Konton',
				and: 'och',
				"Calendar Can't be found": 'Kalender kan inte hittas',
				'Bookmark calendar not found message':
					'De {{calendarSchedules}} kan inte hittas. Vänligen auktorisera rätt {{calendarSources}} till {{action}} det här bokmärket.',

				'First linked event': 'Första länkade händelse',
				'Previous linked event': 'Tidigare länkad händelse',
				'Step through linked events': 'Gå igenom länkade händelser',
				'End of linked events': 'Slutet på länkade händelser',
				'Next linked event': 'Nästa länkade händelse',
				'Last linked event': 'Senast länkade händelse',
				Pause: 'Paus',

				Attachments: 'Bilagor',
				'The original event has attachments':
					'Den ursprungliga händelsen har bilagor. Dessa bilagor tas bort från den duplicerade händelsen.',

				'Filter resources': 'Filtrera resurser',
				'No resources match your criteria':
					'Inga resurser matchar dina kriterier',

				Filter: 'Filtrera',
				Class: 'Class',
				Tags: 'Taggar',
				Capacity: 'Kapacitet',
				'comma separated': 'separerade med komma',
				'No additional details': 'Inga ytterligare detaljer',
				'This item was created dynamically and should be modified in its original source':
					'Denna artikel skapades dynamiskt och bör ändras i sin ursprungliga källa',

				'Over under threshold': 'Över/under tröskeln',
				over: 'över',
				'Includes partial week': 'Inkluderar en delvecka',
				'Includes partial month': 'Inkluderar delmånad',
				'Public bookmarks expire essentials plan':
					'Offentliga bokmärken upphör att gälla om 72 timmar i Essentials-planen. För offentliga kalendrar som varar längre än 3 dagar, uppgradera till Plus-planen där du kan ställa in ditt eget utgångsdatum.',
				'Learn more about DayBack Plans and Pricing':
					'Läs mer om DayBack-planer och priser',
				'No events found': 'Inga händelser hittades',

				'Service will end days unless payment is received':
					'Tjänsten avslutas inom {{days}} dagar om inte betalning har mottagits',
				'Service will end day unless payment is received':
					'Tjänsten avslutas inom {{days}} dag om inte betalning har mottagits',

				Drop: 'Tappa',
				'Count Unscheduled Items': '{{count}} oplanerade objekt',
				'Count Unscheduled Item': '{{count}} oplanerad artikel',
				'No Unscheduled Items': 'Inga oplanerade objekt',
				'Unscheduled is not available for this calendar':
					'Oschemalagt är inte tillgängligt för den här kalendern',

				'Show as resource for me': 'Visa som resurs för mig',

				Assigned: 'Tilldelad',

				'Public shares will be deleted 30 days after expiration':
					'Offentliga aktier kommer att raderas 30 dagar efter utgången',
				'Will be deleted 30 days after expiration':
					'Kommer att raderas 30 dagar efter utgången',
			},
		};

		//Japanese language and substitutions - Special thanks to Shin Ninagawa
		defaultTranslations['ja'] = {
			id: 'ja',
			name: '日本人',
			translations: {
				//These are name value pairs. Customize the values only (the item after the colon)
				Home: 'ホーム',
				Calendars: 'カレンダー',
				Filters: 'フィルタ',
				Settings: '設定',

				'Hide Sidebar': 'サイドバーを隠す',
				'Show Sidebar': 'サイドバー表示',

				//repeating event items
				Repeat: '繰り返す',
				January: '1月',
				February: '2月',
				March: '3月',
				April: '4月',
				May: '5月',
				June: '六月',
				July: '7月',
				August: '8月',
				September: '9���',
				October: '10月',
				November: '11月',
				December: '12月',
				Monday: '月��',
				Tuesday: '火曜日',
				Wednesday: '水���日',
				Thursday: '木曜日',
				Friday: '���曜日',
				Saturday: '土曜日',
				Sunday: '日曜日',
				Daily: '毎日',
				Weekly: '毎週',
				Monthly: '毎月',
				Yearly: '毎年',
				'1st': '最初',
				'2nd': '二番',
				'3rd': '三番',
				'4th': '第4',
				Last: '最終',
				'repeat every': '毎回繰り返す',
				'day on': '一日',
				'days on': '日数',
				'week on': '週に',
				'weeks on': '週の',
				'month on': '月に',
				'months on': '月間',
				'year on': '年',
				'years on': '年',
				'stop after': '後で止まる',
				never: '決して',
				'on the': '〜に',
				'Your changes will only apply to this event, not to its other repetitions. Would you still like to save this change?':
					'あなたの変更はこのイベントにの���適用され、他の繰り返しには適用されません。あなたはまだこの変更を保存したいですか？',
				'Edit only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'このアイテムのみを編集します。これは今後のすべての繰り返し、またはすべての繰り返しですか？これは、元に戻すことはできません。',
				'Delete only this item, this and all future repetitions, or all repetitons? This cannot be undone.':
					'このアイテムのみを削除します。これは今後のすべての繰り返し、またはすべての繰り返しですか？これは、元に戻すことはできません。',
				'Edit only this item or this and all future repetitions? This cannot be undone.':
					'このアイテムのみ、またはこれ以降の繰り返しをすべて編集しますか？これは、元に戻すことはできません。',
				'Edit Repeating Item': '繰り返しアイテムを編集する',
				'Delete Repeating Item': '繰り返しアイテムを削除する',
				'Only This Item': 'このアイテムのみ',
				'All Future Repetitions': '今後のすべての反復',
				'All Repetitions': 'すべての反復',
				'Would you like to delete all future repetitions and create new ones? This cannot be undone':
					'今後のすべての繰り返しを削除して新しい���のを作成しますか？これ������、元に戻すことはできません',
				'Repeating Event': '繰り返すイベント',
				'Delete Only': '削除のみ',
				'Delete and New': '削除し、新しい',
				'Future Repetitions Deleted': '将来の反復が削除されました',
				'must be on or before': 'それ以前にあってはならない',

				Day: '日',
				Schedule: 'スケジュール',
				List: '一覧',
				Week: '週',
				Month: '月',
				Resource: 'リソース',
				Grid: '格子状',
				'Pivot Schedule': 'ピボットスケジュール',
				'Pivot List': 'ピボット一覧',

				'Snap To Month': '月にスナップ',

				Today: '今日',
				Resources: 'リソース',
				resources: 'リソース',

				Calendar: 'カレンダー',
				All_Day: '終日',
				Start: '開始',
				End: '終了',
				Status: 'ステータス',
				Location: 'ロケーション',
				Contact: '連絡先',
				Project: 'プロジェクト',
				Done: '完了',

				Delete: '削除',
				Close: '閉じる',
				'Save And Close': '保存して閉じる',
				'Save And Back': '��存して戻ります',
				Back: 'バ��ク',

				'Show on Map': '地図上に表示',
				'Open URL': 'URLを開く',
				'Open Map': '開写像',
				'Location not found': '場所が見つかりません',
				'Enter an address or location name':
					'住所��場所の名前を入力します。',

				Search: '検索',
				none: 'なし',

				Description: '詳細',
				'Enter Title': 'タイト���を入力',
				'resource inherited from calendar':
					'リソースは、カレンダ��から継承されました',

				'Clear Selection': '選択解除',

				New_Event_Title: '新しいイベント',
				'New Event': '新規イベント',
				'View Day': '日を表示',
				'You have no editable calendars selected':
					'編集可能なカレンダーは選択されていません',
				'You may not edit shared views':
					'共有ビューを編集することはできません',
				'You have no calendar sources configured':
					'カ��ンダーソースが設定されていません',
				'You have no calendars selected that allow times':
					'時間を許すカレンダーは選択されていません',
				'Could not create a new event because all breakout items are hidden':
					'すべ���の中断アイテムが隠されているため、新しいイベントを作成できませんでした',
				"'All Day Event' is currently disabled for Events":
					'イベントの「��日イベント」は��在無効になっています',

				'Time Settings': '時刻の設定',
				'Default Time': 'デフォルト時刻',
				'Earliest Time': '最初の時刻',
				'Latest Time': '最後の時刻',
				'Time Scale': '時間の間隔',
				'View Settings': 'ビューの設定',
				'Compressed View': '圧縮されたビュー',
				'Fluid Months': '詳細な月表示',

				Yes: 'はい',
				No: 'いいえ',

				'Advanced Filters': '高度なフィルタ',
				'Clear Filters': 'フィルタをクリアする',

				Week_Number_Header: '週',
				more: 'もっと...',

				hour: '時',
				hours: '時間',
				minute: '分',
				minutes: '分間',

				Text: 'テキスト',
				'Status Filters': 'ステータスでフィルタ',
				'Resource Filters': 'リソースフィルタ',
				'Project Filters': 'プロジェクトフィルタ',
				'Contact Filters': '連絡先フィルタ',

				'New Status': '新しいステータス',
				'New Resource': '新しいリソース',
				'New Folder': '新規フォルダ',

				Sources: 'ソース',
				Save: '保存',
				Cancel: 'キャンセル',
				cancel: 'キャンセル',

				'Show as resource': '資源としてのショー',
				'Calendar for new entries': '新しいエントリのカレンダー',
				'for me': '私のために',
				'for everyone': 'すべての人のために',
				'disabled by your administrator': '管理者によって無効に',

				'Call your own scripts': '独自のスクリプトを実行する',

				'Status Filter': 'ステータスでフィルタ',

				Name: '名前',
				Color: '色',

				day: '日',
				days: '日',

				'Account Settings': 'アカウント設定',
				'Trial Mode': '試用版',
				'Contact Us': 'お問い合せ',
				Help: '助けて',
				Documentation: 'ドキュメンテーション',
				'What Is New': '新着情報',
				'Sign Out': 'サインアウト',

				'Check For Updates': '更���を確認する',
				'Install Update': '更新をインストール',
				'what is new': '更新内容?',

				'Load Calendar': 'カレンダーを読み込み',

				'Purchase DayBack': 'DayBackを購入',
				'Switch To Subscription': '登録に切り替え',
				'Manage Subscription': '登録情報を管理',
				'Enter New License': '新ライセンスを入力',
				Activate: 'アクティベ����ト',

				'Trial activated':
					' {{days}} 日の試用版はアクティベートされました',
				'Trial expired': '試用版は有効切れです',
				'Trial expired message':
					'試用版が有効切れになりました。続けるにはライセンスをご購入ください。',
				'Trial remains': '試用期間はあと{{days}}日です。',
				'Trial expires in days': '試用期間はあと{{days}}日です。',

				'Subscription expired': '登録が{{days}}日前に切れています。',
				'Subscription expired message':
					'登録期間が切れました。続けるには更新してください。',
				'Cease to function message':
					'新しいライセンスが入力されない場合、カレンダーのインタフェースは{{days}}日以内に機能しなくなります。',

				'Subscription renews': 'あと{{days}}日で登録の更新',
				'Subscription renews today':
					'サブスクリプションは本日更新されます',
				'Subscription should have renewed':
					'サブスクリプションは{{days}}前に更新されている必要があります',

				'Subscription overdue message admin':
					'サブスクリプションの支払いは期限が過ぎています。 サブスクリプションを管理し、支払い情報を更新します。',
				'Subscription overdue message non admin':
					'サブスクリプションの支払いは期限が過ぎています。 サブスクリプションを管理し、支払い情報を更新します。',

				Purchased: '購入済み',

				Product: '製品',
				'Order Number': '注文番号',
				Email: 'メール',
				'Registered To': '登録者',

				'Current version': 'バージョン {{version}} を使用中',
				'Version available': 'バージョン {{version}} が利用可能',
				'Running latest version': '最新版をお使いです。',
				'Cannot connect': '更新サーバに接続できませんでした',
				'Problem downloading update':
					'アップデートのダウンロード時に問題が発生しました。',

				'Activation denied':
					'アクティベーションのリクエストが拒否されました。情報を確認してもう一度お試しください。',
				'Activation successful': 'アクティベーションが成功しました。',

				TooManyUsers1: 'ユーザーが多すぎます。',
				TooManyUsers2: 'TOO多くのユーザーがカレンダーを開いた。',
				TooManyUsers3:
					'OUには、より多くのユーザーを許可するようにアップグレードを購入、またはあなたが今作業を継続できるように、一時的に現在カウントされ、ユーザーをリセットすることができます。',
				'Purchase Upgrade': '購入のアップグレード',
				'Reset User Count': 'リセットユーザーカウント',

				'Mobile not supported':
					'これは、現在のiOSではサポートされていません',
				'Update Successful': '更新に成功',

				'Resource Columns': 'リソースの列',

				Refresh: 'リフレッシュ',
				'Show Weekends': 'ショー週末',
				Days: '日',
				of: 'の',

				Fetching: 'フェッチ',

				'Extend In-App Updates': 'アプリ内アップデートを拡張する',
				'Updates expired message':
					'「アプリ内のアップデートへのアクセスが有効期限 {{days}} 」',
				'Free updates remaining message':
					'{{days}} アプリ内の更新は、残りの',

				'Week of': 'の週',
				weeks: '週間',
				week: '週間',

				months: '月',
				month: '月',

				years: '年',
				year: '年',

				'Show Distances': 'ショーの距離',
				Horizon: '地平線',
				'Breakout By': 'ブレークアウトバイ',
				nothing: '何も',
				'Horizon Settings': '地平線の設定',

				'Breakout by Resource': 'リソース別の内訳',
				'Breakout by Status': 'ステータス別の内訳',
				'Repeating Events': '繰り返しイベント',
				'Hide all repetitions': 'すべての繰り返しを隠す',
				'Hide daily': '毎日隠す',
				'Hide weekly': '毎��隠す',
				'Hide monthly': '毎月隠す',
				'Hide yearly': '毎年隠す',

				Saved: '保存',
				Deleted: '削除されました',
				Undo: 'アンドゥ',
				'No Change History Available': '変更履歴は利用できません',
				'Event may be off screen': 'イベントは画面外かもしれません',

				'Operation Failed': '処理に失敗しました',
				'Revert Changes': '変更を元に戻します',
				'Changes Reverted': '変更が元に戻されました',
				'Return To Event': 'イベントに戻ります',

				'Authentication Successful': '成功した認証',
				'Please close to return to the calendar':
					'カレンダーに戻るには、閉じてく���さい',

				'See in-app updates available since then':
					'それ以来、アプリ内の更新が利用可能を参照してください。',

				Views: 'ビュー',
				'Horizon View': 'ホライゾン見ます',
				'Resource View': 'リソースビュー',
				'Month View': '月間表示',
				Fluid: '流体',
				'Fit to screen': '画面に合わせます',

				'Admin Settings': '管理者設定',
				'Administrator Settings': '管理者設定',
				'My Settings': '私の設定',
				'Contact Us And Docs': 'お問い合わせ＆ドキュメント',
				'Sign-Out': 'サインアウト',
				Feedback: 'フィードバック',
				'Schedule a Demo': 'デモをスケジュールする',

				'events can only be moved to other calendars when they are first created':
					'{{source}} 彼らが最初に作成されたときにのみイベントが他のカレンダーに移動することができます���',

				'Editing Scale': '編��スケール',

				'defend your time': 'あなたの時��を守ります',
				'add to calendar': 'カレンダーに追加',
				More: 'もっと',

				'Invalid View': '無効なビュー',
				'Invalid view message':
					'無効���ビューは、カレンダーをナビゲートするために使用され、無視されました。',
				'Invalid Date': '無効な日付',
				'Invalid date message':
					'無効な日付はカレンダーをナビゲートするた��に使用され、無視されました。',
				'Invalid Bookmark ID': '無効なブックマークID',
				'Invalid Bookmark ID message':
					'ブックマークID {{bookmarkID}} 無効であるか、DayBackグループの一部ではありません',
				Error: 'エラー',
				'Bookmark update filter error message':
					'フィルターリストがブックマークに保存されているものと一致しなくなったため、更新を実行できません。 代わりに新しいブックマークを作成して共有してください。',
				'Bookmark visit filter error message':
					'フィルターリストはブックマークと共に保存されたリストと一致しなくなったため、このブックマークは古くなっている可能性があります。',

				Continue: '持続する',
				'Learn More': 'もっと詳しく知る',

				Share: 'シェア',
				'Bookmark or Share This View':
					'このビューをブックマークまたは共有する',
				'Manage Bookmarks/Shares': 'ブックマーク/共有を管理する',
				'Recent Bookmarks': '最近のブックマーク',
				'Show All': 'すべて表示する',
				'DayBack Mission': 'DayBackミッション',
				Blog: 'ブログ',
				'Get Your Own DayBack': 'あなた自身の DayBackを手に入れよう',
				'Contact DayBack': 'DayBackに連絡する',
				'About DayBack': 'DayBackについて',

				'Enable Sharing': '共有を有効にする',
				Off: 'オフ',
				Administrator: '管理者',
				Administrators: '管理者',
				Everyone: 'みんな',

				'Enabled for Administrators': '管理者が有効になっている',
				'Sharing Disabled': '共有無効',

				'DayBack Share Not Found': 'DayBack共有が見つかりません',
				'The share you have requested has expired or does not exist.':
					'要求した共有が期限切れになっているか、存在しません。',
				'Why did this happen?': 'なぜこれが起こったのですか？',

				'Want more? Get your own DayBack.':
					'もっと欲しい？ あなた自身のDayBackを入手してください。。。',
				'Try DayBack for Free': 'DayBackを無料で試す',

				Update: '更新',
				Edit: '編集する',
				Visit: '訪問',
				'Bookmark/Share Detail': 'ブックマーク/詳細を共有',
				'Created by': 'によって作成された',
				'Created By': 'によって作成された',
				'Expires In': '期限切れ',
				Expires: '失効',
				Expired: '期限切れ',
				New: '新着',
				Create: '作成する',
				'Show In Timezone': 'タイムゾーンで表示',
				'Extend View': 'ビューを拡張',
				'Create Share': '共有を作成する',
				'Shared Dates': '共有日',
				'Share with': 'と共有する',
				'Shared with': 'と共有',
				'Just Me': '私だけ',
				'My DayBack Group': '私のDayBackグループ',
				Public: '公衆',
				'Public share options': '公開共有オプション',
				Bookmark: 'ブックマークする',
				Created: '作成した',
				URL: 'URL',
				'Embed Code': '埋め込む',
				'Update Share': 'アップデート共有',
				'Update Bookmark': 'ブックマークを更新',
				'Copy URL': 'URLをコピーする',
				'Copy Embed Code': '埋め込みコードをコピー',
				'Copied to clipboard': 'クリップボードにコピーされました',
				'Please copy the selected text':
					'選択したテキストをコピーしてください',
				'Creating share': '共有を作成する',
				'Updating share': '共有の更新',
				'Bookmark created successfully': 'ブックマークを作成しました',
				'Share updated successfully': '共有が更新されました',
				'Updating takes you to the view you have shared':
					'更新すると、共有したビューに移動し、一致するアイテムを再同期します。',
				'Remember changes made are automatically synced':
					'DayBackで行った変更は自動的に共有に同期されること���覚��ておいてくだ����い。',
				'Updating this bookmark will reset the filters':
					'このブックマークを更新すると、フィルター、ビュー、日付範囲 (該当する場合) が現在表示されているものにリセットされます。',

				'Authorization Failed': '承認に失敗しました',
				'Connecting To DayBack': 'DayBackに接続する',
				'Get Started': '開始する',
				'Sharing not Activated': '共有は有効化されていません',
				'Sharing Enabled': '共有を有効にする',
				'There are no shares yet': 'まだ株がありません',

				Subscribe: '申し込む',
				'Sharing Trial Expired': '共有試用期間が終了しました',
				'Sharing Subscription Expired': '有効期限の共有',

				'Custom Fields': 'カスタムフィールド',
				'Select Date': '日付を選択',

				'Add Field': 'フィールドを追加',
				'Add Button': '追加ボタン',

				'View In Source': '{{source}}で表示',

				'Basecamp HTTPS Required':
					'Basecampには安全な接��が必要です。 HTTPSを使ってDayBackを����ロードしますか？',
				'HTTPS Connection Required': 'HTTPS接続が必要',
				'Reload as HTTPS': 'HTTPSとしてリロードする',

				Total: '合計',
				'Chart Data': 'チャートデータ',
				Format: 'フォーマット',
				Threshold: '閾値',
				'Chart the value of a specific field:':
					'特定のフィールドの値をグラフ化する:',
				'Chart the number of items': 'アイテム数をグラフ化する',
				'Chart the duration of items': 'アイテムの期間���グラフ化する',
				'Show a threshold value on the chart':
					'グラフにしきい値を表示する',
				Value: '値',
				'Line Color': '線の色',
				'Fill Color': '塗りつぶしの色',
				'Fill Opacity': '���透明度を埋める',
				'color formats?': 'カラーフォーマット？',
				'Color formats instructions':
					'RGB、RGBA、またはHex値を含む任意のCSSカ���ーを使用する',

				'Format as': 'フォーマットは',
				'Label Before Value': '値の前にラベルを付ける',
				'Label After Value': '値の後のラベル',
				'Decimal Symbol': '小数点記号',
				'Decimal Places': '小数位',
				'Thousands Separator': '千のセパレータ',

				event: 'イベント',
				events: 'イベント',
				item: 'アイテム',
				items: 'アイテム',
				'Over by': '上に',
				'Under by': 'アンダーバイ',
				'Chart by day, week': '日、週ごとのグラフ',
				Auto: 'オート',

				'Add Calendar': 'カレンダーを追加',
				'Calendar is read-only': 'カレンダーは読み取り専用です',

				Folder: 'フォルダ',
				'Folder Name': 'フォルダ名',

				'Only applies to horizon view at day scale':
					'日単位の水平線ビューにのみ適用されます',

				'Switch Accounts': 'アカウントを切り替える',
				Deauthorize: '解除',
				Authorize: '許可する',
				'Maybe Later': 'たぶん後で',

				'Grant Access To Your Google Account':
					'{{type}}アカウントへのアクセスを許可する',
				'To view your Google Calendars you will want to allow DayBack to access your Google account':
					'{{type}}カレンダーを表示するには、DayBackが{{type}}アカウントにアクセスできるようにする必要があります。',
				'Welcome To DayBack': 'DayBackへようこそ',

				'Waiting for events': 'イベント待ち',
				'Cancel Request': 'リ���エストをキャンセル',

				Theme: 'テーマ',

				'Moving sample data':
					'サンプルデータを適切な場所に移動します。 これには少し時間がかかる場合があります。',

				'Switch to this date with the bookmark':
					'ブックマークでこの日付に切り替えます',
				'Changing switch date':
					'Aパブリックブックマークのこの設定を変更すると、ビューの現在の日付範囲が変更され、ビューに表示されているものと一致するように共有イベントが更新されます',

				'There was a problem signing in':
					'{{account}}での署名中に問題が発生しました',
				'Your current DayBack license does not support this feature.':
					'現在のDayBackライセンスはこの機能をサポートしていません。',
				'Your subscription does not have enough user licenses':
					'サブスクリプションに十分なユーザーライセンスがありません��',
				'There was an issue retrieving data from the specified group token':
					'指定されたグループトークンからデータを取得する際に��題が発生����した。',
				'Please contact your FileMaker developer':
					'FileMaker開発者に連絡してください。',
				Account: 'アカウント',
				Accounts: 'アカウント',
				and: 'そして',
				"Calendar Can't be found": 'カレンダーが見つかりません',
				'Bookmark calendar not found message':
					'の {{calendarSchedules}} 見つかりません。 承認してください 正しい {{calendarSources}} に {{action}} このブックマーク。',

				'First linked event': '最初のリンクされたイベント',
				'Previous linked event': '以前のリンクされたイベント',
				'Step through linked events':
					'リンクされたイベントをステ���プスルーする',
				'End of linked events': 'リンクされたイベントの終了',
				'Next linked event': '次のリンクされたイベント',
				'Last linked event': '最後にリンクされたイベント',
				Pause: '一時停止',

				Attachments: '添付ファイル',
				'The original event has attachments':
					'元のイベントには添付ファイルがあります。 これらの添付ファイルは、複製されたイベントから削除されます。',

				'Filter resources': 'リソースをフィルタリングする',
				'No resources match your criteria':
					'基準に一致するリソースはありません',

				Filter: 'フィルター',
				Class: 'Class',
				Tags: 'タグ',
				Capacity: '容量',
				'comma separated': '区切り',
				'No additional details': '追加の詳細はありません',
				'This item was created dynamically and should be modified in its original source':
					'このアイテムは動的に作成されたため、元のソースで変更する必要があります',

				'Over under threshold': 'しきい値を超える/下回る',
				over: '以上',
				'Includes partial week': '一部の週を含む',
				'Includes partial month': '月の一部を含む',
				'Public bookmarks expire essentials plan':
					'Essentialsプランでは、パブリックブックマークは72時間で期限切れになります。 3日以上続く公開カレンダーの場合は、独自の有効期限を設定できるPlusプランにアップグレードしてください。',
				'Learn more about DayBack Plans and Pricing':
					'DayBackのプランと料金について詳しく知る',
				'No events found': 'イベントが見つかりません',

				'Service will end days unless payment is received':
					'支払いが受領されない限り、サービスは {{days}} 日で終了します',
				'Service will end day unless payment is received':
					'支払いが受領されない限り、サービスは {{days}} 日で終了します',

				Drop: '取り落とす',
				'Count Unscheduled Items': '{{count}} 予定外のアイテム',
				'Count Unscheduled Item': '{{count}} 予定外のアイテム',
				'No Unscheduled Items': '予定外のアイテムはありません',
				'Unscheduled is not available for this calendar':
					'このカレンダーでは予定外は利用できません',

				'Show as resource for me': '私のためのリソースとして表示',

				Assigned: '割り当て済み',

				'Public shares will be deleted 30 days after expiration':
					'公開株式は有効期限の30日後に削除されます',
				'Will be deleted 30 days after expiration':
					'有効期限の30日後に削除されます',
			},
		};

		function getTranslations(id, loadCustom, callback) {
			var output = {};
			if (!defaultTranslations[id]) {
				id = 'en';
			}
			var languageTranslations = defaultTranslations[id].translations;
			if (loadCustom) {
				//Requires a user to be logged in
				manageSettings.getTranslations(id, onGetTranslations);
			} else {
				onGetTranslations({});
			}

			function onGetTranslations(result) {
				for (var property in languageTranslations) {
					if (result && result[property]) {
						output[property] = result[property];
					} else {
						output[property] = languageTranslations[property];
					}
				}
				//Execute callback with result
				if (callback) {
					callback(output);
				}
			}
		}

		return {
			defaultTranslations: defaultTranslations,
			getTranslations: getTranslations,
		};
	}
})();
